import { runInAction } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';
import { Combobox, ComboboxOption } from '../../../../Components/Combobox/Combobox';
import { MultiCombobox } from '../../../../Components/Combobox/MultiCombobox';
import { Button, Colors, Display } from '../../../../Components/Button/Button';
import { VenueMap } from '../EditApplicationUserInterfaces';

interface IEditApplicationUserAccessControlsProps<T> {
	managementAccess: {
		venues: VenueMap<T>[];
		newVenue: boolean;
	};
	mode: { edit: boolean };
	addVenue: () => void;
	removeAccess: (selectedVenue: string) => void;
	searchVenuesAndMapToVenueOptions: (input: string) => Promise<ComboboxOption<T | undefined>[]>
	fetchZonesAndMapToNewManagementAccess: (venueId: string) => void;
	submit: (e: React.MouseEvent) => void;
	isSubmitting?: boolean;
}

export default observer((props: IEditApplicationUserAccessControlsProps<unknown>) => {
	const {
		managementAccess,
		mode,
		addVenue,
		removeAccess,
		searchVenuesAndMapToVenueOptions,
		fetchZonesAndMapToNewManagementAccess,
		submit,
		isSubmitting,
	} = props;

	return (
		<div className="management-access-container">
			{managementAccess.venues.map(venue => (
				<div className="control-section-container" key={venue.selectedVenue}>
					<Combobox
						className="venue-select"
						model={venue}
						label="Venue Name"
						modelProperty="selectedVenue"
						options={venue.venueOptions}
						isDisabled
						labelVisible={false}
					/>
					<div className="zone-select-wrapper">
						<MultiCombobox
							className={`zone-select${venue.error ? ' error' : ''}`}
							model={venue.venueRelationDetails}
							label="Zone"
							options={venue.venueRelationDetails.zoneOptions}
							modelProperty="zoneRelations"
							isDisabled={!mode.edit}
							labelVisible={false}
							onAfterChange={() => {
								if (venue.venueRelationDetails.zoneRelations.includes('all')) {
									runInAction(() => {
										venue.venueRelationDetails.zoneRelations = ['all'];
									});
								}
							}}
						/>
						{venue.error && (<p className="access-error">{venue.error}</p>)}
					</div>
					{mode.edit && (
						<Button
							className="delete-button"
							display={Display.Text}
							colors={Colors.Secondary}
							icon={{ icon: 'bin-full', iconPos: 'icon-left' }}
							onClick={() => removeAccess(venue.selectedVenue)}
						/>
					)}
				</div>
			))}
			{managementAccess.newVenue && (
				<div className="control-section-container">
					<Combobox
						className="venue-select"
						model={{}}
						label="Venue Name"
						modelProperty="selectedVenue"
						options={searchVenuesAndMapToVenueOptions}
						onChange={(e, data) => {
							fetchZonesAndMapToNewManagementAccess(data.value as string);
						}}
						labelVisible={false}
					/>
					<div className="new-zone-select-wrapper">
						<MultiCombobox
							className="zone-select"
							model={{}}
							label="Zone"
							options={[]}
							modelProperty=""
							labelVisible={false}
						/>
					</div>
					<Button
						className="delete-button"
						display={Display.Text}
						colors={Colors.Secondary}
						icon={{ icon: 'bin-full', iconPos: 'icon-left' }}
						onClick={() => runInAction(() => managementAccess.newVenue = false)}
					/>
				</div>
			)}
			{mode.edit && (
				<div className="management-access-controls">
					<Button
						display={Display.Solid}
						colors={Colors.Primary}
						icon={{ icon: 'create', iconPos: 'icon-left' }}
						onClick={addVenue}
					>
						Add Venue
					</Button>
					<Button
						className="submit-button"
						display={Display.Solid}
						colors={Colors.Primary}
						icon={{ icon: 'create', iconPos: 'icon-left' }}
						onClick={submit}
						disabled={isSubmitting}
					>
						Save Changes
					</Button>
				</div>
			)}
		</div>
	);
});
