import React from 'react';
import { observer } from 'mobx-react';
import { TrackEntity } from '../../../Models/Entities';
import PlaylistTrackPanel from './PlaylistTrackPanel';
import { IEditorTrackLists, MultiSelectStore, PlaylistSummary } from '../../../Util/PlaylistUtils';
import PlaylistTile from './PlaylistTile';

interface IPlaylistsPanelProps {
	playlists: PlaylistSummary[];
	activePlaylistsStore: { active: PlaylistSummary; activePlaylists: PlaylistSummary[] };
	makeActive: (playlistSummary: PlaylistSummary) => void;
	closeActive: (playlistSummary: PlaylistSummary) => void;
	closePlaylist: (playlistSummary: PlaylistSummary) => void;
	deletePlaylist: (playlistSummary: PlaylistSummary) => void;
	isExpanded: boolean;
	multiSelectStore: MultiSelectStore
	multiSelect: (
		event: React.MouseEvent<HTMLInputElement>,
		index: number,
		start: boolean,
		track: string,
		trackList: string[],
		collectionId: string
	) => void;
	editorTrackLists: {editor: IEditorTrackLists};
	addTracklist: (playlistId: string, trackList: TrackEntity[]) => void;
	renamePlaylist: (playlistSummary: PlaylistSummary) => void;
	duplicatePlaylist: (originalPlaylistId: string, playlistSummary: PlaylistSummary) => void;
}

const PlaylistsPanel = observer((props: IPlaylistsPanelProps) => {
	const {
		playlists,
		isExpanded,
		multiSelectStore,
		multiSelect,
		activePlaylistsStore,
		makeActive,
		closeActive,
		closePlaylist,
		deletePlaylist,
		editorTrackLists,
		addTracklist,
		renamePlaylist,
		duplicatePlaylist,
	} = props;

	return (
		<div className="playlist-results-container">
			{playlists.length ? (
				<>
					<div className={`playlist-tiles${isExpanded ? ' expanded' : ''}`}>
						{playlists.map((p: PlaylistSummary) => (
							<PlaylistTile
								key={`playlist-tile-${p.id}`}
								playlist={p}
								activePlaylistsStore={activePlaylistsStore}
								makeActive={makeActive}
								closeActive={closeActive}
								closePlaylist={closePlaylist}
								deletePlaylist={deletePlaylist}
								isExpanded={isExpanded}
								renamePlaylist={renamePlaylist}
								duplicatePlaylist={duplicatePlaylist}
							/>
						))}
					</div>
					{isExpanded && (
						<div className="active-playlists-container">
							{activePlaylistsStore.activePlaylists.map(p => (
								<PlaylistTrackPanel
									key={`playlist-track-panel-${p.id}`}
									playlist={p}
									multiSelectStore={multiSelectStore}
									multiSelect={multiSelect}
									isExpanded={isExpanded}
									closeActive={closeActive}
									editorTrackLists={editorTrackLists}
									addTracklist={addTracklist}
								/>
							))}
						</div>
					)}
					{!isExpanded && activePlaylistsStore.active.name && (
						<PlaylistTrackPanel
							playlist={activePlaylistsStore.active}
							multiSelectStore={multiSelectStore}
							multiSelect={multiSelect}
							isExpanded={isExpanded}
							closeActive={closeActive}
							editorTrackLists={editorTrackLists}
							addTracklist={addTracklist}
						/>
					)}
				</>
			) : (
				<div className="search-placeholder editor">
					<h5>Search for an existing playlist to edit or create a new playlist.</h5>
				</div>
			)}
		</div>
	);
});

export default PlaylistsPanel;
