/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-return-assign */
// @ts-nocheck
import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction, toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ClientEntity, ContactEntity } from '../../../Models/Entities';
import { TextField } from '../../Components/TextBox/TextBox';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Components/Button/Button';
import { IContactDetails } from './CreateClientPage';
import { isEmail } from '../../../Validators/Functions/Email';
import { store } from '../../../Models/Store';
import alertToast from '../../../Util/ToastifyUtils';

interface IClientModalProps {
	client: ClientEntity;
	refetchClient: () => void;
	title: string;
}

const errorMessages = {
	name: 'Client must have a name.',
	mainContact: 'Client must have at least 1 contact.',
	contactName: 'Contact Name is required.',
	contactNumber: 'A contact number is required if no email address is provided.',
	emailAddress: 'A contact email is required if no contact number is provided.',
};

const ClientModal = (props: IClientModalProps): JSX.Element => {
	const {
		client,
		refetchClient,
		title,
	} = props;

	const clientForm = useLocalStore(() => ({
		name: client.name,
		contacts: client.contactss.map((c: ContactEntity) => ({
			contactName: c.name,
			contactNumber: c.phoneNumber,
			emailAddress: c.emailAddress,
			id: c.id,
			mainContact: c.mainContact,
		})) as IContactDetails[],
		errors: {
			name: '',
			mainContact: '',
			contacts: client.contactss.map(contact => (
				{
					contactName: '',
					contactNumber: '',
					emailAddress: '',
					id: contact.id,
				})) as IContactDetails[],
		},
	}));

	const contactsToDelete = useLocalStore(() => ({
		list: [] as string[],
	}));

	const validateField = (field: string, index: number = -1) => {
		if (clientForm.contacts.length === 0) {
			runInAction(() => clientForm.errors.mainContact = errorMessages.mainContact);
		} else {
			// @ts-ignore
			runInAction(() => delete clientForm.errors.mainContact);
		}

		if (field === 'contacts') {
			if (!clientForm.errors.contacts) {
				runInAction(() => clientForm.errors.contacts = clientForm.contacts.map(c => ({
 contactName: '', contactNumber: '', emailAddress: '', id: c.id,
})));
			}
			clientForm.contacts.forEach((contact, i) => {
				Object.keys(contact).forEach(contactField => validateField(contactField, i));
			});
			if (clientForm.errors.contacts?.map(contact => Object.keys(contact).length).reduce((a: number, b: number) => a + b, 0) === 0) {
				runInAction(() => {
					if (clientForm.errors.contacts) {
						// @ts-ignore
						delete clientForm.errors.contacts;
					}
				});
			}
			return;
		}

		if (field === 'id' || field === 'errors') {
			return;
		}

		runInAction(() => {
			if (field === 'contactNumber') {
				if (clientForm.contacts[index].contactNumber === '') {
					if (clientForm.contacts[index].emailAddress !== '') {
						if (clientForm.errors.contacts) { delete clientForm.errors.contacts[index].contactNumber; }
					} else {
						clientForm.errors.contacts[index].contactNumber = errorMessages.contactNumber;
					}
				} else if (Number.isNaN(Number(clientForm.contacts[index].contactNumber))) {
					clientForm.errors.contacts[index].contactNumber = 'Not a valid number.';
				} else if (clientForm.errors.contacts) { delete clientForm.errors.contacts[index].contactNumber; }
			} else if (field === 'emailAddress') {
				if (clientForm.contacts[index].emailAddress === '' || clientForm.contacts[index].emailAddress === null) {
					if (clientForm.contacts[index].contactNumber !== '') {
						if (clientForm.errors.contacts) { delete clientForm.errors.contacts[index].emailAddress; }
					} else {
						clientForm.errors.contacts[index].emailAddress = errorMessages.emailAddress;
					}
				} else if (!isEmail(clientForm.contacts[index].emailAddress || '')) {
					clientForm.errors.contacts[index].emailAddress = 'Not a valid email.';
				} else if (clientForm.errors.contacts) { delete clientForm.errors.contacts[index].emailAddress; }
			} else if (field === 'contactName') {
				if (clientForm.contacts[index].contactName === '' || clientForm.contacts[index].contactName === null) {
					clientForm.errors.contacts[index].contactName = errorMessages.contactName;
				} else if (clientForm.errors.contacts) { delete clientForm.errors.contacts[index].contactName; }
			} else if (clientForm[field] === '' || clientForm[field] === null) {
				if (Object.keys(errorMessages).includes(field)) {
					clientForm.errors[field] = errorMessages[field];
				}
			} else {
				delete clientForm.errors[field];
			}
		});
	};

	const onSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();

		Object.keys(clientForm).forEach(field => validateField(field));

		if (clientForm.contacts.length === 0 || clientForm.contacts.filter(c => c.mainContact).length === 0) {
			alertToast('There must be a main contact', 'error');
			return;
		}

		const actualErrors = clientForm.errors;

		const hasContactErrors = actualErrors.contacts.filter(contactErrors => {
			if (contactErrors.contactName !== undefined) {
				return true;
			}
			if (contactErrors.contactNumber !== undefined) {
				return true;
			}
			if (contactErrors.emailAddress !== undefined) {
				return true;
			}
			return false;
		});

		if (hasContactErrors.length === 0) {
			runInAction(() => {
				// @ts-ignore
				delete clientForm.errors.contacts;
			});
		}

		if (Object.keys(clientForm.errors).length === 0) {
			runInAction(() => {
				client.name = clientForm.name;

				clientForm.contacts.forEach((contact: IContactDetails, index: number) => {
					if (!contact.id?.includes('new-contact')) {
						client.contactss.filter(c => c.id === contact.id)[0].name = contact.contactName || '';
						client.contactss.filter(c => c.id === contact.id)[0].phoneNumber = contact.contactNumber || '';
						client.contactss.filter(c => c.id === contact.id)[0].emailAddress = contact.emailAddress || '';
					} else {
						const newContact = new ContactEntity();
						newContact.name = contact.contactName || '';
						newContact.phoneNumber = contact.contactNumber || '';
						newContact.emailAddress = contact.emailAddress || '';
						newContact.agencyOwnerId = client.agencyId || '';

						client.contactss.push(newContact);
					}
				});
			});

			await client.save({
				contactss: {},
			}).then(async () => {
				if (contactsToDelete.list.length > 0) {
					await Promise.all([...client.contactss.filter(c => contactsToDelete.list.includes(c.id)).map(contact => contact.delete())])
						.then(() => refetchClient())
						.then(() => alertToast('Contacts/s deleted', 'success'))
						.then(() => store.modal.hide())
						.catch(() => alertToast('Something went wrong', 'error'));

					runInAction(() => {
						contactsToDelete.list = [] as string[];
					});
				}

				runInAction(() => {
					alertToast('Updated Client', 'success');
					store.modal.hide();
					refetchClient();
				});
			}).catch((err: any) => {
				alertToast('Failed updating client', 'error');
			});

			if (contactsToDelete.list.length > 0) {
				await Promise.all([...client.contactss.filter(c => contactsToDelete.list.includes(c.id)).map(contact => contact.delete())])
					.then(() => refetchClient())
					.then(() => alertToast('Contacts/s deleted', 'success'))
					.then(() => store.modal.hide())
					.catch(() => alertToast('Something went wrong', 'error'));

				runInAction(() => {
					contactsToDelete.list = [] as string[];
				});
			}
		}
	};

	return (
		<div className="client-modal-container">
			<h4>{title}</h4>
			<form onSubmit={onSubmit}>
				<TextField
					model={clientForm}
					modelProperty="name"
					label="Client Name"
					placeholder="Client Name"
					className="clientName"
					isRequired
					errors={clientForm.errors.name}
				/>
				<h5>Contact Information</h5>
				{clientForm.contacts
					.filter(c => !contactsToDelete.list.includes(c.id || ''))
					.map((contact: IContactDetails, contactIndex: number) => (
						<div key={`contact-form-wrapper-${contact.id}`}>
							<div className="contact-header">
								<h5>{`Contact ${contactIndex + 1}`}</h5>
								<Button
									type="button"
									colors={Colors.Primary}
									display={Display.Text}
									sizes={Sizes.Small}
									onClick={() => {
										runInAction(() => {
											contactsToDelete.list = [...contactsToDelete.list, contact.id || ''];
											clientForm.errors.contacts = [...clientForm.errors.contacts.filter(c => c.id !== contact.id)];
											clientForm.contacts = [...clientForm.contacts.filter(c => c.id !== contact.id)];
										});
									}}
								>
									Delete
								</Button>
							</div>
							<div className="main-contact-switch">
								<p>Main Contact</p>
								<label className="switch">
									<input
										type="checkbox"
										onChange={() => runInAction(() => {
											clientForm.contacts.filter(c => c.id === contact.id)[0].mainContact = !clientForm.contacts.filter(c => c.id === contact.id)[0].mainContact;
											clientForm.contacts.filter(c => c.id !== contact.id).forEach(c => runInAction(() => c.mainContact = false));
										})}
										checked={contact.mainContact}
									/>
									<span className="slider round" />
								</label>
							</div>
							{/* eslint-disable-next-line react/no-array-index-key */}
							<div className="contact-wrapper" key={`contact-${contactIndex}`}>
								<div className="double-input">
									<TextField
										model={contact}
										modelProperty="contactName"
										label="Contact Name"
										className="contactName"
										isRequired
										errors={clientForm.errors.contacts !== undefined ? clientForm.errors.contacts.filter(c => c.id === contact.id)[0].contactName : ''}
									/>
									<TextField
										model={contact}
										modelProperty="contactNumber"
										label="Contact Number"
										className="contactNumber"
										errors={clientForm.errors.contacts !== undefined ? clientForm.errors.contacts.filter(c => c.id === contact.id)[0].contactNumber : ''}
									/>
								</div>
								<TextField
									model={contact}
									modelProperty="emailAddress"
									label="Email Address"
									className="emailAddress"
									errors={clientForm.errors.contacts !== undefined ? clientForm.errors.contacts.filter(c => c.id === contact.id)[0].emailAddress : ''}
								/>
								<br />
							</div>
						</div>
					))}
				<Button
					type="button"
					colors={Colors.Primary}
					display={Display.Text}
					sizes={Sizes.Small}
					icon={{ icon: 'plus', iconPos: 'icon-left' }}
					onClick={() => {
						runInAction(() => {
							const tempId = uuidv4();

							clientForm.contacts = [...clientForm.contacts, {
								contactName: '',
								contactNumber: '',
								emailAddress: '',
								mainContact: false,
								id: `new-contact-${tempId}`,
							}];

							if (!clientForm.errors.contacts) { clientForm.errors.contacts = [{ id: `new-contact-${tempId}` }]; } else {
								clientForm.errors.contacts = [...clientForm.errors.contacts, {
									contactName: '',
									contactNumber: '',
									emailAddress: '',
									id: `new-contact-${tempId}`,
								}];
							}
						});
					}}
				>
					Add contact
				</Button>
				{clientForm.errors.mainContact && (
					<div className="input-group--error">
						<div className="input-group__error-text">
							<p>
								{clientForm.errors.mainContact}
							</p>
						</div>
					</div>
				)}
				<div className="form-controls">
					<Button type="button" colors={Colors.Primary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={() => store.modal.hide()}>
						Cancel
					</Button>
					<Button type="submit" colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium} buttonProps={{ id: 'submit' }}>
						Save Client
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(ClientModal);
