import React from 'react';
import { observer } from 'mobx-react';
import Axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MoodEntity } from '../../../../Models/Entities';
import { SERVER_URL } from '../../../../Constants';
import alertToast from '../../../../Util/ToastifyUtils';
import { runInAction } from 'mobx';
import { MoodContentsSearchResultObject } from 'Util/PlaylistUtils';

export interface MoodSummaryInfo {
    summary: string;
    likedFeedback: string;
    dislikedFeedback: string;
    disabledFeedback: string;
}

export interface MoodSummaryProps {
    mood: MoodEntity;
}

const defaultSummary: MoodSummaryInfo = {
	summary: '0 TRACKS | 00:00:00',
	likedFeedback: '0',
	dislikedFeedback: '0',
	disabledFeedback: '0',
};

const MoodSummary = observer((props: MoodSummaryProps): JSX.Element => {
	const { mood } = props;
	const queryClient = useQueryClient();

	const { data: settingsStats, isLoading } = useQuery(
		['settings_stats', mood.id],
		async (): Promise<MoodSummaryInfo> => {
			try {
				const { data } = await Axios.get(
					`${SERVER_URL}/api/entity/MoodEntity/MoodSettingsSummary/${mood.id}`,
				);
				return data;
			} catch (error) {
				console.error(error);
				return defaultSummary as MoodSummaryInfo;
			}
		},
	);

	return (
		<div className="mood-settings-container">
			<h4>Mood Settings</h4>
			<p className="total-number">Total no. of tracks</p>
			<p>{settingsStats?.summary ?? '0 TRACKS | 00:00:00'}</p>
			<p className="prevent">Prevent Explicits</p>
			<label className="switch">
				<input
					type="checkbox"
					onChange={() => {
						runInAction(() => {
							if (mood.allowExplicits) {
								mood.allowExplicits = false;
								mood.save().then(() => {
									Axios.post(`${SERVER_URL}/api/entity/MoodEntity/RemoveExplicits/${mood.id}`)
										.then(() => {
											queryClient.refetchQueries('profile');
											queryClient.refetchQueries('settings_stats');
											queryClient.refetchQueries('profile_summary');
											queryClient.refetchQueries('collection_contents');
											queryClient.refetchQueries('mood_contents');
											alertToast('Removed explicits', 'success');
										})
										.catch(() => alertToast('Failed to remove explicits', 'error'));
								}).catch(() => {
									alertToast('Failed to remove explicits', 'error');
								});
							} else {
								mood.allowExplicits = true;
								mood.save().then(() => {
									queryClient.refetchQueries('profile');
								});
							}
						});
					}}
					checked={!mood.allowExplicits}
				/>
				<span className="slider round" />
			</label>
		</div>
	);
});

export default MoodSummary;
