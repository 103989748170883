import React from 'react';
import { RouteComponentProps } from 'react-router';
import Navigation, { Orientation } from 'Views/Components/Navigation/Navigation';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import { getFrontendNavLinks } from 'Views/FrontendNavLinks';
import classNames from 'classnames';
import { store } from '../../../Models/Store';
import { VenuesTile } from './VenuesTile';

export const VenuesPage = (props: RouteComponentProps) => {
	const navigationLinks = getFrontendNavLinks(props);
	const {
		history,
		location,
		match,
		staticContext,
	} = props;

	return (
		<SecuredPage>
			<Navigation
				linkGroups={navigationLinks}
				orientation={Orientation.VERTICAL}
				match={match}
				location={location}
				history={history}
				staticContext={staticContext}
			/>

			<div className={classNames('body-content', 'logged-in', store.hasBackendAccess && 'body-admin')}>
				<VenuesTile history={history} location={location} match={match} />
			</div>
		</SecuredPage>
	);
};
