import React, {
	RefObject, useCallback, useEffect, useRef,
} from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import Axios from 'axios';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
	Button, Colors, Display, Sizes,
} from '../../Components/Button/Button';
import { TextField } from '../../Components/TextBox/TextBox';
import { PlaylistEntity, TrackEntity } from '../../../Models/Entities';
import {
	IEditorTrackLists,
	MultiSelectStore,
	PlaylistSearchResultObject,
	PlaylistSummary, TabCollectionContainer,
} from '../../../Util/PlaylistUtils';
import { store } from '../../../Models/Store';
import NewPlaylistModal from './NewPlaylistModal';
import PlaylistsPanel from './PlaylistsPanel';
import { SERVER_URL } from '../../../Constants';
import useIntersectionObserver from '../../../Util/useIntersectionObserver';
import PlaylistSearchBeard from './PlaylistSearchBeard';
import alert from '../../../Util/ToastifyUtils';

interface IPlaylistEditorProps {
	isExpanded: boolean;
	hasExpanded: boolean;
	disableExpand?: boolean;
	expandEditor: () => void;
	multiSelect: (event: React.MouseEvent<HTMLInputElement>, index: number, start: boolean, track: string, trackList: string[], collectionId: string) => void;
	multiSelectStore: MultiSelectStore
	clearMultiSelect: () => void;
	editorTrackLists: {editor: IEditorTrackLists};
	addTracklist: (playlistId: string, trackList: TrackEntity[]) => void;
	tabStore?: { open: TabCollectionContainer[] };
}

export const PlaylistEditor = observer((props: IPlaylistEditorProps) => {
	const {
		isExpanded,
		hasExpanded,
		disableExpand,
		expandEditor,
		multiSelectStore,
		multiSelect,
		clearMultiSelect,
		editorTrackLists,
		addTracklist,
		tabStore,
	} = props;

	const playlistSearchStore = useLocalStore(() => ({
		term: '',
		showBeard: false,
		playlists: [] as PlaylistSummary[],
		loading: false,
	}));

	const beardRef = useRef<HTMLDivElement>(null);

	const mouseDownRef = useRef((e: MouseEvent) => {
		// @ts-ignore
		if (e.target && beardRef.current && !beardRef.current.contains(e.target)) {
			runInAction(() => {
				playlistSearchStore.showBeard = false;
			});
		}
	});

	useEffect(() => {
		document.addEventListener('mousedown', mouseDownRef.current);

		return () => {
			document.removeEventListener('mousedown', mouseDownRef.current);
		};
	}, []);

	// useEffect is required to initialise multiSelectStore
	// It can only be removed when refactoring completed on multi select functionality
	useEffect(() => {}, [multiSelectStore.selected, multiSelectStore.numSelected]);

	const {
		data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading,
	} = useInfiniteQuery(
		'searchPlaylists',
		async ({ pageParam = 0 }): Promise<PlaylistSearchResultObject> => {
			// Do not perform query if no search term entered
			if (!playlistSearchStore.term) {
				return {} as PlaylistSearchResultObject;
			}

			const url = `${SERVER_URL}/api/playlist_search/playlist/${encodeURIComponent(playlistSearchStore.term)}`
			+ `?PageNo=${pageParam}`
			+ '&PageSize=10';

			const res = await Axios.get(url);
			return res.data as PlaylistSearchResultObject;
		},
		{
			getNextPageParam: (lastPage: any) => lastPage.nextPageNo === -1 ? undefined : lastPage.nextPageNo,
		},
	);

	const queryClient = useQueryClient();

	useEffect(() => () => {
		queryClient.removeQueries('searchPlaylists');
	}, []);

	const refetchSearchPlaylists = AwesomeDebouncePromise(() => {
		queryClient.refetchQueries('searchPlaylists')
			.then(() => runInAction(() => {
				playlistSearchStore.loading = false;
			}));
	}, 1000);

	const refetch = useCallback(async () => {
		runInAction(() => {
			playlistSearchStore.loading = true;
		});
		refetchSearchPlaylists();
	}, [playlistSearchStore, refetchSearchPlaylists]);

	useEffect(() => {
		runInAction(() => {
			playlistSearchStore.showBeard = true;
		});

		if (beardRef.current) {
			beardRef.current.focus();
		}
	}, [playlistSearchStore, playlistSearchStore.term]);

	// triggers fetching more content when reached
	const loadMoreButtonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

	// tracks the position of the scroll container to see if the loadMoreButtonRef has been reached
	useIntersectionObserver({
		root: null,
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage,
	});

	const activePlaylistsStore = useLocalStore(() => ({
		active: {} as PlaylistSummary,
		activePlaylists: [] as PlaylistSummary[],
	}));

	const makeActive = action((playlistSummary: PlaylistSummary) => {
		activePlaylistsStore.active = playlistSummary;
		activePlaylistsStore.activePlaylists = isExpanded
			? [...activePlaylistsStore.activePlaylists, playlistSummary]
			: [playlistSummary];
	});

	const closeActive = action((playlistSummary: PlaylistSummary) => {
		activePlaylistsStore.activePlaylists = [...activePlaylistsStore.activePlaylists.filter(x => x.id !== playlistSummary.id)];
		if (activePlaylistsStore.active?.id === playlistSummary.id) {
			activePlaylistsStore.active = {} as PlaylistSummary;
		}
	});

	const openPlaylist = action((playlistSummary: PlaylistSummary) => {
		playlistSearchStore.playlists = [...playlistSearchStore.playlists, playlistSummary];
		store.openPlaylists = [...store.openPlaylists, playlistSummary];
	});

	const closePlaylist = action((playlistSummary: PlaylistSummary) => {
		playlistSearchStore.playlists = [...playlistSearchStore.playlists.filter(x => x.id !== playlistSummary.id)];
		store.openPlaylists = [...store.openPlaylists.filter(x => x.id !== playlistSummary.id)];
	});

	const deletePlaylist = (playlist: PlaylistSummary) => {
		Axios.delete(`${SERVER_URL}/api/entity/PlaylistEntity/${playlist.id}`).then(() => {
			closeActive(playlist);
			closePlaylist(playlist);
			runInAction(() => {
				if (tabStore) {
					tabStore.open = [...tabStore.open.filter(tc => {
						if (tc.playlist) {
							return tc.playlist.id !== playlist.id;
						}
						return true;
					})];
				}
			});
			alert(`Deleted playlist ${playlist.name}`, 'success');
		}).catch(error => alert('Failed to delete playlist', 'error'));
	};

	const renamePlaylist = async (playlist: PlaylistSummary) => {
		const playlistUpdating = await PlaylistEntity.fetch<PlaylistEntity>({ ids: [playlist.id] });
		if (playlistUpdating.length !== 1) {
			return;
		}
		playlistUpdating[0].name = playlist.name;
		await playlistUpdating[0].save();

		closeActive(playlist);

		const query = `${SERVER_URL}/api/playlist_search/summary?id=${playlist.id}`;
		const summaryUpdating = await Axios.get<PlaylistSummary>(query);
		summaryUpdating.data.name = playlist.name;
		playlistSearchStore.playlists.map(x => ((x.id === playlistUpdating[0].id) ? summaryUpdating.data : x));
		makeActive(summaryUpdating.data);
	};

	const duplicatePlaylist = async (originalPlaylistId: string, playlist: PlaylistSummary) => {
		await Axios
			.post<PlaylistSummary>(`${SERVER_URL}/api/entity/PlaylistEntity/Duplicate/${originalPlaylistId}`, playlist)
			.then(res => {
				runInAction(() => {
					playlistSearchStore.playlists = [...playlistSearchStore.playlists, res.data];
					store.openPlaylists = [...store.openPlaylists, res.data];
					makeActive(res.data);
				});
			});
	};

	const clearSearchTerm = action(() => {
		playlistSearchStore.term = '';
	});

	return (
		<div className={`playlist-editor-container${isExpanded ? ' expanded' : ''}${hasExpanded ? '' : ' start-open'}`}>
			<div className="playlist-editor-header">
				<div className="header-button-group">
					<h4>Playlist Editor</h4>
					<Button
						className="new-playlist"
						display={Display.Solid}
						colors={Colors.Primary}
						sizes={Sizes.Small}
						icon={{ icon: 'plus', iconPos: 'icon-left' }}
						onClick={() => store.modal.show(
							'New Playlist',
							<NewPlaylistModal openPlaylist={openPlaylist} />,
							{ className: 'new-playlist-modal' },
						)}
					>
						New
					</Button>
				</div>
				{!disableExpand && (
					<Button
						className="expand"
						display={Display.Text}
						colors={Colors.White}
						sizes={Sizes.Small}
						icon={{ icon: isExpanded ? 'minimise-2' : 'maxminise-2', iconPos: 'icon-left' }}
						onClick={() => {
							expandEditor();
							clearMultiSelect();
						}}
					>
						{isExpanded ? 'Collapse' : 'Expand'}
					</Button>
				)}
			</div>
			<div className="search editor">
				<div className="input-beard-wrapper" ref={beardRef}>
					<TextField
						model={playlistSearchStore}
						modelProperty="term"
						className="search-term"
						placeholder="Search Playlists"
						onAfterChange={refetch}
					/>
					{playlistSearchStore.showBeard && playlistSearchStore.term !== '' && (
						<div ref={beardRef} data-testid="beard" className="beard">
							<PlaylistSearchBeard
								data={data}
								openPlaylists={playlistSearchStore.playlists}
								openPlaylist={openPlaylist}
								clearSearchTerm={clearSearchTerm}
							/>
							<div className="infinite-scroll-button-container">
								<button
									className="infiniteListEndButton"
									ref={loadMoreButtonRef}
									onClick={() => fetchNextPage()}
									disabled={!hasNextPage || isFetchingNextPage}
								>
									{
										playlistSearchStore.loading
										|| isLoading
										|| isFetchingNextPage
										|| hasNextPage ? 'Loading...' : 'Nothing more to load'
									}
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
			<PlaylistsPanel
				playlists={playlistSearchStore.playlists}
				activePlaylistsStore={activePlaylistsStore}
				makeActive={makeActive}
				closeActive={closeActive}
				closePlaylist={closePlaylist}
				deletePlaylist={deletePlaylist}
				renamePlaylist={renamePlaylist}
				duplicatePlaylist={duplicatePlaylist}
				isExpanded={isExpanded}
				multiSelectStore={multiSelectStore}
				multiSelect={multiSelect}
				editorTrackLists={editorTrackLists}
				addTracklist={addTracklist}
			/>
		</div>
	);
});
