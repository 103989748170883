/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* eslint-disable max-len */
import * as React from 'react';
import { action } from 'mobx';
import * as AdminPages from './Pages/Admin/Entity';
import Auth from './Components/Auth/Auth';
import AllUsersPage from './Pages/Admin/AllUsersPage';
import AdminPage from './Pages/Admin/AdminPage';
import Topbar from './Components/Topbar/Topbar';
import PageLinks from './Pages/Admin/PageLinks';
import StyleguidePage from './Pages/Admin/StyleguidePage';
import Spinner from 'Views/Components/Spinner/Spinner';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
} from 'react-router-dom';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

// % protected region % [Customize lazy imports here] off begin
const GraphiQlLazy = React.lazy(() => import('./Pages/Admin/Graphiql'));
// % protected region % [Customize lazy imports here] end

export default class Admin extends React.Component<RouteComponentProps> {
	path = this.props.match.path === '/' ? '' : this.props.match.path;

	// % protected region % [Override componentDidMount here] off begin
	public componentDidMount() {
		this.setAppLocation();
	}
	// % protected region % [Override componentDidMount here] end

	// % protected region % [Override setAppLocation here] off begin
	@action
	private setAppLocation = () => {
		store.appLocation = 'admin';
	}
	// % protected region % [Override setAppLocation here] end

	private adminSwitch = () => {
		// % protected region % [Override access control logic here] off begin
		if (!store.userGroups.some(ug => ug.hasBackendAccess)) {
			return <Redirect to="/404" />;
		}
		// % protected region % [Override access control logic here] end

		return (
			<>
				{
				// % protected region % [Override contents here] off begin
				}
				<PageLinks {...this.props} />
				{
				// % protected region % [Override contents here] end
				}
				<div className="body-content">
					<Switch>
						{/* These routes require a login to view */}

						{/* Admin entity pages */}
						<Route exact path={`${this.path}`} component={AdminPage} />
						<Route path={`${this.path}/User`} component={AllUsersPage} />
						<Route path={`${this.path}/AgencyAdminEntity`} component={AdminPages.AgencyAdminEntityPage} />
						<Route path={`${this.path}/AgencyEntity`} component={AdminPages.AgencyEntityPage} />
						<Route path={`${this.path}/AgencyPlaylisterEntity`} component={AdminPages.AgencyPlaylisterEntityPage} />
						<Route path={`${this.path}/AgencyTagEntity`} component={AdminPages.AgencyTagEntityPage} />
						<Route path={`${this.path}/AlbumEntity`} component={AdminPages.AlbumEntityPage} />
						<Route path={`${this.path}/AnnouncementEntity`} component={AdminPages.AnnouncementEntityPage} />
						<Route path={`${this.path}/AnnouncementTimeslotEntity`} component={AdminPages.AnnouncementTimeslotEntityPage} />
						<Route path={`${this.path}/ApplicationUserEntity`} component={AdminPages.ApplicationUserEntityPage} />
						<Route path={`${this.path}/ArtistEntity`} component={AdminPages.ArtistEntityPage} />
						<Route path={`${this.path}/AuditArchiveEntity`} component={AdminPages.AuditArchiveEntityPage} />
						<Route path={`${this.path}/AuditEntity`} component={AdminPages.AuditEntityPage} />
						<Route path={`${this.path}/BankEntity`} component={AdminPages.BankEntityPage} />
						<Route path={`${this.path}/ClientEntity`} component={AdminPages.ClientEntityPage} />
						<Route path={`${this.path}/ContactEntity`} component={AdminPages.ContactEntityPage} />
						<Route path={`${this.path}/ControlAuditEntity`} component={AdminPages.ControlAuditEntityPage} />
						<Route path={`${this.path}/CountryEntity`} component={AdminPages.CountryEntityPage} />
						<Route path={`${this.path}/DefaultInjectableFrequencyEntity`} component={AdminPages.DefaultInjectableFrequencyEntityPage} />
						<Route path={`${this.path}/FeedbackEntity`} component={AdminPages.FeedbackEntityPage} />
						<Route path={`${this.path}/GenreEntity`} component={AdminPages.GenreEntityPage} />
						<Route path={`${this.path}/GlobalConfigurationEntity`} component={AdminPages.GlobalConfigurationEntityPage} />
						<Route path={`${this.path}/InjectableFrequencyEntity`} component={AdminPages.InjectableFrequencyEntityPage} />
						<Route path={`${this.path}/MoodEntity`} component={AdminPages.MoodEntityPage} />
						<Route path={`${this.path}/MoodTrackEntity`} component={AdminPages.MoodTrackEntityPage} />
						<Route path={`${this.path}/MoodVersionEntity`} component={AdminPages.MoodVersionEntityPage} />
						<Route path={`${this.path}/MustardAdminEntity`} component={AdminPages.MustardAdminEntityPage} />
						<Route path={`${this.path}/MustardManagementEntity`} component={AdminPages.MustardManagementEntityPage} />
						<Route path={`${this.path}/PlaylistEntity`} component={AdminPages.PlaylistEntityPage} />
						<Route path={`${this.path}/PlaylistTrackEntity`} component={AdminPages.PlaylistTrackEntityPage} />
						<Route path={`${this.path}/ProfileEntity`} component={AdminPages.ProfileEntityPage} />
						<Route path={`${this.path}/RegionEntity`} component={AdminPages.RegionEntityPage} />
						<Route path={`${this.path}/ScheduleEntity`} component={AdminPages.ScheduleEntityPage} />
						<Route path={`${this.path}/TagEntity`} component={AdminPages.TagEntityPage} />
						<Route path={`${this.path}/TagTypeEntity`} component={AdminPages.TagTypeEntityPage} />
						<Route path={`${this.path}/TimeslotEntity`} component={AdminPages.TimeslotEntityPage} />
						<Route path={`${this.path}/TrackEntity`} component={AdminPages.TrackEntityPage} />
						<Route path={`${this.path}/VenueEntity`} component={AdminPages.VenueEntityPage} />
						<Route path={`${this.path}/VenueTypeEntity`} component={AdminPages.VenueTypeEntityPage} />
						<Route path={`${this.path}/VolumeTimeslotEntity`} component={AdminPages.VolumeTimeslotEntityPage} />
						<Route path={`${this.path}/ZoneEntity`} component={AdminPages.ZoneEntityPage} />

						{
						// % protected region % [Add any extra page routes here] off begin
						}
						{
						// % protected region % [Add any extra page routes here] end
						}
					</Switch>
				</div>
				{
				// % protected region % [Add any admin footer content here] off begin
				}
				{
				// % protected region % [Add any admin footer content here] end
				}
			</>
		);
	}

	public render() {
		return (
			<>
				<div className="body-container">
					{
					// % protected region % [Modify Topbar] off begin
					}
					<Topbar currentLocation="admin" />
					{
					// % protected region % [Modify Topbar] end
					}
					<Switch>
						<Route path={`${this.path}/styleguide`}>
							<Auth {...this.props}>
								<div className="admin">
									<PageLinks {...this.props} />
								</div>
								<div className="frontend">
									<div className="body-content">
										<StyleguidePage {...this.props} />
									</div>
								</div>
							</Auth>
						</Route>
						<Route>
							<div className="admin">
								<Auth {...this.props}>
									<Switch>
										{
										// % protected region % [Modify top level admin routing here] off begin
										}
										<Route path={`${this.path}/graphiql`}>
											<React.Suspense fallback={<Spinner />}>
												<GraphiQlLazy />
											</React.Suspense>
										</Route>
										<Route component={this.adminSwitch} />
										{
										// % protected region % [Modify top level admin routing here] end
										}
									</Switch>
								</Auth>
							</div>
						</Route>
					</Switch>
				</div>
			</>
		);
	}
}
