import { gql } from '@apollo/client';

export const GET_TRACKS = gql`
	query tracks($args: [WhereExpressionGraph], $skip: Int, $take: Int) {
		trackEntitys(where: $args, skip: $skip, take: $take) {
			id
			audioId
			artistss {
				artists {
					name
				}
			}
			title
			album {
				id
				title
			}
			waveform
		}
	}
`;

export const GET_BANKS = gql`
	query banks($args: [WhereExpressionGraph], $skip: Int, $take: Int, $orderBy: [OrderByGraph]) {
		bankEntitys(where: $args, skip:$skip, take:$take, orderBy: $orderBy) {
			id
			name
			ownerId
			ownerName
			created
			totalTrackCount
			isProcessing
			requiresSorting
			hasErrors
		}
	}
`;

export const DELETE_BANK = gql`
	mutation delete($bankEntityIds: [ID]) {
		deleteBankEntity(bankEntityIds: $bankEntityIds) {
			id
		}
	}
`;
