/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsProfileEntity from 'Models/Security/Acl/VisitorsProfileEntity';
import AgencyAdminProfileEntity from 'Models/Security/Acl/AgencyAdminProfileEntity';
import ApplicationUserProfileEntity from 'Models/Security/Acl/ApplicationUserProfileEntity';
import AgencyPlaylisterProfileEntity from 'Models/Security/Acl/AgencyPlaylisterProfileEntity';
import MustardAdminProfileEntity from 'Models/Security/Acl/MustardAdminProfileEntity';
import MustardManagementProfileEntity from 'Models/Security/Acl/MustardManagementProfileEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProfileEntityAttributes extends IModelAttributes {
	name: string;
	agencyOwnerId: string;
	lastPublished: Date;

	moodss: Array<
		| Models.MoodEntity
		| Models.IMoodEntityAttributes
	>;
	scheduless: Array<
		| Models.ScheduleEntity
		| Models.IScheduleEntityAttributes
	>;
	zoness: Array<
		| Models.ZoneEntity
		| Models.IZoneEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProfileEntity', 'Profile')
// % protected region % [Customise your entity metadata here] end
export default class ProfileEntity extends Model
	implements IProfileEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsProfileEntity(),
		new AgencyAdminProfileEntity(),
		new ApplicationUserProfileEntity(),
		new AgencyPlaylisterProfileEntity(),
		new MustardAdminProfileEntity(),
		new MustardManagementProfileEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<ProfileEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<ProfileEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Published'] off begin
	@observable
	@attribute<ProfileEntity, Date>()
	@CRUD({
		name: 'Last Published',
		displayType: 'datetimepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseDate,
	})
	public lastPublished: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Last Published'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Moods'] off begin
		name: 'Moodss',
		displayType: 'reference-multicombobox',
		order: 40,
		referenceTypeFunc: () => Models.MoodEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'moodss',
			oppositeEntity: () => Models.MoodEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Moods'] end
	})
	public moodss: Models.MoodEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Schedules'] off begin
		name: 'Scheduless',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.ScheduleEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'scheduless',
			oppositeEntity: () => Models.ScheduleEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Schedules'] end
	})
	public scheduless: Models.ScheduleEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Zones'] off begin
		name: 'Zoness',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.ZoneEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'zoness',
			oppositeEntity: () => Models.ZoneEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Zones'] end
	})
	public zoness: Models.ZoneEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProfileEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProfileEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.lastPublished !== undefined) {
				if (attributes.lastPublished === null) {
					this.lastPublished = attributes.lastPublished;
				} else {
					this.lastPublished = moment(attributes.lastPublished).toDate();
				}
			}
			if (attributes.moodss !== undefined && Array.isArray(attributes.moodss)) {
				for (const model of attributes.moodss) {
					if (model instanceof Models.MoodEntity) {
						this.moodss.push(model);
					} else {
						this.moodss.push(new Models.MoodEntity(model));
					}
				}
			}
			if (attributes.scheduless !== undefined && Array.isArray(attributes.scheduless)) {
				for (const model of attributes.scheduless) {
					if (model instanceof Models.ScheduleEntity) {
						this.scheduless.push(model);
					} else {
						this.scheduless.push(new Models.ScheduleEntity(model));
					}
				}
			}
			if (attributes.zoness !== undefined && Array.isArray(attributes.zoness)) {
				for (const model of attributes.zoness) {
					if (model instanceof Models.ZoneEntity) {
						this.zoness.push(model);
					} else {
						this.zoness.push(new Models.ZoneEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] on begin
	public defaultExpands = `
		moodss {
			${Models.MoodEntity.getAttributes().join('\n')}
			moodVersionss {
				${Models.MoodVersionEntity.getAttributes().join('\n')}
				moodTrackss {
					${Models.MoodTrackEntity.getAttributes().join('\n')}
				}
				playlistss {
					playlists {
						${Models.PlaylistEntity.getAttributes().join('\n')}
					}
				}
			}
		}
		scheduless {
			${Models.ScheduleEntity.getAttributes().join('\n')}
			timeslotss {
				${Models.TimeslotEntity.getAttributes().join('\n')}
				mood {
					${Models.MoodEntity.getAttributes().join('\n')}
				}
			}
			zoness {
				${Models.ZoneEntity.getAttributes().join('\n')}
				${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		zoness {
			${Models.ZoneEntity.getAttributes().join('\n')}
			${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;

	public profileExpands = `
		moodss {
			${Models.MoodEntity.getAttributes().join('\n')}
			draftMoodVersion {
				${Models.MoodVersionEntity.getAttributes().join('\n')}
			}
		}
		scheduless {
			${Models.ScheduleEntity.getAttributes().join('\n')}
			timeslotss {
				${Models.TimeslotEntity.getAttributes().join('\n')}
				mood {
					${Models.MoodEntity.getAttributes().join('\n')}
				}
			}
			volumeTimeslotss {
				${Models.VolumeTimeslotEntity.getAttributes().join('\n')}
			}
			zoness {
				${Models.ZoneEntity.getAttributes().join('\n')}
				${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		zoness {
			${Models.ZoneEntity.getAttributes().join('\n')}
			${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			moodss: {},
			scheduless: {},
			zoness: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'moodss',
							'scheduless',
							'zoness',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProfileEntity.prototype, 'created');
CRUD(modifiedAttr)(ProfileEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
