import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useQuery } from 'react-query';
import { runInAction } from 'mobx';
import moment from 'moment';
import {
	AgencyEntity,
	CountryEntity, IAgencyEntityAttributes,
	ICountryEntityAttributes,
	IRegionEntityAttributes,
	IVenueEntityAttributes,
	IZoneEntityAttributes,
	RegionEntity,
	VenueEntity,
	ZoneEntity,
} from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import { getFetchAllConditional, getFetchAllQuery } from '../../../Util/EntityUtils';
import { Combobox } from '../../Components/Combobox/Combobox';
import { RadioButtonGroup } from '../../Components/RadioButton/RadioButtonGroup';
import { MultiCombobox } from '../../Components/Combobox/MultiCombobox';
import {
	Button, Colors, Display, Sizes, 
} from '../../Components/Button/Button';
import { DatePicker } from '../../Components/DatePicker/DatePicker';
import { SERVER_URL } from '../../../Constants';
import alertToast from '../../../Util/ToastifyUtils';
import { Checkbox } from '../../Components/Checkbox/Checkbox';

export const ReportsTile = observer((): JSX.Element => {
	const reportStore = useLocalStore(() => ({
		agencies: ['all'],
		reportType: '',
		venue: '',
		zone: '',
		regionSelect: 'Region',
		region: '',
		countries: ['all'],
		from: undefined,
		to: undefined,
		clientPushUpdate: false,
		isGenerating: false,
	}));

	const orderBy = [
		{ path: 'name', descending: false },
	];

	const fetchAgencies = async (): Promise<AgencyEntity[]> => {
		const { data } = await store.apolloClient.query<{ agencyEntitys: IAgencyEntityAttributes[] }>({
			query: getFetchAllQuery(AgencyEntity, '', true),
			fetchPolicy: 'network-only',
			variables: { args: [], orderBy },
		});
		
		return data.agencyEntitys.map(x => new AgencyEntity(x));
	};
	
	const fetchRegions = async (): Promise<RegionEntity[]> => {
		const { data } = await store.apolloClient.query<{ regionEntitys: IRegionEntityAttributes[] }>({
			query: getFetchAllQuery(RegionEntity, 'countriess { id }', true),
			fetchPolicy: 'network-only',
			variables: { args: [], orderBy },
		});
		
		return data.regionEntitys.map(x => new RegionEntity(x));
	};

	const fetchCountries = async (): Promise<CountryEntity[]> => {
		const { data } = await store.apolloClient.query<{ countryEntitys: ICountryEntityAttributes[] }>({
			query: getFetchAllQuery(CountryEntity, '', true),
			fetchPolicy: 'network-only',
			variables: { args: [], orderBy },
		});

		return data.countryEntitys.map(x => new CountryEntity(x));
	};

	const fetchVenues = async (): Promise<VenueEntity[]> => {
		const { data } = await store.apolloClient.query<{ venueEntitys: IVenueEntityAttributes[] }>({
			query: getFetchAllQuery(VenueEntity, '', true),
			fetchPolicy: 'network-only',
			variables: { args: [], orderBy },
		});

		return data.venueEntitys.map(x => new VenueEntity(x));
	};

	const fetchZones = async (): Promise<ZoneEntity[]> => {
		const { data } = await store.apolloClient.query<{ zoneEntitys: IZoneEntityAttributes[] }>({
			query: getFetchAllConditional(ZoneEntity, '', {
				expandType: 'list',
				removeCount: true,
			}),
			fetchPolicy: 'network-only',
			variables: { args: [[{ path: 'venueId', comparison: 'equal', value: reportStore.venue }]], orderBy },
		});

		return data.zoneEntitys.map(x => new ZoneEntity(x));
	};

	const {
		isLoading: isLoadingAgencies, isError: isErrorAgencies, data: agencies,
	} = useQuery('agencies', fetchAgencies);
	
	const {
		isLoading: isLoadingRegions, isError: isErrorRegions, data: regions,
	} = useQuery('regions', fetchRegions);

	const {
		isLoading: isLoadingCountries, isError: isErrorCountries, data: countries,
	} = useQuery('countries', fetchCountries);

	const {
		isLoading: isLoadingVenues, isError: isErrorVenues, data: venues,
	} = useQuery('venues', fetchVenues);

	const {
		isLoading: isLoadingZones, isError: isErrorZones, data: zones,
	} = useQuery(['zones', reportStore.venue], fetchZones, { enabled: !!reportStore.venue });

	if (isLoadingRegions || isLoadingCountries || isLoadingVenues || isLoadingAgencies) {
		return <span>Loading...</span>;
	}

	if (isErrorRegions || isErrorCountries || isErrorVenues || isErrorAgencies) {
		return <span>Error occured</span>;
	}

	const downloadReport = (url: string, fileName: string) => {
		alertToast('Generating Report. This may take a while...', 'info');
		runInAction(() => reportStore.isGenerating = true);

		fetch(url)
			.then(response => response.blob())
			.then(blob => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode?.removeChild(link);
			})
			.catch(() => alertToast('Error generating report', 'error'))
			.finally(() => {
				// renable the download report button
				runInAction(() => reportStore.isGenerating = false);
			});
	}
	
	const generateReport = () => {
		if (reportStore.from === null || reportStore.from === undefined) {
			alertToast('You must select a start date to generate the report', 'error');
			return;
		}

		if (reportStore.to === null || reportStore.to === undefined) {
			alertToast('You must select an end date to generate the report', 'error');
			return;
		}

		if (moment(reportStore.from).isAfter(moment(reportStore.to))) {
			alertToast('The start date must be before the end date', 'error');
			return;
		}

		const startDateString = moment(reportStore.from).format('YYYY-MM-DDTHH:mm:ss');
		const endDateString = moment(reportStore.to).format('YYYY-MM-DDTHH:mm:ss');
		const dateParams = `&startDate=${startDateString}&endDate=${endDateString}`;

		const reportsEndpoint = `${SERVER_URL}/api/Reports`;

		if (reportStore.reportType === 'Venue Usage Summary') {
			let countriesToGet = reportStore.countries;
			if (reportStore.regionSelect === 'Country') {
				if (countriesToGet.length === 1 && countriesToGet[0] === 'all') {
					countriesToGet = countries?.map(c => c.id) ?? [];
				}
				if (countriesToGet.length === 0) {
					alertToast('You must select countries to generate the report', 'error');
					return;
				}
			}
			
			if (reportStore.regionSelect === 'Region') {
				if (reportStore.region !== null && reportStore.region !== '') {
					countriesToGet = countriesToGet.concat([...regions?.filter(r => r.id === reportStore.region)[0].countriess.map(c => c.id) ?? []]);
				} else {
					alertToast('You must select a region to generate the report', 'error');
					return;
				}
			}
			
			let agenciesToGet = reportStore.agencies;
			if (agenciesToGet.length === 1 && agenciesToGet[0] === 'all') {
				agenciesToGet = agencies?.map(a => a.id) ?? [];
			}

			if (agenciesToGet.length === 0) {
				alertToast('You must select agencies to generate the report', 'error');
				return;
			}

			downloadReport(
				`${reportsEndpoint}/VenueUsageSummary?countryIds=${countriesToGet.join(',')}&agencyIds=${agenciesToGet.join(',')}${dateParams}`,
				'VenueUsageSummary.csv'
			);
		}

		if (reportStore.reportType === 'Recordings Report') {
			let countriesToGet = reportStore.countries;
			if (reportStore.regionSelect === 'Country') {
				if (countriesToGet.length === 1 && countriesToGet[0] === 'all') {
					countriesToGet = countries?.map(c => c.id) ?? [];
				}
				if (countriesToGet.length === 0 && reportStore.venue === '') {
					alertToast('You must select countries to generate the report', 'error');
					return;
				}
			}

			if (reportStore.regionSelect === '' || reportStore.regionSelect === 'Region') {
				if (reportStore.region !== null && reportStore.region !== '') {
					countriesToGet = countriesToGet.concat([...regions?.filter(r => r.id === reportStore.region)[0].countriess.map(c => c.id) ?? []]);
				} else if (reportStore.venue === '') {
					alertToast('You must select a region to generate the report', 'error');
					return;
				}
			}

			if (reportStore.venue === null || reportStore.venue === '') {
				alertToast('You must select a venue to generate the report', 'error');
				return;
			}

			let agenciesToGet = reportStore.agencies;
			if (agenciesToGet.length === 1 && agenciesToGet[0] === 'all') {
				agenciesToGet = agencies?.map(a => a.id) ?? [];
			} else if (agenciesToGet.length === 0 && reportStore.venue !== '') {
				agenciesToGet = ['all'];
			}

			if (agenciesToGet.length === 0 && reportStore.venue === '') {
				alertToast('You must select agencies to generate the report', 'error');
				return;
			}

			downloadReport(
				`${reportsEndpoint}/RecordingsReport?venueId=${reportStore.venue}&countryIds=${countriesToGet.join(',')}&agencyIds=${agenciesToGet.join(',')}${dateParams}`,
				'RecordingsReport.csv'
			);
		}
		
		if (reportStore.reportType === 'Zone Logs') {
			if (reportStore.zone === null || reportStore.zone === '' || reportStore.zone === undefined) {
				alertToast('You must select a zone to generate the report', 'error');
				return;
			}

			downloadReport(
				`${reportsEndpoint}/ZoneLogs?zoneId=${reportStore.zone}&clientPushUpdate=${reportStore.clientPushUpdate}${dateParams}`,
				'ZoneLogReport.csv'
			);
		}
	};


	
	return (
		<div className="reports-page-wrapper">
			<div className="reports-page-header">
				<h1>Reports</h1>
			</div>
			<div className="report-options">
				<Combobox
					model={reportStore}
					modelProperty="reportType"
					label="Report Type"
					options={['Venue Usage Summary', 'Recordings Report', 'Zone Logs'].map(rt => ({ display: rt, value: rt }))}
					className="report-type-input"
					placeholder="Report Type"
					onAfterChange={() => {
						runInAction(() => {
							if (reportStore.reportType === 'Venue Usage Summary') {
								reportStore.venue = '';
								reportStore.zone = '';
							}
							if (reportStore.reportType === 'Zone Logs') {
								reportStore.region = '';
								reportStore.countries = [];
								reportStore.agencies = [];
							}
						});
					}}
				/>
				
				{reportStore.reportType !== '' && (
					<>
						<h3>Filter Report</h3>

						<p>Dates included in the report</p>

						<div className="report-date-range-wrapper">
							<DatePicker model={reportStore} modelProperty="from" />
							<span className="range-separator">-</span>
							<DatePicker model={reportStore} modelProperty="to" />
						</div>
					</>
				)}
				
				{reportStore.reportType !== 'Zone Logs' && (
					<>
						<MultiCombobox
							model={reportStore}
							modelProperty="agencies"
							label="Agencies"
							options={[{ display: 'All', value: 'all' }, ...agencies?.map(c => ({ display: c.name, value: c.id })) ?? []]}
							onAfterChange={() => {
								if (reportStore.agencies.includes('all')) {
									runInAction(() => {
										reportStore.agencies = ['all'];
									});
								}
							}}
						/>
						<RadioButtonGroup
							model={reportStore}
							modelProperty="regionSelect"
							label="Select by"
							options={['Region', 'Country'].map(x => ({ display: x, value: x }))}
							className="region-select"
							onAfterChange={() => {
								runInAction(() => {
									if (reportStore.regionSelect === 'Region') {
										reportStore.countries = [];
									}
									if (reportStore.regionSelect === 'Country') {
										reportStore.countries = ['all'];
										reportStore.region = '';
									}
								});
							}}
						/>
						{reportStore.regionSelect === 'Region' ? (
							<Combobox
								model={reportStore}
								modelProperty="region"
								label="Region"
								options={regions?.map(r => ({ display: r.name, value: r.id })) ?? []}
								className="region"
								placeholder="Region"
							/>
						) : (
							<MultiCombobox
								model={reportStore}
								modelProperty="countries"
								label="Countries"
								options={[{ display: 'All', value: 'all' }, ...countries?.map(c => ({ display: c.name, value: c.id })) ?? []]}
								onAfterChange={() => {
									if (reportStore.countries.includes('all')) {
										runInAction(() => {
											reportStore.countries = ['all'];
										});
									}
								}}
							/>
						)}
					</>
				)}
				
				{reportStore.reportType === 'Recordings Report' && (
					<Combobox
						model={reportStore}
						modelProperty="venue"
						label="Venue"
						placeholder="Venue"
						options={[{ display: 'All', value: 'All' }, ...venues?.map(v => ({ display: v.name, value: v.id })) ?? []]}
					/>	
				)}

				{reportStore.reportType === 'Zone Logs' && (
					<>
						<Combobox
							model={reportStore}
							modelProperty="venue"
							label="Venue"
							options={venues?.map(v => ({ display: v.name, value: v.id })) ?? []}
							className="venue"
							placeholder="Venue"
						/>
						{isLoadingZones && (<p>Loading...</p>)}
						{isErrorZones && (<p>Error...</p>)}
						{!isLoadingZones && !isErrorZones && (
							<Combobox
								model={reportStore}
								modelProperty="zone"
								label="Zone"
								options={zones?.map(z => ({ display: z.name, value: z.id })) ?? []}
								className="zone"
								placeholder="Zone"
								isDisabled={reportStore.venue === ''}
							/>	
						)}
						<Checkbox model={reportStore} modelProperty="clientPushUpdate" label="Filter out Client Push Updates?" />
					</>
				)}
				
				{reportStore.reportType !== '' && (
					<Button
						className="generate-report-button"
						display={Display.Solid}
						colors={Colors.Primary}
						sizes={Sizes.Medium}
						onClick={generateReport}
						disabled={reportStore.isGenerating}
					>
						{reportStore.isGenerating && (
							<div className='simple-loader-xs' />
						)}
						Download Report
					</Button>
				)}
			</div>
		</div>
	);
});
