import React from 'react';
import { RouteComponentProps } from 'react-router/index';
import EntityCRUD from '../../Components/CRUD/EntityCRUD';
import { ClientEntity } from '../../../Models/Entities';
import { archiveAction } from '../Admin/ArchiveAction';
import * as Models from '../../../Models/Entities';

export const ClientsTile = (props: RouteComponentProps) => {
	const { history, location, match } = props;

	return (
		<div className="clients-dashboard">
			<EntityCRUD
				perPage={8}
				modelType={ClientEntity}
				history={history}
				location={location}
				match={match}
				collectionTitle="Clients"
				collectionDeleteAction={() => undefined}
				entityCollectionProps={{
					additionalTableActions: [archiveAction(Models.ClientEntity, () => undefined)],
				}}
			/>
		</div>
	);
};
