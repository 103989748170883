import React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { v4 as uuidV4 } from 'uuid';
import {
	Button, Colors, Display, Sizes,
} from '../../../Components/Button/Button';
import { TextField } from '../../../Components/TextBox/TextBox';
import { ScheduleEntity } from '../../../../Models/Entities';
import { store } from '../../../../Models/Store';

interface ICreateScheduleModalProps {
	addSchedule: (schedule: ScheduleEntity) => void;
}

interface IScheduleForm {
	name: string;
	errors: {
		name?: string;
	};
}

const defaultScheduleForm: IScheduleForm = {
	name: '',
	errors: {
		name: '',
	},
};

const errorMessages = {
	name: 'Schedule must have a name.',
};

const CreateScheduleModal = (props: ICreateScheduleModalProps): JSX.Element => {
	const profileForm = useLocalStore(() => (
		defaultScheduleForm
	));

	const validateField = (): void => {
		if (profileForm.name.length === 0) {
			runInAction(() => profileForm.errors.name = errorMessages.name);
		} else {
			runInAction(() => delete profileForm.errors.name);
		}
	};

	const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
		e.preventDefault();

		validateField();

		if (Object.keys(profileForm.errors).length === 0) {
			const newSchedule = new ScheduleEntity();
			newSchedule.name = profileForm.name;
			newSchedule.id = uuidV4();
			
			props.addSchedule(newSchedule);
			store.modal.hide();
		}
	};

	return (
		<div className="schedule-modal-Container">
			<h4>Create Schedule</h4>

			<form onSubmit={onSubmit}>
				<TextField
					model={profileForm}
					modelProperty="name"
					label="Schedule Name"
					isRequired
					errors={profileForm.errors.name}
				/>
				<div className="form-controls">
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={(): void => store.modal.hide()}
					>
						Cancel
					</Button>

					<Button
						type="submit"
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'submit' }}
					>
						Save
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(CreateScheduleModal);
