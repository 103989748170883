/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import MustardManagementFavouriteZoneReferenceManyToMany from '../Security/Acl/MustardManagementFavouriteZoneReferenceManyToMany';
import AgencyPlaylisterFavouriteZoneReferenceManyToMany from '../Security/Acl/AgencyPlaylisterFavouriteZoneReferenceManyToMany';
import AgencyAdminFavouriteZoneReferenceManyToMany from '../Security/Acl/AgencyAdminFavouriteZoneReferenceManyToMany';
import VisitorsFavouriteZoneReferenceManyToMany from '../Security/Acl/VisitorsFavouriteZoneReferenceManyToMany';
import ApplicationUserFavouriteZoneReferenceManyToMany from '../Security/Acl/ApplicationUserFavouriteZoneReferenceManyToMany';
import MustardAdminFavouriteZoneReferenceManyToMany from '../Security/Acl/MustardAdminFavouriteZoneReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IFavouritedByFavouriteZoneAttributes extends IModelAttributes {
	favouritedById: string;
	favouriteZoneId: string;

	favouritedBy: Models.ApplicationUserEntity | Models.IApplicationUserEntityAttributes;
	favouriteZone: Models.ZoneEntity | Models.IZoneEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('FavouritedByFavouriteZone')
export default class FavouritedByFavouriteZone
	extends Model
	implements IFavouritedByFavouriteZoneAttributes {
	public static acls: IAcl[] = [
		new MustardManagementFavouriteZoneReferenceManyToMany(),
		new AgencyPlaylisterFavouriteZoneReferenceManyToMany(),
		new AgencyAdminFavouriteZoneReferenceManyToMany(),
		new VisitorsFavouriteZoneReferenceManyToMany(),
		new ApplicationUserFavouriteZoneReferenceManyToMany(),
		new MustardAdminFavouriteZoneReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public favouritedById: string;

	@observable
	@attribute()
	public favouriteZoneId: string;

	@observable
	@attribute({ isReference: true })
	public favouritedBy: Models.ApplicationUserEntity;

	@observable
	@attribute({ isReference: true })
	public favouriteZone: Models.ZoneEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IFavouritedByFavouriteZoneAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.favouritedById) {
				this.favouritedById = attributes.favouritedById;
			}
			if (attributes.favouriteZoneId) {
				this.favouriteZoneId = attributes.favouriteZoneId;
			}

			if (attributes.favouritedBy) {
				if (attributes.favouritedBy instanceof Models.ApplicationUserEntity) {
					this.favouritedBy = attributes.favouritedBy;
					this.favouritedById = attributes.favouritedBy.id;
				} else {
					this.favouritedBy = new Models.ApplicationUserEntity(attributes.favouritedBy);
					this.favouritedById = this.favouritedBy.id;
				}
			} else if (attributes.favouritedById !== undefined) {
				this.favouritedById = attributes.favouritedById;
			}

			if (attributes.favouriteZone) {
				if (attributes.favouriteZone instanceof Models.ZoneEntity) {
					this.favouriteZone = attributes.favouriteZone;
					this.favouriteZoneId = attributes.favouriteZone.id;
				} else {
					this.favouriteZone = new Models.ZoneEntity(attributes.favouriteZone);
					this.favouriteZoneId = this.favouriteZone.id;
				}
			} else if (attributes.favouriteZoneId !== undefined) {
				this.favouriteZoneId = attributes.favouriteZoneId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
