import React, {
	FormEvent, useState, useEffect, useMemo,
	useCallback,
} from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import axios from 'axios';
import { store } from '../../../../../Models/Store';
import {
	Button, Colors, Display, Sizes,
} from '../../../../Components/Button/Button';
import Tabs from '../../../../Components/Tabs/Tabs';
import { SERVER_URL } from '../../../../../Constants';
import alertToast from '../../../../../Util/ToastifyUtils';
import ScheduleTimeTab from './ScheduleTimeTab';
import { useQueryClient } from 'react-query';
import TrackTile from 'Views/Pages/Playlisting/TrackTile';
import { MoodTrackEntity, TrackEntity } from 'Models/Entities';

interface RemoveTracksModalProps {
	selectedTracks: MoodTrackEntity[];
	onCancel: () => void;
	onConfirm: () => void;
}

const RemoveTracksModal = ({ selectedTracks, onCancel, onConfirm }: RemoveTracksModalProps) => {
	return (
		<div className="modal-container">
			<div className="modal-content">
				<h3 className="modal-title">Remove Selected Tracks</h3>
				<p>You have selected {selectedTracks.length} tracks for removal.</p>
				<ul className="track-list">
					{selectedTracks.map(track => (
						<li key={track.id}>{track.track.title}</li>
					))}
				</ul>
				<div className="move-tracks-form-controls">
					<div className="cancel">
						<Button
							type="button"
							colors={Colors.Primary}
							display={Display.Outline}
							sizes={Sizes.Medium}
							buttonProps={{ id: 'cancel' }}
							onClick={onCancel}
						>
							Cancel
						</Button>
					</div>
					<div className="move">
						<Button
							type="button"
							display={Display.Solid}
							sizes={Sizes.Medium}
							buttonProps={{ id: 'confirm' }}
							onClick={onConfirm}
						>
							Confirm
						</Button>
					</div>

				</div>
			</div>
		</div>
	);
};

interface IPublishProfileModalProps {
  profileId?: string;
  zoneId?: string;
  canPublish: boolean;
  fetchScheduledDateTime: () => void;
}

const parseScheduleDateTime = (publishDateTime: { date: Date; time: Date }) => {
	const selectedDate = publishDateTime.date.toDateString();
	const selectedTime = publishDateTime.time.toTimeString();

	return moment(`${selectedDate} ${selectedTime}`).utc(true).toDate();
};

const PublishProfileModal = ({
	profileId,
	zoneId,
	canPublish,
	fetchScheduledDateTime,
}: IPublishProfileModalProps) => {
	const [currentTab, setCurrentTab] = useState(0);
	const [disabledTracks, setDisabledTracks] = useState<MoodTrackEntity[]>([]);
	const [dislikedTracks, setDislikedTracks] = useState<MoodTrackEntity[]>([]);
	const [removeTracksModalVisible, setRemoveTracksModalVisible] = useState(false); // Track modal visibility
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const queryClient = useQueryClient();

	const refetchings = () => {
		queryClient.refetchQueries('profile');
		queryClient.refetchQueries('settings_stats');
		queryClient.refetchQueries('profile_summary');
		queryClient.refetchQueries('collection_contents');
		queryClient.refetchQueries('mood_contents');
	};

	// clicking cancel on 'remove selected tracks' modal
	const handleCancelRemoveTracksModal = () => {
		setRemoveTracksModalVisible(false);
		setIsLoading(false); // to reactivate the confirm in the publish modal
	};

	const [selectedDisabledTracks, setSelectedDisabledTracks] = useState<string[]>([]);
	const [selectedDislikedTracks, setSelectedDislikedTracks] = useState<string[]>([]);

	const [isAllDisabledSelected, setIsAllDisabledSelected] = useState(false); // select all for disabled
	const [isAllDislikedSelected, setIsAllDislikedSelected] = useState(false); // select all for disliked

	// individual tracks
	const handleTrackCheckDisabled = (
		trackId: string,
		isSelected: boolean,
		setSelectedTracks: React.Dispatch<React.SetStateAction<string[]>>,
	) => {
		setSelectedTracks(prev => {
			const updatedTracks = isSelected
				? [...prev, trackId] // add selected track
				: prev.filter(id => id !== trackId); // remove unselected track

		// synchronize with disliked tracks if the track exists in both lists
			if (dislikedTracks.find(track => track.id === trackId)) {
				setSelectedDislikedTracks(prevDisliked => {
					return isSelected
						? [...prevDisliked, trackId] // select in disliked list
						: prevDisliked.filter(id => id !== trackId); // deselect in disliked list
				});
			}

			setIsAllDisabledSelected(updatedTracks.length === disabledTracks.length);
			return updatedTracks;
		});
	};

	const handleTrackCheckDisliked = (
		trackId: string,
		isSelected: boolean,
		setSelectedTracks: React.Dispatch<React.SetStateAction<string[]>>,
	) => {
		setSelectedTracks(prev => {
			const updatedTracks = isSelected
				? [...prev, trackId]
				: prev.filter(id => id !== trackId);

			if (disabledTracks.find(track => track.id === trackId)) {
				setSelectedDisabledTracks(prevDisabled => {
					return isSelected
						? [...prevDisabled, trackId] // select in disabled list
						: prevDisabled.filter(id => id !== trackId); // deselect in disabled list
				});
			}

			setIsAllDislikedSelected(updatedTracks.length === dislikedTracks.length);
			return updatedTracks;
		});
	};

	// automatically check select all if all tracks are individually selected
	useEffect(() => {
		if (selectedDisabledTracks.length === disabledTracks.length) {
			setIsAllDisabledSelected(true);
		} else {
			setIsAllDisabledSelected(false);
		}
	}, [selectedDisabledTracks, disabledTracks]);

	useEffect(() => {
		if (selectedDislikedTracks.length === dislikedTracks.length) {
			setIsAllDislikedSelected(true);
		} else {
			setIsAllDislikedSelected(false);
		}
	}, [selectedDislikedTracks, dislikedTracks]);

	// select all for disabled
	const handleSelectAllDisabled = () => {
		setIsAllDisabledSelected(!isAllDisabledSelected);
		setSelectedDisabledTracks(!isAllDisabledSelected ? disabledTracks.map(track => track.id) : []);
	};

	// select all for disliked
	const handleSelectAllDisliked = () => {
		setIsAllDislikedSelected(!isAllDislikedSelected);
		setSelectedDislikedTracks(!isAllDislikedSelected ? dislikedTracks.map(track => track.id) : []);
	};

	const publishDateTime = {
		date: moment().add(1, 'day').toDate(),
		time: moment('1970-1-1 4:00').toDate(),
	};

	const fetchDisabledTracks = useCallback(async () => {
		try {
			if (profileId) {
				const { data } = await axios.get(
					`${SERVER_URL}/api/disabledtrack/profile-disabled-tracks-list/${profileId}`,
				);
				setDisabledTracks(data);
			}
		} catch (err) {
			alertToast('Error fetching disabled tracks. Please try again later or contact support!', 'error');
		}
	}, [profileId]);

	const fetchDislikedTracks = useCallback(async () => {
		try {
			if (profileId) {
				const { data } = await axios.get(
					`${SERVER_URL}/api/disabledtrack/profile-disliked-tracks-list/${profileId}`,
				);
				setDislikedTracks(data);
			}
		} catch (err) {
			alertToast('Error fetching disliked tracks. Please try again later or contact support!', 'error');
		}
	}, [profileId]);

	// default for when the modal is first opened
	useEffect(() => {
		fetchDisabledTracks();
		fetchDislikedTracks();
	}, [fetchDisabledTracks, fetchDislikedTracks, profileId]);

	// default select all for when the tracks are first fetched
	useEffect(() => {
		setSelectedDisabledTracks(disabledTracks.map(track => track.id));
		setSelectedDislikedTracks(dislikedTracks.map(track => track.id));
		setIsAllDisabledSelected(true);
		setIsAllDislikedSelected(true);
	}, [disabledTracks, dislikedTracks]);

	const closeModal = () => {
		fetchScheduledDateTime();
		store.modal.hide();
	};
	const removeSelectedTracks = async () => {
		try {
			const selectedTracks = [...selectedDisabledTracks, ...selectedDislikedTracks];
			if (selectedTracks.length === 0) {
				alertToast('No tracks selected for removal.', 'warning');
				return;
			}

			// send as array, not object
			const response = await axios.post(`${SERVER_URL}/api/disabledtrack/remove-selected-tracks`, selectedTracks);

			alertToast('Selected tracks removed successfully.', 'success');

			// clear
			setSelectedDisabledTracks([]);
			setSelectedDislikedTracks([]);

			// refetch any feedbacked tracks that were not removed
			fetchDisabledTracks();
			fetchDislikedTracks();

			let formattedPublishDateTime = moment().utc(true).set('second', 0).set('millisecond', 0)
				.toDate();
			if (currentTab === 0) {
				formattedPublishDateTime = parseScheduleDateTime(publishDateTime);
			}

			try {
				await axios.post(`${SERVER_URL}/api/sync/PublishProfile`, {
					profileId,
					dateTime: formattedPublishDateTime,
					publishNow: currentTab === 1,
				});

				alertToast('Profile is scheduled to be published', 'success');
				closeModal();
			} catch (err) {
				alertToast('Error publishing profile. Please try again later or contact support!', 'error');
			} finally {
				setIsLoading(false);
			}

			refetchings();
		} catch (err) {
			alertToast('Error removing selected tracks. Please try again later or contact support!', 'error');
		}
	};

	const publishZone = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!canPublish) {
			alertToast("You don't have permission to publish profiles!", 'error');
			return;
		}

		if (!zoneId) {
			return;
		}

		try {
			await axios.post(`${SERVER_URL}/api/sync/PublishZones`, {
				zoneIds: [zoneId],
				publishDateTime: currentTab === 0 ? parseScheduleDateTime(publishDateTime) : null,
			});
			alertToast('This zone is scheduled to be published', 'success');
			// TODO: need to refetch zone info, go to EditVenuePage...
		} catch (err) {
			alertToast('Error publishing zones. Please try again later or contact support!', 'error');
		} finally {
			closeModal();
		}
	};

	const publishProfile = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);

		if (!canPublish) {
			alertToast("You don't have permission to publish profiles!", 'error');
			setIsLoading(false);
			return;
		}

		if (!profileId) {
			alertToast('Cannot get profile ID', 'error');
			setIsLoading(false);
			return;
		}

		const selectedTracks = [...selectedDisabledTracks, ...selectedDislikedTracks];
		if (selectedTracks.length > 0) {
      // Show modal before publishing if there are selected tracks
			setRemoveTracksModalVisible(true);
			return;
		}

		let formattedPublishDateTime = moment().utc(true).set('second', 0).set('millisecond', 0)
			.toDate();
		if (currentTab === 0) {
			formattedPublishDateTime = parseScheduleDateTime(publishDateTime);
		}

		try {
			await axios.post(`${SERVER_URL}/api/sync/PublishProfile`, {
				profileId,
				dateTime: formattedPublishDateTime,
				publishNow: currentTab === 1,
			});

			refetchings();

			alertToast('Profile is scheduled to be published', 'success');
			closeModal();
		} catch (err) {
			alertToast('Error publishing profile. Please try again later or contact support!', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="publish-modal-container">
			<h4>Publish</h4>
			<p id="sub-header">{`Would you like to schedule a time or publish ${zoneId ? 'this zone update' : 'all profile updates'} now?`}</p>

			<form onSubmit={zoneId ? publishZone : publishProfile}>
				<Tabs
					className="publish-tabs"
					tabs={[
						{
							component: <ScheduleTimeTab publishDateTime={publishDateTime} isPublishingZone={!!zoneId} />,
							name: 'Schedule a time',
							className: 'publish-tab-content',
							key: 'scheduleTimeTab',
						},
						{
							component: <></>,
							name: 'Publish now',
							className: 'publish-tab-content',
							key: 'publishNowTab',
						},
					]}
					currentTab={currentTab}
					onTabClicked={tabIndex => setCurrentTab(tabIndex)}
				/>

				{disabledTracks.length > 0 && (
					<>
						<h4>Disabled Mood Tracks</h4>
						<div className="select-all">
							<input type="checkbox" checked={isAllDisabledSelected} onChange={handleSelectAllDisabled} />
							<span>{isAllDisabledSelected ? 'Unselect All' : 'Select All'}</span>
						</div>
						<ul className="track-list">
							{disabledTracks.map(track => (
								<>
									<li key={track.id}>
										<input
											type="checkbox"
											checked={selectedDisabledTracks.includes(track.id)}
											onChange={e => handleTrackCheckDisabled(track.id, e.target.checked, setSelectedDisabledTracks)}
										/>

										<TrackTile
											track={track.track}
											hideTrackLengthAndBpm
										/>
									</li>
								</>
							))}
						</ul>

					</>
				)}

				{dislikedTracks.length > 0 && (
					<>
						<h4>Disliked Mood Tracks</h4>
						<div className="select-all">
							<input type="checkbox" checked={isAllDislikedSelected} onChange={handleSelectAllDisliked} />
							<span>{isAllDislikedSelected ? 'Unselect All' : 'Select All'}</span>
						</div>
						<ul className="track-list">
							{dislikedTracks.map(track => (
								<>

									<li key={track.id}>
										<input
											type="checkbox"
											checked={selectedDislikedTracks.includes(track.id)}
											onChange={e => handleTrackCheckDisliked(track.id, e.target.checked, setSelectedDislikedTracks)}
										/>
										<TrackTile
											track={track.track}
											hideTrackLengthAndBpm
										/>
									</li>
								</>
							))}
						</ul>

					</>
				)}

				<div className="form-controls">
					<Button type="button" colors={Colors.Primary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }} onClick={closeModal}>
						Cancel
					</Button>

					<Button type="submit" colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium} buttonProps={{ id: 'submit' }} disabled={isLoading}>
						Confirm
					</Button>
				</div>
			</form>

			{removeTracksModalVisible && (
				<RemoveTracksModal
					selectedTracks={
						// if a song appears as both disliked and disabled, only show/count it once
						Array.from(new Map([...disabledTracks, ...dislikedTracks]
							.filter(track => selectedDisabledTracks.concat(selectedDislikedTracks).includes(track.id))
							.map(track => [track.id, track])).values())
					}
					onCancel={handleCancelRemoveTracksModal}
					onConfirm={() => {
						setRemoveTracksModalVisible(false);
						removeSelectedTracks();
						closeModal();
					}}
				/>

			)}
		</div>
	);
};

export default observer(PublishProfileModal);
