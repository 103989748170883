import React from 'react';
import { observer } from 'mobx-react';
import { Button, Colors, Display } from '../../../../Components/Button/Button';
import { store } from '../../../../../Models/Store';

interface IEditApplicationUserTileHeaderProps {
	enableEdit: () => void;
}

export default observer((props: IEditApplicationUserTileHeaderProps) => {
	const { enableEdit } = props;
	return (
		<div className="application-user-edit-header">
			<Button
				display={Display.Text}
				colors={Colors.Secondary}
				icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}
				onClick={() => store.routerHistory.goBack()}
				className="back-button"
			>
				Back
			</Button>
			<Button
				display={Display.Text}
				colors={Colors.Primary}
				onClick={enableEdit}
			>
				Edit user
			</Button>
		</div>
	);
});
