/* eslint-disable jsx-a11y/label-has-associated-control */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction, toJS } from 'mobx';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useApolloClient } from '@apollo/client';
import {
	ClientEntity, ContactEntity,
	CountryEntity,
	RegionEntity,
	VenueEntity,
	VenueTypeEntity,
} from '../../../../Models/Entities';
import { TextField } from '../../../Components/TextBox/TextBox';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../../Components/Button/Button';
import { IContactDetails } from '../CreateClientPage';
import FileUpload from '../../../Components/FileUpload/FileUpload';
import { Combobox } from '../../../Components/Combobox/Combobox';
import { isEmail } from '../../../../Validators/Functions/Email';
import { isUrl } from '../../../../Validators/Functions/Url';
import { store } from '../../../../Models/Store';
import alert from '../../../../Util/ToastifyUtils';
import { SERVER_URL } from '../../../../Constants';
import { getFetchAllQuery } from '../../../../Util/EntityUtils';
import alertToast from '../../../../Util/ToastifyUtils';

interface IVenueModalProps {
	// eslint-disable-next-line react/require-default-props
	client?: ClientEntity;
	// eslint-disable-next-line react/require-default-props
	venue?: VenueEntity;
	// eslint-disable-next-line react/require-default-props
	refetchClient?: () => void;
	title: string;
	// eslint-disable-next-line react/require-default-props
	redirect?: boolean;
}

interface IVenueForm {
	logo: Blob | null;
	logoFileName: string;
	logoUrl: string;
	logoId: string;
	name: string;
	type: string;
	streetAddressLineOne: string;
	streetAddressLineTwo: string;
	city: string;
	state: string;
	postCode: string;
	region: string;
	client: string;
	country: string;
	latitude: number | null,
	longitude: number | null,
	contacts: IContactDetails[];
	facebookUrl: string;
	instagramUrl: string;
	websiteUrl: string;
	spotifyRadioUrl: string;
	errors: {
		name?: string;
		type: string;
		client: string;
		region: string;
		country: string;
		latitude: string,
		longitude: string,
		contacts: IContactDetails[];
		facebookUrl?: string;
		instagramUrl?: string;
		websiteUrl: string;
		spotifyRadioUrl: string;
		logo: string;
	};
}

const defaultVenueForm: IVenueForm = {
	logo: null,
	logoFileName: '',
	logoUrl: '',
	logoId: '',
	name: '',
	type: '',
	streetAddressLineOne: '',
	streetAddressLineTwo: '',
	city: '',
	state: '',
	postCode: '',
	region: '',
	country: '',
	latitude: null,
	longitude: null,
	client: '',
	contacts: [] as IContactDetails[],
	facebookUrl: '',
	instagramUrl: '',
	websiteUrl: '',
	spotifyRadioUrl: '',
	errors: {
		name: '',
		type: '',
		client: '',
		region: '',
		country: '',
		latitude: '',
		longitude: '',
		contacts: [] as IContactDetails[],
		facebookUrl: '',
		instagramUrl: '',
		websiteUrl: '',
		spotifyRadioUrl: '',
		logo: '',
	},
};

const errorMessages = {
	client: 'Venue must have a client',
	name: 'Venue must have a name.',
	type: 'Venue must have a type.',
	region: 'Venue must have a region.',
	country: 'Venue must have a country.',
	urlMessage: 'Must be a valid url.',
	contactName: 'Contact Name is required.',
	contactNumber: 'A contact number is required if no email address is provided.',
	emailAddress: 'A contact email is required if no contact number is provided.',
	latitude: 'Latitude must be between -90 and 90',
	longitude: 'Longitude must be between -180 and 180',
	logo: 'A logo must be uploaded',
};

const VenueModal = (props: IVenueModalProps): JSX.Element => {
	const {
		client,
		venue,
		refetchClient,
		title,
		redirect,
	} = props;

	const venueStore = useLocalStore(() => ({
		venue: venue ?? new VenueEntity(),
		regions: [] as RegionEntity[],
		countries: [] as CountryEntity[],
		venueTypes: [] as VenueTypeEntity[],
		clients: [] as ClientEntity[],
	}));

	const contactsToDelete = useLocalStore(() => ({
		list: [] as string[],
	}));

	const apollo = useApolloClient();

	useEffect(() => {
		const orderBy = [
			{ path: 'name', descending: false },
		];
		const fetchData = async () => {
			await apollo.query({
				query: getFetchAllQuery(RegionEntity, '', true),
				fetchPolicy: 'network-only',
				variables: { args: [], orderBy },
			}).then((res: any) => {
				runInAction(() => { venueStore.regions = res.data.regionEntitys.map((r: RegionEntity) => new RegionEntity(r)); });
			}).catch(err => console.log('Error fetching regions', err));

			await apollo.query({
				query: getFetchAllQuery(CountryEntity, '', true),
				fetchPolicy: 'network-only',
				variables: { args: [], orderBy },
			}).then((res: any) => {
				runInAction(() => { venueStore.countries = res.data.countryEntitys.map((c: CountryEntity) => new CountryEntity(c)); });
			}).catch(err => console.log('Error fetching countries', err));

			await apollo.query({
				query: getFetchAllQuery(VenueTypeEntity, '', true),
				fetchPolicy: 'network-only',
				variables: { args: [], orderBy },
			}).then((res: any) => {
				runInAction(() => { venueStore.venueTypes = res.data.venueTypeEntitys.map((vt: VenueTypeEntity) => new VenueTypeEntity(vt)); });
			}).catch(err => console.log('Error fetching countries', err));

			if (!client) {
				await apollo.query({
					query: getFetchAllQuery(ClientEntity, '', true),
					fetchPolicy: 'network-only',
					variables: { args: [], orderBy },
				}).then((res: any) => {
					runInAction(() => { venueStore.clients = res.data.clientEntitys.map((r: ClientEntity) => new ClientEntity(r)); });
				}).catch(err => console.log('Error fetching clients', err));
			}
		};

		fetchData();
	}, []);

	const venueForm = useLocalStore(() => (venueStore.venue.name ? {
		logo: venueStore.venue.logo,
		logoFileName: '',
		logoUrl: '',
		logoId: venueStore.venue.logoId,
		name: venueStore.venue.name,
		type: venueStore.venue.venueType?.id,
		streetAddressLineOne: venueStore.venue.streetAddressLineOne,
		streetAddressLineTwo: venueStore.venue.streetAddressLineTwo,
		city: venueStore.venue.city,
		state: venueStore.venue.state,
		postCode: venueStore.venue.postCode,
		region: venueStore.venue.region?.id,
		country: venueStore.venue.country?.id,
		latitude: venueStore.venue.latitude,
		longitude: venueStore.venue.longitude,
		client: venueStore.venue.client?.id,
		contacts: venueStore.venue.contactss.map(c => ({
			contactName: c.name,
			contactNumber: c.phoneNumber,
			emailAddress: c.emailAddress,
			mainContact: c.mainContact,
			id: c.id,
		})) as IContactDetails[],
		facebookUrl: venueStore.venue.facebookURL ?? '',
		instagramUrl: venueStore.venue.instagramURL ?? '',
		websiteUrl: venueStore.venue.websiteURL ?? '',
		spotifyRadioUrl: venueStore.venue.spotifyRadioURL ?? '',
		errors: {
			name: '',
			type: '',
			region: '',
			client: '',
			country: '',
			latitude: '',
			longitude: '',
			contacts: venueStore.venue.contactss?.map(contact => (
				{
					contactName: '',
					contactNumber: '',
					emailAddress: '',
					id: contact.id,
				})) as IContactDetails[],
			facebookUrl: '',
			instagramUrl: '',
			websiteUrl: '',
			spotifyRadioUrl: '',
			logo: '',
		},
	} : defaultVenueForm));

	const validateField = (field: string, index: number = -1) => {
		if (field === 'logoFileName' || field === 'logoUrl') { return; }
		if (store.isMustardAdminOrMustardManager) {
			if (venueForm.client === '') {
				runInAction(() => { venueForm.errors.client = errorMessages.client; });
			}
		}

		if (field === 'contacts') {
			if (!venueForm.errors.contacts) {
				runInAction(() => {
					venueForm.errors.contacts = venueForm.contacts.map(c => ({
						contactName: '', contactNumber: '', emailAddress: '', id: c.id,
					}));
				});
			}
			venueForm.contacts.forEach((contact, i) => {
				Object.keys(contact).forEach(contactField => validateField(contactField, i));
			});
			if (venueForm.errors.contacts?.map(contact => Object.keys(contact).length).reduce((a: number, b: number) => a + b, 0) === 0) {
				runInAction(() => {
					// @ts-ignore
					if (venueForm.errors.contacts) { delete venueForm.errors.contacts; }
				});
			}
			return;
		}

		if (field === 'id' || field === 'errors') {
			return;
		}

		if (field === 'latitude') {
			if (Number.isNaN(Number(venueForm.latitude))) {
				runInAction(() => { venueForm.errors.latitude = 'Latitude must be a number'; });
				return;
			}

			if (!venueForm.latitude || venueForm.latitude < -90 || venueForm.latitude > 90) {
				runInAction(() => { venueForm.errors.latitude = errorMessages.latitude; });
				return;
			}

			runInAction(() => { venueForm.errors.latitude = ''; });
		}

		if (field === 'longitude') {
			if (Number.isNaN(Number(venueForm.longitude))) {
				runInAction(() => { venueForm.errors.longitude = 'Longitude must be a number'; });
				return;
			}

			if (!venueForm.longitude || venueForm.longitude < -180 || venueForm.longitude > 180) {
				runInAction(() => { venueForm.errors.longitude = errorMessages.longitude; });
				return;
			}

			runInAction(() => { venueForm.errors.longitude = ''; });
		}

		if (field === 'logo') {
			if ((venueForm.logo === null || venueForm.logo === undefined)
				&& (venueForm.logoId === null || venueForm.logoId === undefined || venueForm.logoId === '')) {
				runInAction(() => { venueForm.errors.logo = errorMessages.logo; });
				return;
			}

			runInAction(() => { venueForm.errors.logo = ''; });
		}

		runInAction(() => {
			if (field === 'contactNumber') {
				if (venueForm.contacts[index].contactNumber === '') {
					if (venueForm.contacts[index].emailAddress !== '') {
						if (venueForm.errors.contacts) { delete venueForm.errors.contacts[index].contactNumber; }
					} else {
						venueForm.errors.contacts[index].contactNumber = errorMessages.contactNumber;
					}
				} else if (venueForm.errors.contacts) { delete venueForm.errors.contacts[index].contactNumber; }
			} else if (field === 'emailAddress') {
				if (venueForm.contacts[index].emailAddress === '') {
					if (venueForm.contacts[index].contactNumber !== '') {
						if (venueForm.errors.contacts) { delete venueForm.errors.contacts[index].emailAddress; }
					} else {
						venueForm.errors.contacts[index].emailAddress = errorMessages.emailAddress;
					}
				} else if (!isEmail(venueForm.contacts[index].emailAddress || '')) {
					venueForm.errors.contacts[index].emailAddress = 'Not a valid email.';
				} else if (venueForm.errors.contacts) { delete venueForm.errors.contacts[index].emailAddress; }
			} else if (field === 'contactName') {
				if (venueForm.contacts[index].contactName === '' || venueForm.contacts[index].contactName === null) {
					venueForm.errors.contacts[index].contactName = errorMessages.contactName;
				} else if (venueForm.errors.contacts) { delete venueForm.errors.contacts[index].contactName; }
			} else if (field === 'facebookUrl' || field === 'instagramUrl' || field === 'websiteUrl' || field === 'spotifyRadioUrl') {
				if (venueForm[field] !== '' && !isUrl(venueForm[field])) {
					venueForm.errors[field] = errorMessages.urlMessage;
				} else {
					delete venueForm.errors[field];
				}
			} else if (venueForm[field] === '' || venueForm[field] === null) {
				if (Object.keys(errorMessages).includes(field)) {
					venueForm.errors[field] = errorMessages[field];
				}
			} else {
				delete venueForm.errors[field];
			}
		});
	};

	useEffect(() => {
		if (venueStore.venue.logoId) {
			axios.get(`${SERVER_URL}/api/files/metadata/${venueStore.venue.logoId}`)
				.then(res => {
					runInAction(() => { venueForm.logoFileName = res.data.fileName; });
				}).catch(err => console.log('err', err));
		}
	}, [venueForm, venueStore.venue.logoId]);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		setIsSubmitting(true);

		Object.keys(venueForm).forEach(field => validateField(field));
		const actualErrors = venueForm.errors;

		const hasContactErrors = actualErrors.contacts?.filter(contactErrors => {
			if (contactErrors.contactName !== undefined) {
				return true;
			}
			if (contactErrors.contactNumber !== undefined) {
				return true;
			}
			if (contactErrors.emailAddress !== undefined) {
				return true;
			}
			return false;
		});

		if (!hasContactErrors || hasContactErrors.length === 0) {
			runInAction(() => {
				// @ts-ignore
				delete venueForm.errors.contacts;
			});
		}

		if (client) {
			// @ts-ignore
			delete venueForm.errors.client;
		}

		if (Object.keys(venueForm.errors).length === 0) {
			if (venueStore.venue.id === undefined) {
				const newVenue = new VenueEntity();
				newVenue.client = client || venueStore.clients.filter(r => r.id === venueForm.client)[0] || null;
				newVenue.clientId = newVenue.client.id;
				newVenue.name = venueForm.name;
				newVenue.venueType = venueStore.venueTypes.filter(vt => vt.id === venueForm.type)[0] || null;
				newVenue.venueTypeId = venueForm.type || undefined;
				newVenue.streetAddressLineOne = venueForm.streetAddressLineOne;
				newVenue.streetAddressLineTwo = venueForm.streetAddressLineTwo;
				newVenue.city = venueForm.city;
				newVenue.state = venueForm.state;
				newVenue.postCode = venueForm.postCode;
				newVenue.agencyOwnerId = newVenue.client.agencyId || '';
				newVenue.region = venueStore.regions.filter(r => r.id === venueForm.region)[0] || null;
				newVenue.regionId = venueForm.region || undefined;
				newVenue.country = venueStore.countries.filter(c => c.id === venueForm.country)[0] || null;
				newVenue.countryId = venueForm.country || undefined;
				if (venueForm.latitude) { newVenue.latitude = venueForm.latitude; }
				if (venueForm.longitude) { newVenue.longitude = venueForm.longitude; }

				venueForm.contacts.forEach((contact: IContactDetails) => {
					const newContact = new ContactEntity();
					newContact.name = contact.contactName || '';
					newContact.phoneNumber = contact.contactNumber || '';
					newContact.emailAddress = contact.emailAddress || '';
					newContact.mainContact = contact.mainContact || false;
					newContact.agencyOwnerId = newVenue.agencyOwnerId;

					newVenue.contactss.push(newContact);
				});

				newVenue.facebookURL = venueForm.facebookUrl;
				newVenue.instagramURL = venueForm.instagramUrl;
				newVenue.websiteURL = venueForm.websiteUrl;
				newVenue.spotifyRadioURL = venueForm.spotifyRadioUrl;

				newVenue.save({
					region: {},
					country: {},
					contactss: {},
					venueType: {},
				}).then(() => {
					runInAction(() => {
						if (venueForm.logo) {
							newVenue.logo = venueForm.logo;
							newVenue.save(undefined, {
								contentType: 'multipart/form-data',
							}).then(() => {
								alert('Saved Venue', 'success');
								store.modal.hide();
								if (refetchClient) {
									refetchClient();
								}
							}).catch(err => {
								alert('Failed saving venue', 'error');
							});
						} else {
							alert('Saved Venue', 'success');
							store.modal.hide();

							if (redirect) {
								store.routerHistory.push(`/venues/edit/${newVenue.id}`);
							}

							if (refetchClient) {
								refetchClient();
							}
						}
					});
				}).catch(err => {
					alert('Failed saving venue', 'error');
				});
			} else {
				runInAction(() => {
					venueStore.venue.name = venueForm.name;
					venueStore.venue.venueType = venueStore.venueTypes.filter(vt => vt.id === venueForm.type)[0] || null;
					venueStore.venue.venueTypeId = venueForm.type || undefined;
					venueStore.venue.streetAddressLineOne = venueForm.streetAddressLineOne;
					venueStore.venue.streetAddressLineTwo = venueForm.streetAddressLineTwo;
					venueStore.venue.city = venueForm.city;
					venueStore.venue.state = venueForm.state;
					venueStore.venue.postCode = venueForm.postCode;
					venueStore.venue.region = venueStore.regions.filter(r => r.id === venueForm.region)[0] || null;
					venueStore.venue.regionId = venueForm.region || undefined;
					venueStore.venue.country = venueStore.countries.filter(c => c.id === venueForm.country)[0] || null;
					venueStore.venue.countryId = venueForm.country || undefined;
					if (venueForm.latitude) { venueStore.venue.latitude = venueForm.latitude; }
					if (venueForm.longitude) { venueStore.venue.longitude = venueForm.longitude; }

					venueForm.contacts.forEach((contact: IContactDetails, index: number) => {
						if (!contact.id?.includes('new-contact')) {
							venueStore.venue.contactss.filter(c => c.id === contact.id)[0].name = contact.contactName || '';
							venueStore.venue.contactss.filter(c => c.id === contact.id)[0].phoneNumber = contact.contactNumber || '';
							venueStore.venue.contactss.filter(c => c.id === contact.id)[0].emailAddress = contact.emailAddress || '';
							venueStore.venue.contactss.filter(c => c.id === contact.id)[0].mainContact = contact.mainContact || false;
						} else {
							const newContact = new ContactEntity();
							newContact.name = contact.contactName || '';
							newContact.phoneNumber = contact.contactNumber || '';
							newContact.emailAddress = contact.emailAddress || '';
							newContact.mainContact = contact.mainContact || false;
							newContact.agencyOwnerId = client?.agencyId || venueStore.clients.filter(c => c.id === venueForm.client)[0].agencyId || '';

							venueStore.venue.contactss.push(newContact);
						}
					});

					venueStore.venue.facebookURL = venueForm.facebookUrl;
					venueStore.venue.instagramURL = venueForm.instagramUrl;
					venueStore.venue.websiteURL = venueForm.websiteUrl;
					venueStore.venue.spotifyRadioURL = venueForm.spotifyRadioUrl;
				});

				await venueStore.venue.save({
					region: {},
					country: {},
					contactss: {},
					venueType: {},
				}).then(async () => {
					if (contactsToDelete.list.length > 0) {
						let venueContacts: ContactEntity[] = [];
						const filteredVenueContacts = venue?.contactss.filter(c => contactsToDelete.list.includes(c.id));

						if (filteredVenueContacts?.length) {
							venueContacts = filteredVenueContacts;
						}

						await Promise.all([...venueContacts.map(contact => contact.delete())])
							.then(() => refetchClient && refetchClient())
							.then(() => alertToast('Contacts/s deleted', 'success'))
							.then(() => store.modal.hide())
							.catch(() => alertToast('Something went wrong', 'error'));

						runInAction(() => {
							contactsToDelete.list = [] as string[];
						});
					}

					runInAction(async () => {
						if (venueForm.logo) {
							venueStore.venue.logo = venueForm.logo;
							await venueStore.venue.save(undefined, {
								contentType: 'multipart/form-data',
							}).then(() => {
								alert('Updated Venue', 'success');
								store.modal.hide();
							}).catch(err => {
								alert('Failed updating venue', 'error');
							});
						} else {
							alert('Updated Venue', 'success');
							store.modal.hide();

							if (refetchClient) {
								refetchClient();
							}
						}
					});
				}).catch(err => {
					alert('Failed updating venue', 'error');
				});

				if (contactsToDelete.list.length > 0) {
					let venueContacts: ContactEntity[] = [];
					const filteredVenueContacts = venue?.contactss.filter(c => contactsToDelete.list.includes(c.id));

					if (filteredVenueContacts?.length) {
						venueContacts = filteredVenueContacts;
					}

					await Promise.all([...venueContacts.map(contact => contact.delete())])
						.then(() => refetchClient && refetchClient())
						.then(() => alertToast('Contacts/s deleted', 'success'))
						.then(() => store.modal.hide())
						.catch(() => alertToast('Something went wrong', 'error'));

					runInAction(() => {
						contactsToDelete.list = [] as string[];
					});
				}
			}
		}
		setIsSubmitting(false);
	};

	return (
		<div className="venue-modal-container">
			<h4>{title}</h4>
			<form onSubmit={onSubmit}>
				<FileUpload
					model={venueForm}
					modelProperty="logo"
					label="Logo"
					labelVisible={false}
					disableDropArea
					errors={venueForm.errors.logo}
					buttonText="Upload Logo"
					tooltip="The venue logo works best with a 7:5 aspect ratio."
					preview={(file, onDelete) => {
						if (file) {
							const reader = new FileReader();
							reader.onload = (event: ProgressEvent<FileReader>) => {
								const result = event.target?.result;
								if (typeof result === 'string') {
									runInAction(() => {
										venueForm.logoUrl = result;
									});
								}
							};
							reader.readAsDataURL(file);
							return <img className="logo-preview" src={venueForm.logoUrl} alt={venueForm.logoFileName} />;
						}

						if (venueStore.venue.logoId) {
							return <img className="logo-preview" src={`${SERVER_URL}/api/files/${venueStore.venue.logoId}`} alt={venueForm.logoFileName} />;
						}

						return <div className="logo-preview-na" />;
					}}
				/>

				{!client && (
					<Combobox
						model={venueForm}
						modelProperty="client"
						label="Clients"
						className="client"
						isRequired
						errors={venueForm.errors.client}
						options={venueStore.clients.map((r: ClientEntity) => ({ display: r.name, value: r.id }))}
					/>
				)}

				<div className="double-input">
					<TextField
						model={venueForm}
						modelProperty="name"
						label="Venue Name"
						placeholder="Venue Name"
						className="venueName"
						isRequired
						errors={venueForm.errors.name}
					/>
					<Combobox
						model={venueForm}
						modelProperty="type"
						label="Venue Type"
						options={venueStore.venueTypes.map((vt: VenueTypeEntity) => ({ display: vt.name, value: vt.id }))}
						className="type"
						isRequired
						errors={venueForm.errors.type}
					/>
				</div>
				<TextField
					model={venueForm}
					modelProperty="streetAddressLineOne"
					label="Street Address Line 1"
					className="venueAddress"
				/>
				<TextField
					model={venueForm}
					modelProperty="streetAddressLineTwo"
					label="Street Address Line 2"
					className="venueAddress"
				/>
				<TextField
					model={venueForm}
					modelProperty="city"
					label="City/Town"
					className="venueAddress"
				/>
				<div className="double-input">
					<TextField
						model={venueForm}
						modelProperty="state"
						label="State"
						className="venueAddress"
					/>
					<TextField
						model={venueForm}
						modelProperty="postCode"
						label="Postal/Zip Code"
						className="venueAddress"
					/>
				</div>
				<div className="double-input">
					<Combobox
						model={venueForm}
						modelProperty="region"
						label="Region"
						className="region"
						isRequired
						errors={venueForm.errors.region}
						options={venueStore.regions.map((r: RegionEntity) => ({ display: r.name, value: r.id }))}
					/>
					<Combobox
						model={venueForm}
						modelProperty="country"
						label="Country"
						className="country"
						isRequired
						errors={venueForm.errors.country}
						isDisabled={venueForm.region === ''}
						options={venueStore.countries.filter((c: CountryEntity) => c.regionId === venueForm.region).map((c: CountryEntity) => ({ display: c.name, value: c.id }))}
					/>
				</div>
				<div className="double-input">
					<TextField
						model={venueForm}
						modelProperty="latitude"
						label="Latitude"
						className="latitude"
						isRequired
						errors={venueForm.errors.latitude}
					/>
					<TextField
						model={venueForm}
						modelProperty="longitude"
						label="Longitude"
						className="longitude"
						isRequired
						errors={venueForm.errors.longitude}
					/>
				</div>
				<h5>Contact Information</h5>
				{venueForm.contacts
					.filter(c => !contactsToDelete.list.includes(c.id || ''))
					.map((contact: IContactDetails, contactIndex: number) => (
						<div key={`contact-form-wrapper-${contact.id}`}>
							<div className="contact-header">
								<h5>{`Contact ${contactIndex + 1}`}</h5>
								<Button
									type="button"
									colors={Colors.Primary}
									display={Display.Text}
									sizes={Sizes.Small}
									onClick={() => {
										runInAction(() => {
											contactsToDelete.list = [...contactsToDelete.list, contact.id || ''];
											venueForm.errors.contacts = [...venueForm.errors.contacts.filter(c => c.id !== contact.id)];
											venueForm.contacts = [...venueForm.contacts.filter(c => c.id !== contact.id)];
										});
									}}
								>
									Delete
								</Button>
							</div>
							<div className="main-contact-switch">
								<p>Main Contact</p>
								<label className="switch">
									<input
										type="checkbox"
										onChange={() => runInAction(() => {
											venueForm.contacts.filter(c => c.id === contact.id)[0].mainContact = !venueForm.contacts.filter(c => c.id === contact.id)[0].mainContact;
											venueForm.contacts.filter(c => c.id !== contact.id).forEach(c => runInAction(() => c.mainContact = false));
										})}
										checked={contact.mainContact}
									/>
									<span className="slider round" />
								</label>
							</div>
							{/* eslint-disable-next-line react/no-array-index-key */}
							<div className="contact-wrapper" key={`contact-${contactIndex}`}>
								<div className="double-input">
									<TextField
										model={contact}
										modelProperty="contactName"
										label="Contact Name"
										className="contactName"
										isRequired
										errors={venueForm.errors.contacts !== undefined ? venueForm.errors.contacts.filter(c => c.id === contact.id)[0].contactName : ''}
									/>
									<TextField
										model={contact}
										modelProperty="contactNumber"
										label="Contact Number"
										className="contactNumber"
										errors={venueForm.errors.contacts !== undefined ? venueForm.errors.contacts.filter(c => c.id === contact.id)[0].contactNumber : ''}
									/>
								</div>
								<TextField
									model={contact}
									modelProperty="emailAddress"
									label="Email Address"
									className="emailAddress"
									errors={venueForm.errors.contacts !== undefined ? venueForm.errors.contacts.filter(c => c.id === contact.id)[0].emailAddress : ''}
								/>
								<br />
							</div>
						</div>
					))}
				<Button
					type="button"
					colors={Colors.Primary}
					display={Display.Text}
					sizes={Sizes.Small}
					icon={{ icon: 'plus', iconPos: 'icon-left' }}
					onClick={() => {
						runInAction(() => {
							const tempId = uuidv4();

							venueForm.contacts = [...venueForm.contacts, {
								contactName: '',
								contactNumber: '',
								emailAddress: '',
								mainContact: false,
								id: `new-contact-${tempId}`,
							}];

							if (!venueForm.errors.contacts) { venueForm.errors.contacts = [{ id: `new-contact-${tempId}` }]; } else {
								venueForm.errors.contacts = [...venueForm.errors.contacts, {
									contactName: '',
									contactNumber: '',
									emailAddress: '',
									id: `new-contact-${tempId}`,
								}];
							}
						});
					}}
				>
					Add contact
				</Button>
				<h5>Links</h5>
				<TextField
					model={venueForm}
					modelProperty="websiteUrl"
					label="Website URL"
					className="website"
					errors={venueForm.errors.websiteUrl !== undefined ? venueForm.errors.websiteUrl : ''}
				/>
				<TextField
					model={venueForm}
					modelProperty="facebookUrl"
					label="Facebook URL"
					className="facebookUrl"
					errors={venueForm.errors.facebookUrl !== undefined ? venueForm.errors.facebookUrl : ''}
				/>
				<TextField
					model={venueForm}
					modelProperty="instagramUrl"
					label="Instagram URL"
					className="instagramUrl"
					errors={venueForm.errors.instagramUrl !== undefined ? venueForm.errors.instagramUrl : ''}
				/>
				<TextField
					model={venueForm}
					modelProperty="spotifyRadioUrl"
					label="Venue Radio URL"
					className="spotifyRadioUrl"
					errors={venueForm.errors.spotifyRadioUrl !== undefined ? venueForm.errors.spotifyRadioUrl : ''}
				/>
				<div className="form-controls">
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={() => store.modal.hide()}
					>
						Cancel
					</Button>
					<Button
						type="submit"
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'submit' }}
						disabled={isSubmitting}
					>
						Save Venue
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(VenueModal);
