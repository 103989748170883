import React from 'react';
import { ReactComponent as Play } from '../../../assets/play.svg';
import { ReactComponent as Pause } from '../../../assets/pause.svg';

interface AudioControlsProps {
	isPlaying: boolean;
	onPlayPauseClick: (playing: boolean) => void;
	interact: boolean;
}

const AudioControls = (props: AudioControlsProps): JSX.Element => {
	const {
		isPlaying, onPlayPauseClick, interact,
	} = props;

	return (
		<div className="audio-controls">
			{isPlaying ? (
				<button
					type="button"
					className="pause"
					onClick={(): void => {
						if (interact) {
							onPlayPauseClick(false);
						}
					}}
					aria-label="Pause"
				>
					<Pause />
				</button>
			) : (
				<button
					type="button"
					className="play"
					onClick={(): void => {
						if (interact) {
							onPlayPauseClick(true);
						}
					}}
					aria-label="Play"
				>
					<Play />
				</button>
			)}
		</div>
	);
};

export default AudioControls;
