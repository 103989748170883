import React, { ChangeEvent, SyntheticEvent } from 'react';
import { IImportTrackValidationModel } from 'Models/Custom/ImportTrackValidationModel';
import TrackTile from '../Playlisting/TrackTile';
import * as uuid from 'uuid';
import InputWrapper, { InputType } from '../../Components/Inputs/InputWrapper';
import { TrackEntity } from 'Models/Entities';
import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { DropdownProps } from 'semantic-ui-react';

interface ImportTrackValidationPanelProps {
	validatedTracks: IImportTrackValidationModel[];
	matchedTracks: TrackEntity[];
	missingTracks: IImportTrackValidationModel[];
}

interface TrackRowProps {
	track: IImportTrackValidationModel;
	selectedTrack: TrackEntity | undefined;
	checked: boolean;
	matchedTracks: TrackEntity[];
	missingTracks: IImportTrackValidationModel[];
}

const TrackRow = (props: TrackRowProps): JSX.Element => {
	const {
		track, selectedTrack, matchedTracks, checked, missingTracks,
	} = props;

	const onChecked = (event: ChangeEvent<HTMLInputElement>): void => {
		const selectedTrackId = track.selectedTrack?.id;
		if (!selectedTrackId) {
			return;
		}

		const selectedTrackIndex = matchedTracks.findIndex(mt => mt.id === selectedTrackId);
		const missingTrackIndex = missingTracks.findIndex(mt => mt.id === track.id);

		if (event.target.checked) {
			if (selectedTrackIndex === -1) {
				runInAction(() => matchedTracks.push(track.selectedTrack!));
			}
			if (missingTrackIndex !== -1) {
				runInAction(() => missingTracks.splice(missingTrackIndex, 1));
			}
		} else {
			if (selectedTrackIndex !== -1) {
				runInAction(() => matchedTracks.splice(selectedTrackIndex, 1));
			}
			if (missingTrackIndex === -1) {
				runInAction(() => missingTracks.push({
					...track, selectedTrack: undefined, selectedTrackId: undefined, matchedTracks: [],
				}));
			}
		}
	};

	const onDropdownChange = (e: SyntheticEvent<HTMLElement, Event>, d: DropdownProps) => {
		const oldSelectedTrackId = track.selectedTrackId;

		const selectedTrack = track.matchedTracks
			?.map(x => x.track)
			.filter(x => x.id == d.value as string)[0];

		if (!selectedTrack) return;
		if (selectedTrack.id === oldSelectedTrackId) return;

		track.selectedTrackId = selectedTrack.id;
		track.selectedTrack = selectedTrack;

		if (checked) {
			if (oldSelectedTrackId && oldSelectedTrackId !== '') {
				// Remove old selected track from matched track list
				const pos = matchedTracks.findIndex(x => x.id === oldSelectedTrackId);
				if (pos !== -1) {
					runInAction(() => matchedTracks.splice(pos, 1));
				}
			}

			if (!matchedTracks.some(x => x.id === track.selectedTrackId)) {
				runInAction(() => matchedTracks.push(track.selectedTrack!));
			}
		}
	};

	const id = uuid.v4().toString();
	const fieldId = `${id}-field`;
	return (
		<>
			<div className="existing-track-row py-2">
				<div className="track-title" />
				<div className="track-title">{track.title}</div>

				<div className="track-artist">
					{track.artists.join(', ')}
				</div>

				<div className="track-album">{track.album}</div>
			</div>
			<div className="existing-track-row border">
				<InputWrapper
					id={id}
					inputType={InputType.CHECKBOX}
					// className={`${track.matchedTracks !== null ? '' : 'hidden'}`}
					inputId={fieldId}
				>
					<input
						type="checkbox"
						id={fieldId}
						onChange={onChecked}
						checked={checked}
						disabled={!track.matchedTracks || track.matchedTracks?.length == 0}
					/>
				</InputWrapper>
				{selectedTrack && track.matchedTracks && track.matchedTracks?.length > 0 ? (
					<>
						<TrackTile
							track={selectedTrack}
							isLarge
							customClassNames="import-track-tile"
							hideTrackLengthAndBpm
						/>
						<Combobox
							key={uuid.v4()}
							model={track}
							className="spotify-track-selection"
							modelProperty="selectedTrackId"
							label="Matches"
							labelVisible={false}
							isDisabled={track.matchedTracks.length == 1 && track.matchedTracks[0].matchConfidence > 0.9}
							options={track.matchedTracks?.map(t => ({
								display: `${t.track.title} | ${t.track.artistss.map(a => a.artists.name).join(', ')}`,
								value: t.track.id,
							}))}
							onChange={onDropdownChange}
						/>
					</>
				) : (
					<div className="track-label-lrg art f2">
						<p className="ml">No Suitable Match Found</p>
					</div>
				)}
			</div>
		</>
	);
};

const ImportTrackValidationPanel = (props: ImportTrackValidationPanelProps): JSX.Element => {
	const { validatedTracks, matchedTracks, missingTracks } = props;

	const matchState = useLocalStore(() => ({
		matchedTracks: new Array<TrackEntity>(),
		missingTracks: new Array<IImportTrackValidationModel>(),
		trackListing: validatedTracks,
	}));
	runInAction(() => {
		matchState.matchedTracks = matchedTracks;
		matchState.missingTracks = missingTracks;
	});

	return (
		<div className="existing-track-panel">
			<div className="existing-track-info">
				{`${missingTracks.length} Missing Tracks`}
				{' | '}
				{`${matchedTracks.length} Selected For Import`}
			</div>

			<p className="existing-track-warning">
				You must purchase and upload the missing tracks to the Music Library, before they can be added to a playlist.
			</p>

			<div style={{ display: 'grid' }}>
				<div className="existing-track-header">
					<div className="f2">Match Information</div>
					<div className="f2">Spotify Track</div>
					<div className="f1">Spotify Artist</div>
					<div className="f1">Spotify Album</div>
				</div>
				<div className="existing-track-container no-top-border">
					{matchState.trackListing.map(track => (
						<TrackRow
							key={track.id}
							track={track}
							selectedTrack={track.selectedTrack}
							matchedTracks={matchState.matchedTracks}
							checked={matchedTracks.some(x => x.id === track.selectedTrackId)}
							missingTracks={matchState.missingTracks}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default observer(ImportTrackValidationPanel);
