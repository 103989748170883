/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterPlaylistTrackEntity from 'Models/Security/Acl/AgencyPlaylisterPlaylistTrackEntity';
import VisitorsPlaylistTrackEntity from 'Models/Security/Acl/VisitorsPlaylistTrackEntity';
import ApplicationUserPlaylistTrackEntity from 'Models/Security/Acl/ApplicationUserPlaylistTrackEntity';
import AgencyAdminPlaylistTrackEntity from 'Models/Security/Acl/AgencyAdminPlaylistTrackEntity';
import MustardManagementPlaylistTrackEntity from 'Models/Security/Acl/MustardManagementPlaylistTrackEntity';
import MustardAdminPlaylistTrackEntity from 'Models/Security/Acl/MustardAdminPlaylistTrackEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IPlaylistTrackEntityAttributes extends IModelAttributes {
	order: number;
	agencyOwnerId: string;

	playlistId: string;
	playlist: Models.PlaylistEntity | Models.IPlaylistEntityAttributes;
	trackId?: string;
	track?: Models.TrackEntity | Models.ITrackEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('PlaylistTrackEntity', 'Playlist Track')
// % protected region % [Customise your entity metadata here] end
export default class PlaylistTrackEntity extends Model
	implements IPlaylistTrackEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterPlaylistTrackEntity(),
		new VisitorsPlaylistTrackEntity(),
		new ApplicationUserPlaylistTrackEntity(),
		new AgencyAdminPlaylistTrackEntity(),
		new MustardManagementPlaylistTrackEntity(),
		new MustardAdminPlaylistTrackEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'order',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Order'] off begin
	@Validators.Integer()
	@observable
	@attribute<PlaylistTrackEntity, number>()
	@CRUD({
		name: 'Order',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<PlaylistTrackEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Playlist'] off begin
		name: 'Playlist',
		displayType: 'reference-combobox',
		order: 30,
		referenceTypeFunc: () => Models.PlaylistEntity,
		// % protected region % [Modify props to the crud options here for reference 'Playlist'] end
	})
	public playlistId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public playlist: Models.PlaylistEntity;

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Track'] off begin
		name: 'Track',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.TrackEntity,
		// % protected region % [Modify props to the crud options here for reference 'Track'] end
	})
	public trackId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public track: Models.TrackEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IPlaylistTrackEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IPlaylistTrackEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.playlistId !== undefined) {
				this.playlistId = attributes.playlistId;
			}
			if (attributes.playlist !== undefined) {
				if (attributes.playlist === null) {
					this.playlist = attributes.playlist;
				} else if (attributes.playlist instanceof Models.PlaylistEntity) {
					this.playlist = attributes.playlist;
					this.playlistId = attributes.playlist.id;
				} else {
					this.playlist = new Models.PlaylistEntity(attributes.playlist);
					this.playlistId = this.playlist.id;
				}
			}
			if (attributes.trackId !== undefined) {
				this.trackId = attributes.trackId;
			}
			if (attributes.track !== undefined) {
				if (attributes.track === null) {
					this.track = attributes.track;
				} else if (attributes.track instanceof Models.TrackEntity) {
					this.track = attributes.track;
					this.trackId = attributes.track.id;
				} else {
					this.track = new Models.TrackEntity(attributes.track);
					this.trackId = this.track.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		playlist {
			${Models.PlaylistEntity.getAttributes().join('\n')}
		}
		track {
			${Models.TrackEntity.getAttributes().join('\n')}
			${Models.TrackEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return `${this.track?.title ?? this.id}`;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(PlaylistTrackEntity.prototype, 'created');
CRUD(modifiedAttr)(PlaylistTrackEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
