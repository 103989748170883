/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import AgencyPlaylisterTracksReferenceManyToMany from '../Security/Acl/AgencyPlaylisterTracksReferenceManyToMany';
import ApplicationUserTracksReferenceManyToMany from '../Security/Acl/ApplicationUserTracksReferenceManyToMany';
import MustardAdminTracksReferenceManyToMany from '../Security/Acl/MustardAdminTracksReferenceManyToMany';
import AgencyAdminTracksReferenceManyToMany from '../Security/Acl/AgencyAdminTracksReferenceManyToMany';
import VisitorsTracksReferenceManyToMany from '../Security/Acl/VisitorsTracksReferenceManyToMany';
import MustardManagementTracksReferenceManyToMany from '../Security/Acl/MustardManagementTracksReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IArtistsTracksAttributes extends IModelAttributes {
	artistsId: string;
	tracksId: string;

	artists: Models.ArtistEntity | Models.IArtistEntityAttributes;
	tracks: Models.TrackEntity | Models.ITrackEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('ArtistsTracks')
export default class ArtistsTracks
	extends Model
	implements IArtistsTracksAttributes {
	public static acls: IAcl[] = [
		new AgencyPlaylisterTracksReferenceManyToMany(),
		new ApplicationUserTracksReferenceManyToMany(),
		new MustardAdminTracksReferenceManyToMany(),
		new AgencyAdminTracksReferenceManyToMany(),
		new VisitorsTracksReferenceManyToMany(),
		new MustardManagementTracksReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public artistsId: string;

	@observable
	@attribute()
	public tracksId: string;

	@observable
	@attribute({ isReference: true })
	public artists: Models.ArtistEntity;

	@observable
	@attribute({ isReference: true })
	public tracks: Models.TrackEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IArtistsTracksAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.artistsId) {
				this.artistsId = attributes.artistsId;
			}
			if (attributes.tracksId) {
				this.tracksId = attributes.tracksId;
			}

			if (attributes.artists) {
				if (attributes.artists instanceof Models.ArtistEntity) {
					this.artists = attributes.artists;
					this.artistsId = attributes.artists.id;
				} else {
					this.artists = new Models.ArtistEntity(attributes.artists);
					this.artistsId = this.artists.id;
				}
			} else if (attributes.artistsId !== undefined) {
				this.artistsId = attributes.artistsId;
			}

			if (attributes.tracks) {
				if (attributes.tracks instanceof Models.TrackEntity) {
					this.tracks = attributes.tracks;
					this.tracksId = attributes.tracks.id;
				} else {
					this.tracks = new Models.TrackEntity(attributes.tracks);
					this.tracksId = this.tracks.id;
				}
			} else if (attributes.tracksId !== undefined) {
				this.tracksId = attributes.tracksId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
