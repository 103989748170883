/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import AgencyPlaylisterManagesZoneReferenceManyToMany from '../Security/Acl/AgencyPlaylisterManagesZoneReferenceManyToMany';
import VisitorsManagesZoneReferenceManyToMany from '../Security/Acl/VisitorsManagesZoneReferenceManyToMany';
import MustardManagementManagesZoneReferenceManyToMany from '../Security/Acl/MustardManagementManagesZoneReferenceManyToMany';
import MustardAdminManagesZoneReferenceManyToMany from '../Security/Acl/MustardAdminManagesZoneReferenceManyToMany';
import ApplicationUserManagesZoneReferenceManyToMany from '../Security/Acl/ApplicationUserManagesZoneReferenceManyToMany';
import AgencyAdminManagesZoneReferenceManyToMany from '../Security/Acl/AgencyAdminManagesZoneReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IManagedByManagesZoneAttributes extends IModelAttributes {
	managedById: string;
	managesZoneId: string;

	managedBy: Models.ApplicationUserEntity | Models.IApplicationUserEntityAttributes;
	managesZone: Models.ZoneEntity | Models.IZoneEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('ManagedByManagesZone')
export default class ManagedByManagesZone
	extends Model
	implements IManagedByManagesZoneAttributes {
	public static acls: IAcl[] = [
		new AgencyPlaylisterManagesZoneReferenceManyToMany(),
		new VisitorsManagesZoneReferenceManyToMany(),
		new MustardManagementManagesZoneReferenceManyToMany(),
		new MustardAdminManagesZoneReferenceManyToMany(),
		new ApplicationUserManagesZoneReferenceManyToMany(),
		new AgencyAdminManagesZoneReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public managedById: string;

	@observable
	@attribute()
	public managesZoneId: string;

	@observable
	@attribute({ isReference: true })
	public managedBy: Models.ApplicationUserEntity;

	@observable
	@attribute({ isReference: true })
	public managesZone: Models.ZoneEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IManagedByManagesZoneAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.managedById) {
				this.managedById = attributes.managedById;
			}
			if (attributes.managesZoneId) {
				this.managesZoneId = attributes.managesZoneId;
			}

			if (attributes.managedBy) {
				if (attributes.managedBy instanceof Models.ApplicationUserEntity) {
					this.managedBy = attributes.managedBy;
					this.managedById = attributes.managedBy.id;
				} else {
					this.managedBy = new Models.ApplicationUserEntity(attributes.managedBy);
					this.managedById = this.managedBy.id;
				}
			} else if (attributes.managedById !== undefined) {
				this.managedById = attributes.managedById;
			}

			if (attributes.managesZone) {
				if (attributes.managesZone instanceof Models.ZoneEntity) {
					this.managesZone = attributes.managesZone;
					this.managesZoneId = attributes.managesZone.id;
				} else {
					this.managesZone = new Models.ZoneEntity(attributes.managesZone);
					this.managesZoneId = this.managesZone.id;
				}
			} else if (attributes.managesZoneId !== undefined) {
				this.managesZoneId = attributes.managesZoneId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
