/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import moment from 'moment';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterAlbumEntity from 'Models/Security/Acl/AgencyPlaylisterAlbumEntity';
import MustardAdminAlbumEntity from 'Models/Security/Acl/MustardAdminAlbumEntity';
import VisitorsAlbumEntity from 'Models/Security/Acl/VisitorsAlbumEntity';
import AgencyAdminAlbumEntity from 'Models/Security/Acl/AgencyAdminAlbumEntity';
import MustardManagementAlbumEntity from 'Models/Security/Acl/MustardManagementAlbumEntity';
import ApplicationUserAlbumEntity from 'Models/Security/Acl/ApplicationUserAlbumEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IAlbumEntityAttributes extends IModelAttributes {
	title: string;
	artworkId: string;
	artwork: Blob;
	releaseDate: Date;
	recordLabel: string;

	artistss: Array<
		| Models.ArtistsAlbums
		| Models.IArtistsAlbumsAttributes
	>;
	trackss: Array<
		| Models.TrackEntity
		| Models.ITrackEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AlbumEntity', 'Album')
// % protected region % [Customise your entity metadata here] end
export default class AlbumEntity extends Model
	implements IAlbumEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterAlbumEntity(),
		new MustardAdminAlbumEntity(),
		new VisitorsAlbumEntity(),
		new AgencyAdminAlbumEntity(),
		new MustardManagementAlbumEntity(),
		new ApplicationUserAlbumEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Title'] off begin
	@observable
	@attribute<AlbumEntity, string>()
	@CRUD({
		name: 'Title',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public title: string;
	// % protected region % [Modify props to the crud options here for attribute 'Title'] end

	// % protected region % [Modify props to the crud options here for attribute 'Artwork'] off begin
	@observable
	@attribute<AlbumEntity, string>({ file: 'artwork' })
	@CRUD({
		name: 'Artwork',
		displayType: 'file',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'artwork',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Album" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public artworkId: string;

	@observable
	public artwork: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Artwork'] end

	// % protected region % [Modify props to the crud options here for attribute 'Release Date'] off begin
	@observable
	@attribute<AlbumEntity, Date>({ serialise: (e, a) => AttrUtils.serialiseDate(a) })
	@CRUD({
		name: 'Release Date',
		displayType: 'datepicker',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: attr => AttrUtils.standardiseDate(attr, { serialiseAs: 'date' }),
	})
	public releaseDate: Date;
	// % protected region % [Modify props to the crud options here for attribute 'Release Date'] end

	// % protected region % [Modify props to the crud options here for attribute 'Record Label'] off begin
	@observable
	@attribute<AlbumEntity, string>()
	@CRUD({
		name: 'Record Label',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public recordLabel: string;
	// % protected region % [Modify props to the crud options here for attribute 'Record Label'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Artists'] off begin
		name: 'Artists',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ArtistsAlbums,
		optionEqualFunc: makeJoinEqualsFunc('artistsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'albumEntity',
			oppositeEntityName: 'artistEntity',
			relationName: 'albums',
			relationOppositeName: 'artists',
			entity: () => Models.AlbumEntity,
			joinEntity: () => Models.ArtistsAlbums,
			oppositeEntity: () => Models.ArtistEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Artists'] end
	})
	public artistss: Models.ArtistsAlbums[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Tracks'] off begin
		name: 'Trackss',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.TrackEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'trackss',
			oppositeEntity: () => Models.TrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Tracks'] end
	})
	public trackss: Models.TrackEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAlbumEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAlbumEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.title !== undefined) {
				this.title = attributes.title;
			}
			if (attributes.artwork !== undefined) {
				this.artwork = attributes.artwork;
			}
			if (attributes.artworkId !== undefined) {
				this.artworkId = attributes.artworkId;
			}
			if (attributes.releaseDate !== undefined) {
				if (attributes.releaseDate === null) {
					this.releaseDate = attributes.releaseDate;
				} else {
					this.releaseDate = moment(attributes.releaseDate).toDate();
				}
			}
			if (attributes.recordLabel !== undefined) {
				this.recordLabel = attributes.recordLabel;
			}
			if (attributes.artistss !== undefined && Array.isArray(attributes.artistss)) {
				for (const model of attributes.artistss) {
					if (model instanceof Models.ArtistsAlbums) {
						this.artistss.push(model);
					} else {
						this.artistss.push(new Models.ArtistsAlbums(model));
					}
				}
			}
			if (attributes.trackss !== undefined && Array.isArray(attributes.trackss)) {
				for (const model of attributes.trackss) {
					if (model instanceof Models.TrackEntity) {
						this.trackss.push(model);
					} else {
						this.trackss.push(new Models.TrackEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		artistss {
			${Models.ArtistsAlbums.getAttributes().join('\n')}
			artists {
				${Models.ArtistEntity.getAttributes().join('\n')}
				${Models.ArtistEntity.getFiles().map(f => f.name).join('\n')}
			}
		}
		trackss {
			${Models.TrackEntity.getAttributes().join('\n')}
			${Models.TrackEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			artistss: {},
			trackss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'artistss',
							'trackss',
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.title;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AlbumEntity.prototype, 'created');
CRUD(modifiedAttr)(AlbumEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
