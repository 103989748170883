/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import Navigation, { Orientation, ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router';
import * as Models from 'Models/Entities';
import { Model } from 'Models/Model';
import { IIconProps } from 'Views/Components/Helpers/Common';
import SecurityService from 'Services/SecurityService';
import { getModelDisplayName } from 'Util/EntityUtils';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

interface AdminLink extends IIconProps {
	path: string;
	label: string;
	entity: {new (args: any): Model};
	isMember?: boolean;
	// % protected region % [Add extra AdminLink fields here] off begin
	// % protected region % [Add extra AdminLink fields here] end
}

const getPageLinks = (): AdminLink[] => [
	{
		// % protected region % [Override navigation link for AgencyAdminEntity here] off begin
		path: '/admin/agencyadminentity',
		label: getModelDisplayName(Models.AgencyAdminEntity),
		entity: Models.AgencyAdminEntity,
		isMember: true,
		// % protected region % [Override navigation link for AgencyAdminEntity here] end
	},
	{
		// % protected region % [Override navigation link for AgencyEntity here] off begin
		path: '/admin/agencyentity',
		label: getModelDisplayName(Models.AgencyEntity),
		entity: Models.AgencyEntity,
		isMember: false,
		// % protected region % [Override navigation link for AgencyEntity here] end
	},
	{
		// % protected region % [Override navigation link for AgencyPlaylisterEntity here] off begin
		path: '/admin/agencyplaylisterentity',
		label: getModelDisplayName(Models.AgencyPlaylisterEntity),
		entity: Models.AgencyPlaylisterEntity,
		isMember: true,
		// % protected region % [Override navigation link for AgencyPlaylisterEntity here] end
	},
	{
		// % protected region % [Override navigation link for AgencyTagEntity here] off begin
		path: '/admin/agencytagentity',
		label: getModelDisplayName(Models.AgencyTagEntity),
		entity: Models.AgencyTagEntity,
		isMember: false,
		// % protected region % [Override navigation link for AgencyTagEntity here] end
	},
	{
		// % protected region % [Override navigation link for AlbumEntity here] off begin
		path: '/admin/albumentity',
		label: getModelDisplayName(Models.AlbumEntity),
		entity: Models.AlbumEntity,
		isMember: false,
		// % protected region % [Override navigation link for AlbumEntity here] end
	},
	{
		// % protected region % [Override navigation link for AnnouncementEntity here] off begin
		path: '/admin/announcemententity',
		label: getModelDisplayName(Models.AnnouncementEntity),
		entity: Models.AnnouncementEntity,
		isMember: false,
		// % protected region % [Override navigation link for AnnouncementEntity here] end
	},
	{
		// % protected region % [Override navigation link for AnnouncementTimeslotEntity here] off begin
		path: '/admin/announcementtimeslotentity',
		label: getModelDisplayName(Models.AnnouncementTimeslotEntity),
		entity: Models.AnnouncementTimeslotEntity,
		isMember: false,
		// % protected region % [Override navigation link for AnnouncementTimeslotEntity here] end
	},
	{
		// % protected region % [Override navigation link for ApplicationUserEntity here] off begin
		path: '/admin/applicationuserentity',
		label: getModelDisplayName(Models.ApplicationUserEntity),
		entity: Models.ApplicationUserEntity,
		isMember: true,
		// % protected region % [Override navigation link for ApplicationUserEntity here] end
	},
	{
		// % protected region % [Override navigation link for ArtistEntity here] off begin
		path: '/admin/artistentity',
		label: getModelDisplayName(Models.ArtistEntity),
		entity: Models.ArtistEntity,
		isMember: false,
		// % protected region % [Override navigation link for ArtistEntity here] end
	},
	{
		// % protected region % [Override navigation link for AuditArchiveEntity here] off begin
		path: '/admin/auditarchiveentity',
		label: getModelDisplayName(Models.AuditArchiveEntity),
		entity: Models.AuditArchiveEntity,
		isMember: false,
		// % protected region % [Override navigation link for AuditArchiveEntity here] end
	},
	{
		// % protected region % [Override navigation link for AuditEntity here] off begin
		path: '/admin/auditentity',
		label: getModelDisplayName(Models.AuditEntity),
		entity: Models.AuditEntity,
		isMember: false,
		// % protected region % [Override navigation link for AuditEntity here] end
	},
	{
		// % protected region % [Override navigation link for BankEntity here] off begin
		path: '/admin/bankentity',
		label: getModelDisplayName(Models.BankEntity),
		entity: Models.BankEntity,
		isMember: false,
		// % protected region % [Override navigation link for BankEntity here] end
	},
	{
		// % protected region % [Override navigation link for ClientEntity here] off begin
		path: '/admin/cliententity',
		label: getModelDisplayName(Models.ClientEntity),
		entity: Models.ClientEntity,
		isMember: false,
		// % protected region % [Override navigation link for ClientEntity here] end
	},
	{
		// % protected region % [Override navigation link for ContactEntity here] off begin
		path: '/admin/contactentity',
		label: getModelDisplayName(Models.ContactEntity),
		entity: Models.ContactEntity,
		isMember: false,
		// % protected region % [Override navigation link for ContactEntity here] end
	},
	{
		// % protected region % [Override navigation link for ControlAuditEntity here] off begin
		path: '/admin/controlauditentity',
		label: getModelDisplayName(Models.ControlAuditEntity),
		entity: Models.ControlAuditEntity,
		isMember: false,
		// % protected region % [Override navigation link for ControlAuditEntity here] end
	},
	{
		// % protected region % [Override navigation link for CountryEntity here] off begin
		path: '/admin/countryentity',
		label: getModelDisplayName(Models.CountryEntity),
		entity: Models.CountryEntity,
		isMember: false,
		// % protected region % [Override navigation link for CountryEntity here] end
	},
	{
		// % protected region % [Override navigation link for DefaultInjectableFrequencyEntity here] off begin
		path: '/admin/defaultinjectablefrequencyentity',
		label: getModelDisplayName(Models.DefaultInjectableFrequencyEntity),
		entity: Models.DefaultInjectableFrequencyEntity,
		isMember: false,
		// % protected region % [Override navigation link for DefaultInjectableFrequencyEntity here] end
	},
	{
		// % protected region % [Override navigation link for FeedbackEntity here] off begin
		path: '/admin/feedbackentity',
		label: getModelDisplayName(Models.FeedbackEntity),
		entity: Models.FeedbackEntity,
		isMember: false,
		// % protected region % [Override navigation link for FeedbackEntity here] end
	},
	{
		// % protected region % [Override navigation link for GenreEntity here] off begin
		path: '/admin/genreentity',
		label: getModelDisplayName(Models.GenreEntity),
		entity: Models.GenreEntity,
		isMember: false,
		// % protected region % [Override navigation link for GenreEntity here] end
	},
	{
		// % protected region % [Override navigation link for GlobalConfigurationEntity here] off begin
		path: '/admin/globalconfigurationentity',
		label: getModelDisplayName(Models.GlobalConfigurationEntity),
		entity: Models.GlobalConfigurationEntity,
		isMember: false,
		// % protected region % [Override navigation link for GlobalConfigurationEntity here] end
	},
	{
		// % protected region % [Override navigation link for InjectableFrequencyEntity here] off begin
		path: '/admin/injectablefrequencyentity',
		label: getModelDisplayName(Models.InjectableFrequencyEntity),
		entity: Models.InjectableFrequencyEntity,
		isMember: false,
		// % protected region % [Override navigation link for InjectableFrequencyEntity here] end
	},
	{
		// % protected region % [Override navigation link for MoodEntity here] off begin
		path: '/admin/moodentity',
		label: getModelDisplayName(Models.MoodEntity),
		entity: Models.MoodEntity,
		isMember: false,
		// % protected region % [Override navigation link for MoodEntity here] end
	},
	{
		// % protected region % [Override navigation link for MoodTrackEntity here] off begin
		path: '/admin/moodtrackentity',
		label: getModelDisplayName(Models.MoodTrackEntity),
		entity: Models.MoodTrackEntity,
		isMember: false,
		// % protected region % [Override navigation link for MoodTrackEntity here] end
	},
	{
		// % protected region % [Override navigation link for MoodVersionEntity here] off begin
		path: '/admin/moodversionentity',
		label: getModelDisplayName(Models.MoodVersionEntity),
		entity: Models.MoodVersionEntity,
		isMember: false,
		// % protected region % [Override navigation link for MoodVersionEntity here] end
	},
	{
		// % protected region % [Override navigation link for MustardAdminEntity here] off begin
		path: '/admin/mustardadminentity',
		label: getModelDisplayName(Models.MustardAdminEntity),
		entity: Models.MustardAdminEntity,
		isMember: true,
		// % protected region % [Override navigation link for MustardAdminEntity here] end
	},
	{
		// % protected region % [Override navigation link for MustardManagementEntity here] off begin
		path: '/admin/mustardmanagemententity',
		label: getModelDisplayName(Models.MustardManagementEntity),
		entity: Models.MustardManagementEntity,
		isMember: true,
		// % protected region % [Override navigation link for MustardManagementEntity here] end
	},
	{
		// % protected region % [Override navigation link for PlaylistEntity here] off begin
		path: '/admin/playlistentity',
		label: getModelDisplayName(Models.PlaylistEntity),
		entity: Models.PlaylistEntity,
		isMember: false,
		// % protected region % [Override navigation link for PlaylistEntity here] end
	},
	{
		// % protected region % [Override navigation link for PlaylistTrackEntity here] off begin
		path: '/admin/playlisttrackentity',
		label: getModelDisplayName(Models.PlaylistTrackEntity),
		entity: Models.PlaylistTrackEntity,
		isMember: false,
		// % protected region % [Override navigation link for PlaylistTrackEntity here] end
	},
	{
		// % protected region % [Override navigation link for ProfileEntity here] off begin
		path: '/admin/profileentity',
		label: getModelDisplayName(Models.ProfileEntity),
		entity: Models.ProfileEntity,
		isMember: false,
		// % protected region % [Override navigation link for ProfileEntity here] end
	},
	{
		// % protected region % [Override navigation link for RegionEntity here] off begin
		path: '/admin/regionentity',
		label: getModelDisplayName(Models.RegionEntity),
		entity: Models.RegionEntity,
		isMember: false,
		// % protected region % [Override navigation link for RegionEntity here] end
	},
	{
		// % protected region % [Override navigation link for ScheduleEntity here] off begin
		path: '/admin/scheduleentity',
		label: getModelDisplayName(Models.ScheduleEntity),
		entity: Models.ScheduleEntity,
		isMember: false,
		// % protected region % [Override navigation link for ScheduleEntity here] end
	},
	{
		// % protected region % [Override navigation link for TagEntity here] off begin
		path: '/admin/tagentity',
		label: getModelDisplayName(Models.TagEntity),
		entity: Models.TagEntity,
		isMember: false,
		// % protected region % [Override navigation link for TagEntity here] end
	},
	{
		// % protected region % [Override navigation link for TagTypeEntity here] off begin
		path: '/admin/tagtypeentity',
		label: getModelDisplayName(Models.TagTypeEntity),
		entity: Models.TagTypeEntity,
		isMember: false,
		// % protected region % [Override navigation link for TagTypeEntity here] end
	},
	{
		// % protected region % [Override navigation link for TimeslotEntity here] off begin
		path: '/admin/timeslotentity',
		label: getModelDisplayName(Models.TimeslotEntity),
		entity: Models.TimeslotEntity,
		isMember: false,
		// % protected region % [Override navigation link for TimeslotEntity here] end
	},
	{
		// % protected region % [Override navigation link for TrackEntity here] off begin
		path: '/admin/trackentity',
		label: getModelDisplayName(Models.TrackEntity),
		entity: Models.TrackEntity,
		isMember: false,
		// % protected region % [Override navigation link for TrackEntity here] end
	},
	{
		// % protected region % [Override navigation link for VenueEntity here] off begin
		path: '/admin/venueentity',
		label: getModelDisplayName(Models.VenueEntity),
		entity: Models.VenueEntity,
		isMember: false,
		// % protected region % [Override navigation link for VenueEntity here] end
	},
	{
		// % protected region % [Override navigation link for VenueTypeEntity here] off begin
		path: '/admin/venuetypeentity',
		label: getModelDisplayName(Models.VenueTypeEntity),
		entity: Models.VenueTypeEntity,
		isMember: false,
		// % protected region % [Override navigation link for VenueTypeEntity here] end
	},
	{
		// % protected region % [Override navigation link for VolumeTimeslotEntity here] off begin
		path: '/admin/volumetimeslotentity',
		label: getModelDisplayName(Models.VolumeTimeslotEntity),
		entity: Models.VolumeTimeslotEntity,
		isMember: false,
		// % protected region % [Override navigation link for VolumeTimeslotEntity here] end
	},
	{
		// % protected region % [Override navigation link for ZoneEntity here] off begin
		path: '/admin/zoneentity',
		label: getModelDisplayName(Models.ZoneEntity),
		entity: Models.ZoneEntity,
		isMember: false,
		// % protected region % [Override navigation link for ZoneEntity here] end
	},
	// % protected region % [Add any extra page links here] off begin
	// % protected region % [Add any extra page links here] end
];

export default class PageLinks extends React.Component<RouteComponentProps> {
	private filter = (link: AdminLink) => {
		return SecurityService.canRead(link.entity);
	}

	public render() {
		return (
			<Navigation
				className="nav__admin"
				orientation={Orientation.VERTICAL}
				linkGroups={this.getAdminNavLinks()}
				{...this.props}
			/>
		);
	}

	private getAdminNavLinks = () : ILink[][] => {
		// % protected region % [Add custom logic before all here] off begin
		// % protected region % [Add custom logic before all here] end

		const links = getPageLinks();
		const userLinks = links.filter(link => link.isMember).filter(this.filter);
		const entityLinks = links.filter(link => !link.isMember).filter(this.filter);
		const linkGroups: ILink[][] = [];

		// % protected region % [Add any custom logic here before groups are made] off begin
		// % protected region % [Add any custom logic here before groups are made] end

		const homeLinkGroup: ILink[] = [
			{
				path: '/admin',
				label: 'Home',
				icon: 'home',
				iconPos: 'icon-left',
			},
			// % protected region % [Updated your home link group here] off begin
			// % protected region % [Updated your home link group here] end
		];
		linkGroups.push(homeLinkGroup);

		const entityLinkGroup: ILink[] = [];
		if (userLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/users',
					label: 'Users',
					icon: 'person-group',
					iconPos: 'icon-left',
					subLinks: [
						{ path: '/admin/user', label: 'All Users' },
						...userLinks.map(link => ({ path: link.path, label: link.label })),
					],
				},
			);
		}
		if (entityLinks.length > 0) {
			entityLinkGroup.push(
				{
					path: '/admin/entities',
					label: 'Entities',
					icon: 'list',
					iconPos: 'icon-left',
					subLinks: entityLinks.map(link => {
						return {
							path: link.path,
							label: link.label,
						};
					}),
				},
			);
		}
		linkGroups.push(entityLinkGroup);

		// % protected region % [Add any new link groups here before other and bottom] off begin
		// % protected region % [Add any new link groups here before other and bottom] end

		const otherlinkGroup: ILink[] = [];

		// % protected region % [Add any additional links to otherLinkGroup here] off begin
		// % protected region % [Add any additional links to otherLinkGroup here] end

		if (otherlinkGroup.length > 0) {
			linkGroups.push(otherlinkGroup);
		}

		const bottomlinkGroup: ILink[] = [];
		bottomlinkGroup.push(
			// % protected region % [Modify the utility link group here] off begin
			{
				path: '/admin/',
				label: 'Utility',
				icon: 'file',
				iconPos: 'icon-left',
				subLinks: [
					{
						path: '/admin/styleguide',
						label: 'Style Guide',
						useATag: false,
					},
					{
						path: '/admin/graphiql',
						label: 'GraphiQL',
						useATag: true,
					},
					{
						path: '/api/hangfire',
						label: 'Hangfire',
						useATag: true,
					},
					{
						path: '/api/swagger',
						label: 'OpenAPI',
						useATag: true,
					},
					{
						path: 'https://gitlab.codebots.dev',
						label: 'Git',
						useATag: true,
					},
				],
			},
			// % protected region % [Modify the utility link group here] end
		);

		// % protected region % [Update the logout link here] off begin
		bottomlinkGroup.push(
			{
				path: '/logout',
				label: 'Logout',
				icon: 'room',
				iconPos: 'icon-left',
			},
		);
		// % protected region % [Update the logout link here] end

		// % protected region % [Add any additional links to bottomlinkGroup here] off begin
		// % protected region % [Add any additional links to bottomlinkGroup here] end

		linkGroups.push(bottomlinkGroup);

		// % protected region % [Modify your link groups here before returning] off begin
		// % protected region % [Modify your link groups here before returning] end

		return linkGroups;
	}

	// % protected region % [Add custom methods here] off begin
	// % protected region % [Add custom methods here] end
}
