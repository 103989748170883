import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { runInAction } from 'mobx';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import { getFetchSingleQuery } from '../../../Util/EntityUtils';
import {
	ClientEntity,
	CountryEntity,
	VenueEntity,
	VenueTypeEntity,
} from '../../../Models/Entities';
import EditClientTile from './EditClientTile';
import LoadingContainer from 'Views/Components/LoadingContainer/LoadingContainer';

interface EditClientPageProps extends RouteComponentProps {
	match: {
		params: {
			id?: string;
		};
		isExact: boolean;
		path: string;
		url: string;
	};
}

const EditClientPage = (props: EditClientPageProps): JSX.Element => {
	const clientStore = useLocalStore(() => ({
		client: new ClientEntity(),
		countries: [] as CountryEntity[],
		venueTypes: [] as VenueTypeEntity[],
		loading: true,
	}));

	const orderBy = [
		{ path: 'name', descending: false },
	];

	useEffect(() => {
		const { match: { params: { id } } } = props;

		const fetchData = async () => {
			await store.apolloClient.query({
				query: getFetchSingleQuery(ClientEntity, new ClientEntity().clientPageExpands, false),
				fetchPolicy: 'network-only',
				variables: {
					args: [{
						path: 'id',
						comparison: 'equal',
						value: id,
					}],
					orderBy,
				},
			}).then((res: any) => {
				runInAction(() => {
					clientStore.client = new ClientEntity(res.data.clientEntity);
					clientStore.client.venuess = clientStore.client.venuess.sort((va: VenueEntity, vb: VenueEntity) => (va.name.toLowerCase() > vb.name.toLowerCase() ? 1 : -1));
				});
			}).catch(err => console.log('could not fetch client', err));

			runInAction(() => { clientStore.loading = false; });
		};

		fetchData();
	}, []);

	const refetchClient = async () => {
		const { match: { params: { id } } } = props;

		await store.apolloClient.query({
			query: getFetchSingleQuery(ClientEntity, new ClientEntity().clientPageExpands, false),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: id,
				}],
				orderBy,
			},
		}).then((res: any) => {
			runInAction(() => {
				clientStore.client = new ClientEntity(res.data.clientEntity);
				clientStore.client.venuess = clientStore.client.venuess.sort((va: VenueEntity, vb: VenueEntity) => (va.name.toLowerCase() > vb.name.toLowerCase() ? 1 : -1));
			});
		}).catch(err => console.log('could not fetch client', err));
	};

	if (clientStore.loading) {
		return <LoadingContainer />;
	}

	return (
		<div className="client-wrapper">
			<Link to="/clients">
				<Button colors={Colors.Secondary} display={Display.Text} sizes={Sizes.Medium} buttonProps={{ id: 'return' }} icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}>
					Back
				</Button>
			</Link>
			<EditClientTile
				client={clientStore.client}
				refetchClient={refetchClient}
			/>
		</div>
	);
};

export default observer(EditClientPage);
