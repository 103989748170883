/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import VisitorsPlaylistsReferenceManyToMany from '../Security/Acl/VisitorsPlaylistsReferenceManyToMany';
import AgencyPlaylisterPlaylistsReferenceManyToMany from '../Security/Acl/AgencyPlaylisterPlaylistsReferenceManyToMany';
import AgencyAdminPlaylistsReferenceManyToMany from '../Security/Acl/AgencyAdminPlaylistsReferenceManyToMany';
import ApplicationUserPlaylistsReferenceManyToMany from '../Security/Acl/ApplicationUserPlaylistsReferenceManyToMany';
import MustardAdminPlaylistsReferenceManyToMany from '../Security/Acl/MustardAdminPlaylistsReferenceManyToMany';
import MustardManagementPlaylistsReferenceManyToMany from '../Security/Acl/MustardManagementPlaylistsReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMoodVersionsPlaylistsAttributes extends IModelAttributes {
	moodVersionsId: string;
	playlistsId: string;

	moodVersions: Models.MoodVersionEntity | Models.IMoodVersionEntityAttributes;
	playlists: Models.PlaylistEntity | Models.IPlaylistEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('MoodVersionsPlaylists')
export default class MoodVersionsPlaylists
	extends Model
	implements IMoodVersionsPlaylistsAttributes {
	public static acls: IAcl[] = [
		new VisitorsPlaylistsReferenceManyToMany(),
		new AgencyPlaylisterPlaylistsReferenceManyToMany(),
		new AgencyAdminPlaylistsReferenceManyToMany(),
		new ApplicationUserPlaylistsReferenceManyToMany(),
		new MustardAdminPlaylistsReferenceManyToMany(),
		new MustardManagementPlaylistsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public moodVersionsId: string;

	@observable
	@attribute()
	public playlistsId: string;

	@observable
	@attribute({ isReference: true })
	public moodVersions: Models.MoodVersionEntity;

	@observable
	@attribute({ isReference: true })
	public playlists: Models.PlaylistEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IMoodVersionsPlaylistsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.moodVersionsId) {
				this.moodVersionsId = attributes.moodVersionsId;
			}
			if (attributes.playlistsId) {
				this.playlistsId = attributes.playlistsId;
			}

			if (attributes.moodVersions) {
				if (attributes.moodVersions instanceof Models.MoodVersionEntity) {
					this.moodVersions = attributes.moodVersions;
					this.moodVersionsId = attributes.moodVersions.id;
				} else {
					this.moodVersions = new Models.MoodVersionEntity(attributes.moodVersions);
					this.moodVersionsId = this.moodVersions.id;
				}
			} else if (attributes.moodVersionsId !== undefined) {
				this.moodVersionsId = attributes.moodVersionsId;
			}

			if (attributes.playlists) {
				if (attributes.playlists instanceof Models.PlaylistEntity) {
					this.playlists = attributes.playlists;
					this.playlistsId = attributes.playlists.id;
				} else {
					this.playlists = new Models.PlaylistEntity(attributes.playlists);
					this.playlistsId = this.playlists.id;
				}
			} else if (attributes.playlistsId !== undefined) {
				this.playlistsId = attributes.playlistsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
