import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import { observer, useLocalStore } from 'mobx-react';
import axios from 'axios';
import moment from 'moment';
import { runInAction } from 'mobx';
import CustomSpinner from '../../Components/CustomSpinner/CustomSpinner';
import { contextMenu } from 'react-contexify';
import {
	IProfileEntityAttributes,
	ProfileEntity,
} from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import { getFetchSingleQuery } from '../../../Util/EntityUtils';
import Navigation, {
	Orientation,
} from '../../Components/Navigation/Navigation';
import SecuredPage from '../../Components/Security/SecuredPage';
import { getFrontendNavLinks } from '../../FrontendNavLinks';
import {
	Button, Colors, Display, Sizes,
} from '../../Components/Button/Button';
import alert from '../../../Util/ToastifyUtils';
import alertToast from '../../../Util/ToastifyUtils';
import Tabs from '../../Components/Tabs/Tabs';
import MoodTab from './ProfileTabs/MoodTab';
import ScheduleTab from './ProfileTabs/ScheduleTab';
import AssignTab from './ProfileTabs/AssignTab';
import { SERVER_URL } from '../../../Constants';
import PublishModal from './Sync/PublishModal/PublishModal';
import { ContextMenu } from '../../Components/ContextMenu/ContextMenu';
import { confirmModal } from '../../Components/Modal/ModalUtils';
import { ProfileNameInputModal } from './Profiles/ProfileNameInputModal';
import VolumeTab from './ProfileTabs/VolumeTab/VolumeTab';
import { renderScheduleDateTime } from '../../../Util/ScheduleUtils';

export interface EditProfilePageProps extends RouteComponentProps {
	match: {
		params: {
			profileId?: string;
			scheduleId?: string;
		};
		isExact: boolean;
		path: string;
		url: string;
	};
}

export const EditProfilePage = observer(
	(props: EditProfilePageProps): JSX.Element => {
		const {
			match,
			match: {
				params: { profileId, scheduleId },
			},
			staticContext,
			location,
			history,
		} = props;

		const navigationLinks = getFrontendNavLinks(props);
		const scheduledDateTimeStore: { datetime: string | null } = useLocalStore(
			() => ({
				datetime: null,
			}),
		);

		const [canPublish, setCanPublish] = useState(false);

		const checkPublishPermission = () => {
			store.getCanPublish().then(data => setCanPublish(data));
		};

		const fetchScheduledDateTime = async () => {
			if (profileId) {
				await axios
					.get(
						`${SERVER_URL}/api/sync/GetScheduledDateTime?profileId=${profileId}`,
					)
					.then(({ data }) => {
						runInAction(() => {
              // If data not defined, set to null. Otherwise, use returned date
							scheduledDateTimeStore.datetime = data || null;
						});
					})
					.catch(() => {
						runInAction(() => {
							scheduledDateTimeStore.datetime = null;
						});
					})
					.finally(() => checkPublishPermission());
			}
		};

		const {
			isLoading: isProfileDataLoading,
			data: profileData,
		} = useQuery(['profile', profileId], async (): Promise<ProfileEntity> => {
			try {
				const { data } = await store.apolloClient.query<{
          profileEntity: IProfileEntityAttributes;
        }>({
        	query: getFetchSingleQuery(
        		ProfileEntity,
        		new ProfileEntity().profileExpands,
        		true,
        	),
        	fetchPolicy: 'network-only',
        	variables: {
        		args: [
        			{
        				path: 'id',
        				comparison: 'equal',
        				value: profileId,
        			},
        		],
        	},
        });
				return new ProfileEntity(data.profileEntity);
			} catch (err) {
				store.routerHistory.push('/profile-management');
				alertToast('Profile could not be found', 'error');
				return {} as ProfileEntity;
			}
		});

    // TODO: WRONG USED OF USE QUERY
		useQuery('PublishPermission', checkPublishPermission);
		useQuery('ScheduledDateTime', fetchScheduledDateTime);

    // Fetching profile summary
		const {
			isLoading: isProfileStatusLoading,
			isError: isProfileStatsError,
			data: profileStats,
		} = useQuery(['profile_summary', profileId], async () => {
			const { data } = await axios.get(
				`${SERVER_URL}/api/entity/ProfileEntity/GetProfileSummary/${profileId}`,
			);

			return data;
		});

    // Edit profile name
		const openEditProfileNameModal = async (): Promise<void> => {
			if (!profileData) {
				alertToast('Profile could not be located', 'error');
				return;
			}

			try {
				const newProfileName = await ProfileNameInputModal(
					{
						isEditMode: true,
						profile: profileData,
					},
				);

				runInAction(() => {
					profileData.name = newProfileName;
				});
				await profileData?.save();

				alert('Profile name updated', 'success');
			} catch (err) {
				alertToast(`An unexpected error occurred: ${err}`, 'error');
			}
		};

    // Duplicate profile
		const openDuplicateProfileNameModal = async (): Promise<void> => {
			if (!profileData) {
				alertToast('Profile could not be located', 'error');
				return;
			}

			try {
				const newProfileName = await ProfileNameInputModal({
					isEditMode: false,
					profile: profileData,
				});
				const { data } = await axios.post(
					`${SERVER_URL}/api/entity/ProfileEntity/DuplicateProfile/${profileId}`,
					{ profileName: newProfileName },
				);

				store.routerHistory.push(`/profile-management/edit/${data}`);
				alertToast('Successfully duplicated profile', 'success');
			} catch (err) {
				alertToast(`An unexpected error occurred: ${err}`, 'error');
			}
		};

    // Delete Profile
		const openDeleteProfileModal = async (): Promise<void> => {
			if (store.userGroups.some(ug => ug.name === 'AgencyPlaylister')) {
				alertToast('You do not have permission to delete profiles', 'error');
				return;
			}
      // TODO: replacement, check before deleting the below part
			try {
				await confirmModal('Delete Profile', 'Are you sure you want to delete this profile?');
				await axios.delete(`${SERVER_URL}/api/entity/MoodEntity/byProfile/${profileData?.id}`);
				await profileData?.delete();
				store.routerHistory.push('/profile-management');
				alertToast('Profile deleted successfully', 'success');
			} catch (err) {
				alertToast(`Profile could not be deleted: ${err}`, 'error');
			}
      // confirmModal(
      //   "Delete Profile",
      //   "Are you sure you want to delete this profile?"
      // ).then(() =>
      //   axios
      //     .delete(`/api/entity/MoodEntity/byProfile/${profileData?.id}`)
      //     .then(() => {
      //       // Hacky clear cache
      //       profileData
      //         ?.delete()
      //         .then(() => {
      //           store.routerHistory.push("/profile-management");
      //           alertToast("Profile deleted successfully", "success");
      //         })
      //         .catch(() => {
      //           alertToast(
      //             "Profile could not be deleted. Please try again or contact support!",
      //             "error"
      //           );
      //         });
      //     })
      //     .catch((err) => {
      //       alertToast(`Profile could not be deleted: ${err}`, "error");
      //     })
      // );
		};

		if (isProfileStatsError) {
			<CustomSpinner />;
		}

		return (
			<SecuredPage>
				<Navigation
					linkGroups={navigationLinks}
					orientation={Orientation.VERTICAL}
					match={match}
					location={location}
					history={history}
					staticContext={staticContext}
				/>
				<div
					className={classNames(
						'body-content',
						'logged-in',
						store.hasBackendAccess && 'body-admin',
					)}
				>
					<div className="edit-profile-wrapper">
						<Button
							colors={Colors.Secondary}
							display={Display.Text}
							sizes={Sizes.Small}
							buttonProps={{ id: 'return' }}
							icon={{
								icon: 'chevron-left',
								iconPos: 'icon-left',
							}}
							onClick={history.goBack}
						>
							Back
						</Button>
						<div className="profile-information">
							<h1>Edit Profile</h1>

							{isProfileDataLoading && isProfileStatusLoading
								? <CustomSpinner />
								: (
									<>
										<h3>{profileData?.name}</h3>

										<Button
											className="profile-contextmenu-button"
											display={Display.Solid}
											sizes={Sizes.Small}
											icon={{ icon: 'more-vertical', iconPos: 'icon-top' }}
											onClick={e => contextMenu.show({ id: 'profile-options', event: e })}
										/>
										<ContextMenu
											menuId="profile-options"
											actions={[
												{
													label: 'Edit Profile Name',
													onClick: (): Promise<void> => openEditProfileNameModal(),
												},
												{
													label: 'Delete Profile',
													onClick: (): Promise<void> => openDeleteProfileModal(),
												},
												{
													label: 'Duplicate Profile',
													onClick: (): Promise<void> => openDuplicateProfileNameModal(),
												},
											]}
										/>

										<span className="profile-stats">
											Total no. of tracks:
											<span className="stats">{profileStats}</span>
										</span>
									</>
								)}

							{canPublish && (
								<Button
									className="publish-button"
									display={Display.Solid}
									sizes={Sizes.Small}
									colors={Colors.Primary}
									buttonProps={{
										onClick: (): void => {
											store.modal.show(
												'Publish',
												<PublishModal
													profileId={profileId ?? ''}
													fetchScheduledDateTime={fetchScheduledDateTime}
													canPublish={canPublish}
												/>,
												{ className: 'slideout-panel-right' },
											);
										},
									}}
								>
									Publish
								</Button>
							)}
						</div>

						{canPublish && (
							<div className="publish-status-wrapper">
								<p className="publish-status">
									{renderScheduleDateTime(scheduledDateTimeStore.datetime)}
								</p>
							</div>
						)}

						{profileData && (
							<Tabs
								className="edit-profile-tabs"
								isDisabled={store.isEditingSchedule || store.isEditingVolumeSchedule}
								tabs={[
									{
										component: isProfileDataLoading ? (
											<div className="mood-tab-container">
												<div className="mood-tiles-container">
													<CustomSpinner />
												</div>
											</div>
										) : (
											<MoodTab profile={profileData} />
										),
										name: 'Moods',
										className: 'moods-tab',
										key: 'moods-tab',
									},
									{
										component: (
											<ScheduleTab profile={profileData} scheduleId={scheduleId} />
										),
										name: 'Schedule',
										className: 'schedule-tab',
										key: 'schedule-tab',
									},
									{
										component: (
											<VolumeTab profile={profileData} scheduleId={scheduleId} />
										),
										name: 'Volume',
										className: 'volume-tab',
										key: 'volume-tab',
									},
									{
										component: <AssignTab profile={profileData} />,
										name: 'Assign',
										className: 'assign-tab',
										key: 'assign-tab',
									},
								]}
								defaultTab={scheduleId !== undefined ? 1 : 0}
							/>
						)}
					</div>
				</div>
			</SecuredPage>
		);
	},
);
