/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterMoodEntity from 'Models/Security/Acl/AgencyPlaylisterMoodEntity';
import ApplicationUserMoodEntity from 'Models/Security/Acl/ApplicationUserMoodEntity';
import AgencyAdminMoodEntity from 'Models/Security/Acl/AgencyAdminMoodEntity';
import MustardAdminMoodEntity from 'Models/Security/Acl/MustardAdminMoodEntity';
import MustardManagementMoodEntity from 'Models/Security/Acl/MustardManagementMoodEntity';
import VisitorsMoodEntity from 'Models/Security/Acl/VisitorsMoodEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { IInjectableFrequencyEntityAttributes, InjectableFrequencyEntity } from 'Models/Entities';
import { store } from 'Models/Store';
import { getFetchAllConditional  } from 'Util/EntityUtils';
// % protected region % [Add any further imports here] end

export interface IMoodEntityAttributes extends IModelAttributes {
	name: string;
	colour: string;
	publishedVersion: string;
	draftVersion: string;
	allowExplicits: boolean;
	agencyOwnerId: string;
	archived: boolean;
	injectable: boolean;

	controlAuditss: Array<
		| Models.ControlAuditEntity
		| Models.IControlAuditEntityAttributes
	>;
	injectableFrequenciess: Array<
		| Models.InjectableFrequencyEntity
		| Models.IInjectableFrequencyEntityAttributes
	>;
	moodVersionss: Array<
		| Models.MoodVersionEntity
		| Models.IMoodVersionEntityAttributes
	>;
	profileId?: string;
	profile?: Models.ProfileEntity | Models.IProfileEntityAttributes;
	timeslotss: Array<
		| Models.TimeslotEntity
		| Models.ITimeslotEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] on begin
	draftMoodVersion: Models.MoodVersionEntity | Models.IMoodVersionEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MoodEntity', 'Mood')
// % protected region % [Customise your entity metadata here] end
export default class MoodEntity extends Model
	implements IMoodEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterMoodEntity(),
		new ApplicationUserMoodEntity(),
		new AgencyAdminMoodEntity(),
		new MustardAdminMoodEntity(),
		new MustardManagementMoodEntity(),
		new VisitorsMoodEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<MoodEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Colour'] off begin
	@observable
	@attribute<MoodEntity, string>()
	@CRUD({
		name: 'Colour',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public colour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Published Version'] on begin
	@Validators.Uuid()
	@observable
	@attribute<MoodEntity, string>()
	@CRUD({
		name: 'Published Version',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public publishedVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Published Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Draft Version'] on begin
	@Validators.Uuid()
	@observable
	@attribute<MoodEntity, string>()
	@CRUD({
		name: 'Draft Version',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: false,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public draftVersion: string;
	// % protected region % [Modify props to the crud options here for attribute 'Draft Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Allow Explicits'] off begin
	@observable
	@attribute<MoodEntity, boolean>()
	@CRUD({
		name: 'Allow Explicits',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public allowExplicits: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Allow Explicits'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<MoodEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'textfield',
		order: 60,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	// % protected region % [Modify props to the crud options here for attribute 'Archived'] off begin
	@observable
	@attribute<MoodEntity, boolean>()
	@CRUD({
		name: 'Archived',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public archived: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Archived'] end

	// % protected region % [Modify props to the crud options here for attribute 'Injectable'] off begin
	/**
	 * Defines if a Mood will have Injectable Frequencies
	 */
	@Validators.Required()
	@observable
	@attribute<MoodEntity, boolean>()
	@CRUD({
		name: 'Injectable',
		displayType: 'checkbox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public injectable: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Injectable'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Control Audits'] off begin
		name: 'Control Auditss',
		displayType: 'reference-multicombobox',
		order: 90,
		referenceTypeFunc: () => Models.ControlAuditEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'controlAuditss',
			oppositeEntity: () => Models.ControlAuditEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Control Audits'] end
	})
	public controlAuditss: Models.ControlAuditEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Injectable Frequencies'] off begin
		name: 'Injectable Frequenciess',
		displayType: 'reference-multicombobox',
		order: 100,
		referenceTypeFunc: () => Models.InjectableFrequencyEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'injectableFrequenciess',
			oppositeEntity: () => Models.InjectableFrequencyEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Injectable Frequencies'] end
	})
	public injectableFrequenciess: Models.InjectableFrequencyEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Mood Versions'] off begin
		name: 'Mood Versionss',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.MoodVersionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'moodVersionss',
			oppositeEntity: () => Models.MoodVersionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Mood Versions'] end
	})
	public moodVersionss: Models.MoodVersionEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Profile'] off begin
		name: 'Profile',
		displayType: 'reference-combobox',
		order: 120,
		referenceTypeFunc: () => Models.ProfileEntity,
		// % protected region % [Modify props to the crud options here for reference 'Profile'] end
	})
	public profileId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public profile: Models.ProfileEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Timeslots'] off begin
		name: 'Timeslotss',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.TimeslotEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'timeslotss',
			oppositeEntity: () => Models.TimeslotEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Timeslots'] end
	})
	public timeslotss: Models.TimeslotEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] on begin
	@observable
	@attribute({isReference: true})
	public draftMoodVersion: Models.MoodVersionEntity;
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMoodEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMoodEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.colour !== undefined) {
				this.colour = attributes.colour;
			}
			if (attributes.publishedVersion !== undefined) {
				this.publishedVersion = attributes.publishedVersion;
			}
			if (attributes.draftVersion !== undefined) {
				this.draftVersion = attributes.draftVersion;
			}
			if (attributes.allowExplicits !== undefined) {
				this.allowExplicits = attributes.allowExplicits;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.archived !== undefined) {
				this.archived = attributes.archived;
			}
			if (attributes.injectable !== undefined) {
				this.injectable = attributes.injectable;
			}
			if (attributes.controlAuditss !== undefined && Array.isArray(attributes.controlAuditss)) {
				for (const model of attributes.controlAuditss) {
					if (model instanceof Models.ControlAuditEntity) {
						this.controlAuditss.push(model);
					} else {
						this.controlAuditss.push(new Models.ControlAuditEntity(model));
					}
				}
			}
			if (attributes.injectableFrequenciess !== undefined && Array.isArray(attributes.injectableFrequenciess)) {
				for (const model of attributes.injectableFrequenciess) {
					if (model instanceof Models.InjectableFrequencyEntity) {
						this.injectableFrequenciess.push(model);
					} else {
						this.injectableFrequenciess.push(new Models.InjectableFrequencyEntity(model));
					}
				}
			}
			if (attributes.moodVersionss !== undefined && Array.isArray(attributes.moodVersionss)) {
				for (const model of attributes.moodVersionss) {
					if (model instanceof Models.MoodVersionEntity) {
						this.moodVersionss.push(model);
					} else {
						this.moodVersionss.push(new Models.MoodVersionEntity(model));
					}
				}
			}
			if (attributes.profileId !== undefined) {
				this.profileId = attributes.profileId;
			}
			if (attributes.profile !== undefined) {
				if (attributes.profile === null) {
					this.profile = attributes.profile;
				} else if (attributes.profile instanceof Models.ProfileEntity) {
					this.profile = attributes.profile;
					this.profileId = attributes.profile.id;
				} else {
					this.profile = new Models.ProfileEntity(attributes.profile);
					this.profileId = this.profile.id;
				}
			}
			if (attributes.timeslotss !== undefined && Array.isArray(attributes.timeslotss)) {
				for (const model of attributes.timeslotss) {
					if (model instanceof Models.TimeslotEntity) {
						this.timeslotss.push(model);
					} else {
						this.timeslotss.push(new Models.TimeslotEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] on begin
			if (attributes.draftMoodVersion !== undefined) {
				if (attributes.draftMoodVersion === null) {
					this.draftMoodVersion = attributes.draftMoodVersion;
				} else {
					if (attributes.draftMoodVersion instanceof Models.MoodVersionEntity) {
						this.draftMoodVersion = attributes.draftMoodVersion;
					} else {
						this.draftMoodVersion = new Models.MoodVersionEntity(attributes.draftMoodVersion);
					}
				}
			}
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		controlAuditss {
			${Models.ControlAuditEntity.getAttributes().join('\n')}
		}
		injectableFrequenciess {
			${Models.InjectableFrequencyEntity.getAttributes().join('\n')}
		}
		moodVersionss {
			${Models.MoodVersionEntity.getAttributes().join('\n')}
		}
		profile {
			${Models.ProfileEntity.getAttributes().join('\n')}
		}
		timeslotss {
			${Models.TimeslotEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			controlAuditss: {},
			injectableFrequenciess: {},
			moodVersionss: {},
			timeslotss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'controlAuditss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public fetchInjectableFrequencyEntitysQuery = async (): Promise<InjectableFrequencyEntity[]> => {
		const { data } = await store.apolloClient.query<{injectableFrequencyEntitys: IInjectableFrequencyEntityAttributes[]}>({
			query: getFetchAllConditional(InjectableFrequencyEntity),
			fetchPolicy: 'network-only',
			variables: { args: [[{ path: 'moodId', comparison: 'equal', value: this.id }]], orderBy: [{ path: 'start' }] },
		});

		return data.injectableFrequencyEntitys.map(x => new InjectableFrequencyEntity(x));
	};
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MoodEntity.prototype, 'created');
CRUD(modifiedAttr)(MoodEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
