import React, { ChangeEvent, KeyboardEvent } from "react";
import { store } from "Models/Store";
import { Button, Display } from "../../../Components/Button/Button";
import { TextField } from "../../../Components/TextBox/TextBox";
import { ProfileEntity } from "Models/Entities";
import alert from "../../../../Util/ToastifyUtils";

interface ProfileNameInputModalProps {
  isEditMode: boolean,
  profile: ProfileEntity
}

export const ProfileNameInputModal = (
  {isEditMode, profile}: ProfileNameInputModalProps
) => {
  return new Promise<string>((resolve, reject) => {
    const profileCopy = isEditMode ? { ...profile } : {...profile, name: ""};

    const onConfirm = () => {
      if(!profileCopy.name) {
        alert("Profile name cannot be empty", "error");
        return
      }

      store.modal.hide();
      resolve(profileCopy.name);
    };

    const onCancel = () => {
      store.modal.hide();
      reject();
    };

    const handleTextFieldChange = (
      event: ChangeEvent<HTMLInputElement>
    ) => {
      profileCopy.name = event.target.value;
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onConfirm();
      }
    };

    const confirmDom = (
      <>
        <div key="header" className="modal__header">
          <h2 key="header-item" className="modal__title">
            {isEditMode ? "Edit Profile Name" : "Duplicate Profile"}
          </h2>
        </div>

        { isEditMode ?
          <p className="modal-message">Please enter a new name for this profile.</p>
          : 
          <>
            <p className="modal-message">Duplicating a profile will copy over all the moods from the current profile.</p>
            <p className="modal-message">Please enter a name for the new profile.</p>
          </>
        }

        <TextField
          model={profileCopy}
          modelProperty="name"
          placeholder="Profile Name"
          onAfterChange={handleTextFieldChange}
          inputProps={{ onKeyDown: handleKeyPress }}
        />

        <div className="modal__actions">
          <Button
            className="modal--confirm"
            onClick={onConfirm}
            display={Display.Solid}
          >
            {isEditMode ? "Save" : "Create"}
          </Button>
          <Button
            className="modal--cancel"
            onClick={onCancel}
            display={Display.Outline}
          >
            Cancel
          </Button>
        </div>
      </>
    );

    store.modal.show("", confirmDom, {
      className: "confirm-modal",
      onRequestClose: () => {
        store.modal.hide();
        reject();
      },
    });
  });
}
