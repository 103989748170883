/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
// % protected region % [Add any extra imports here] off begin
// % protected region % [Add any extra imports here] end

export type auditType =
	// % protected region % [Override auditType keys here] off begin
	'TRACK_PLAYED' |
	'TRACK_PAUSED' |
	'TRACK_UNPAUSED' |
	'TRACK_SKIPPED' |
	'TRACK_LIKED' |
	'TRACK_DISLIKED' |
	'TRACK_DISABLED' |
	'TRACK_QUEUED' |
	'MOOD_STARTED' |
	'MOOD_OVERRIDDEN' |
	'MOOD_CANCELLED' |
	'CLIENT_APPLICATION_STARTED' |
	'CLIENT_UPDATE_PULL' |
	'CLIENT_PUSHED_UPDATE' |
	'SYNC_SUCCESS' |
	'SYNC_FAILED' |
	'TRACK_DOWNLOADED' |
	'TRACK_STREAMED';
	// % protected region % [Override auditType keys here] end

export const auditTypeOptions: { [key in auditType]: string } = {
	// % protected region % [Override auditType display fields here] off begin
	TRACK_PLAYED: 'TRACK PLAYED',
	TRACK_PAUSED: 'TRACK PAUSED',
	TRACK_UNPAUSED: 'TRACK UNPAUSED',
	TRACK_SKIPPED: 'TRACK SKIPPED',
	TRACK_LIKED: 'TRACK LIKED',
	TRACK_DISLIKED: 'TRACK DISLIKED',
	TRACK_DISABLED: 'TRACK DISABLED',
	TRACK_QUEUED: 'TRACK QUEUED',
	MOOD_STARTED: 'MOOD STARTED',
	MOOD_OVERRIDDEN: 'MOOD OVERRIDDEN',
	MOOD_CANCELLED: 'MOOD CANCELLED',
	CLIENT_APPLICATION_STARTED: 'CLIENT APPLICATION STARTED',
	CLIENT_UPDATE_PULL: 'CLIENT UPDATE PULL',
	CLIENT_PUSHED_UPDATE: 'CLIENT PUSHED UPDATE',
	SYNC_SUCCESS: 'SYNC SUCCESS',
	SYNC_FAILED: 'SYNC FAILED',
	TRACK_DOWNLOADED: 'TRACK DOWNLOADED',
	TRACK_STREAMED: 'TRACK STREAMED',
	// % protected region % [Override auditType display fields here] end
};

export type controlType =
	// % protected region % [Override controlType keys here] off begin
	'PLAY' |
	'PAUSE' |
	'SKIP' |
	'OVERRIDE_MOOD' |
	'OVERRIDE_CANCEL' |
	'VOLUME_UP' |
	'VOLUME_DOWN' |
	'QUEUE' |
	'DISABLE_TRACK';
	// % protected region % [Override controlType keys here] end

export const controlTypeOptions: { [key in controlType]: string } = {
	// % protected region % [Override controlType display fields here] off begin
	PLAY: 'Play',
	PAUSE: 'Pause',
	SKIP: 'Skip',
	OVERRIDE_MOOD: 'Override Mood',
	OVERRIDE_CANCEL: 'Override Cancel',
	VOLUME_UP: 'Volume up',
	VOLUME_DOWN: 'Volume down',
	QUEUE: 'Queue',
	DISABLE_TRACK: 'Disable Track',
	// % protected region % [Override controlType display fields here] end
};

export type downloadStatus =
	// % protected region % [Override downloadStatus keys here] off begin
	'AWAITING_DOWNLOAD' |
	'DOWNLOADING' |
	'DOWNLOADED' |
	'ERROR';
	// % protected region % [Override downloadStatus keys here] end

export const downloadStatusOptions: { [key in downloadStatus]: string } = {
	// % protected region % [Override downloadStatus display fields here] off begin
	AWAITING_DOWNLOAD: 'Awaiting Download',
	DOWNLOADING: 'Downloading',
	DOWNLOADED: 'Downloaded',
	ERROR: 'Error',
	// % protected region % [Override downloadStatus display fields here] end
};

export type eventType =
	// % protected region % [Override eventType keys here] off begin
	'TRACK_UPDATED' |
	'MOODTRACK_ADDED' |
	'MOODTRACK_REMOVED' |
	'MOOD_ADDED' |
	'MOOD_UPDATED' |
	'MOOD_REMOVED' |
	'SCHEDULE_ADDED' |
	'SCHEDULE_UPDATED' |
	'SCHEDULE_REMOVED' |
	'TIMESLOT_ADDED' |
	'TIMESLOT_UPDATED' |
	'TIMESLOT_REMOVED' |
	'ZONE_UPDATED';
	// % protected region % [Override eventType keys here] end

export const eventTypeOptions: { [key in eventType]: string } = {
	// % protected region % [Override eventType display fields here] off begin
	TRACK_UPDATED: 'Track updated',
	MOODTRACK_ADDED: 'Moodtrack added',
	MOODTRACK_REMOVED: 'Moodtrack removed',
	MOOD_ADDED: 'Mood added',
	MOOD_UPDATED: 'Mood updated',
	MOOD_REMOVED: 'Mood removed',
	SCHEDULE_ADDED: 'Schedule added',
	SCHEDULE_UPDATED: 'Schedule updated',
	SCHEDULE_REMOVED: 'Schedule removed',
	TIMESLOT_ADDED: 'Timeslot added',
	TIMESLOT_UPDATED: 'Timeslot updated',
	TIMESLOT_REMOVED: 'Timeslot removed',
	ZONE_UPDATED: 'Zone updated',
	// % protected region % [Override eventType display fields here] end
};

export type frequencyType =
	// % protected region % [Override frequencyType keys here] off begin
	'TRACK' |
	'MINUTE' |
	'HOUR';
	// % protected region % [Override frequencyType keys here] end

export const frequencyTypeOptions: { [key in frequencyType]: string } = {
	// % protected region % [Override frequencyType display fields here] off begin
	TRACK: 'Track',
	MINUTE: 'Minute',
	HOUR: 'Hour',
	// % protected region % [Override frequencyType display fields here] end
};

export type syncStatusType =
	// % protected region % [Override syncStatusType keys here] off begin
	'AWAITING_SYNC' |
	'SYNCING' |
	'COMPLETED' |
	'FAILED';
	// % protected region % [Override syncStatusType keys here] end

export const syncStatusTypeOptions: { [key in syncStatusType]: string } = {
	// % protected region % [Override syncStatusType display fields here] off begin
	AWAITING_SYNC: 'Awaiting sync',
	SYNCING: 'Syncing',
	COMPLETED: 'Completed',
	FAILED: 'Failed',
	// % protected region % [Override syncStatusType display fields here] end
};

export type timeslotDayOfWeek =
	// % protected region % [Override timeslotDayOfWeek keys here] off begin
	'MONDAY' |
	'TUESDAY' |
	'WEDNESDAY' |
	'THURSDAY' |
	'FRIDAY' |
	'SATURDAY' |
	'SUNDAY';
	// % protected region % [Override timeslotDayOfWeek keys here] end

export const timeslotDayOfWeekOptions: { [key in timeslotDayOfWeek]: string } = {
	// % protected region % [Override timeslotDayOfWeek display fields here] off begin
	MONDAY: 'Mon',
	TUESDAY: 'Tue',
	WEDNESDAY: 'Wed',
	THURSDAY: 'Thu',
	FRIDAY: 'Fri',
	SATURDAY: 'Sat',
	SUNDAY: 'Sun',
	// % protected region % [Override timeslotDayOfWeek display fields here] end
};

// % protected region % [Add any extra enums here] off begin
// % protected region % [Add any extra enums here] end
