﻿import { runInAction } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';
import { contextMenu } from 'react-contexify';
import AlbumArtPlaceholder from 'assets/empty-album-art.svg';
import PlayIcon from 'assets/play.svg';
import PauseIcon from 'assets/pause.svg';
import classNames from 'classnames';
import { SERVER_URL } from '../../../Constants';
import { store } from '../../../Models/Store';
import { CheckedTrackEntity, playlistRunTime } from '../../../Util/PlaylistUtils';
import { FeedbackEntity, TrackEntity } from '../../../Models/Entities';
import { ContextMenu, IContextMenuActions } from '../../Components/ContextMenu/ContextMenu';
import ArchiveTrackConfirmation from '../Uploads/ArchiveTrackConfirmation';
import alertToast from '../../../Util/ToastifyUtils';
import Feedback from '../ProfileManagement/Moods/Feedback';
import axios from 'axios';

interface ITrackTileProps {
	includeSelected?: boolean;
	isSelected?: boolean;
	track: CheckedTrackEntity | TrackEntity;
	contextMenu?: boolean;
	archiveTrack?: () => void;
	closePanel?: () => void;
	includeFeedback?: boolean;
	feedbacks?: FeedbackEntity[];
	hideTrackLengthAndBpm?: boolean;
	isLarge?: boolean;
	customClassNames?: string;
	showNotSorted?: boolean
}

const TrackTile = observer((props: ITrackTileProps) => {
	const {
		includeSelected = false,
		isSelected = false,
		track,
		showNotSorted = false,
		archiveTrack,
		closePanel,
		includeFeedback,
		feedbacks,
		customClassNames,
		hideTrackLengthAndBpm = false,
	} = props;

	// const includeTrackLengthAndBpm = props.includeTrackLengthAndBpm == undefined ? true : props.includeTrackLengthAndBpm;
	const isLarge = props.isLarge === undefined ? false : props.isLarge;

	const downloadCsv = async () => {
		try {
			if (props.track.id) {
				const response = await axios.get(`${SERVER_URL}/api/playlist_search/track-info/${props.track.id}`, {
					responseType: 'json',
				});

				const { data } = response;
				let csvContent = 'Venue,Zone,Profile,Moods\n';

				data.forEach((venue: { zones: any[]; venueName: any; }) => {
					venue.zones.forEach(zone => {
						csvContent += `${venue.venueName},${zone.zoneName},${zone.profileName},${zone.moodNames.join(' / ')}\n`;
					});
				});

				const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				const filename = `${props.track.title} Info.csv`;
				link.setAttribute('download', filename);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (err) {
			alertToast('Error fetching track info CSV. Please try again later or contact support!', 'error');
		}
	};
	// construct context menu
	const contextMenuActions = (): IContextMenuActions => {
		const actions: IContextMenuActions = [];

		if (archiveTrack) {
			actions.push({
				label: 'Archive',
				onClick: async () => {
					const canUpload = await store.getCanUpload();
					if (!canUpload) {
						alertToast('You do not have permission to delete a track', 'error');
						return;
					}
					store.modal.show(
						'Archive Track Confirmation',
						<ArchiveTrackConfirmation
							trackName={track.title}
							archiveTrack={archiveTrack}
						/>,
					);
				},
			});
		}
		if (closePanel) {
			actions.push({
				label: 'Close',
				onClick: closePanel,
			});
			{
				actions.push({
					label: 'Download Track Information',
					onClick: downloadCsv,
				});
			}
		}
		return actions;
	};

	return (
		<>
			{includeSelected && <div className={`included${isSelected ? ' true' : ''}`} />}
			<div className={classNames(`track-label${isLarge ? '-lrg' : ''}`, 'art', customClassNames)} style={includeFeedback ? { width: '100%', overflowX: 'hidden' } : {}}>
				<div className="track-art-imgs">
					<img
						src={!track.album?.artworkId ? AlbumArtPlaceholder : `${SERVER_URL}/api/files/${track.album.artworkId}`}
						alt="album-art"
						className="album-art"
					/>

					{(store.track.id === track.id) && !store.pause ? (
						<img
							src={PauseIcon}
							className="pause-button"
							onClick={(): void => {
								runInAction(() => {
									store.pause = true;
								});
							}}
						/>
					) : (
						<img
							src={PlayIcon}
							className="play-button"
							onClick={() => {
								if (store._track === track) {
									runInAction(() => { store.pause = false; });
								} else {
									runInAction(() => { store._track = track; });
								}
							}}
						/>
					)}

				</div>

				<div className="text">
					<p className={classNames('title', store.track.id === track.id && 'active')} unselectable="on" style={{ textOverflow: 'ellipsis' }}>{track.title}</p>
					<p unselectable="on">{track.artistss.map(a => a.artists.name).join(', ')}</p>
				</div>

				{contextMenu && contextMenuActions().length > 0 && (
					<>
						<div
							className="icon-more-vertical icon-top"
							onClick={e => {
								contextMenu.show({ event: e, id: 'context-menu-tracks' });
							}}
						/>
						<ContextMenu
							menuId="context-menu-tracks"
							actions={contextMenuActions()}
						/>
					</>
				)}
			</div>

			{showNotSorted && !track.sorted && <div className="icon-warning icon-top" />}

			{includeFeedback && feedbacks ? (
				<div className="feedback-details-wrapper">
					<Feedback feedback={feedbacks} />
					{!hideTrackLengthAndBpm && (
						<div className="track-details art">
							<p unselectable="on">{playlistRunTime([track])}</p>
							<p unselectable="on">{`BPM ${track.bpm}`}</p>
						</div>
					)}
				</div>
			) : (
				!hideTrackLengthAndBpm && (
					<div className="track-details art">
						<p unselectable="on">{playlistRunTime([track])}</p>
						<p unselectable="on">{`BPM ${track.bpm}`}</p>
					</div>
				)
			)}
		</>
	);
});

export default TrackTile;
