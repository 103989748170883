/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsMoodVersionEntity from 'Models/Security/Acl/VisitorsMoodVersionEntity';
import AgencyAdminMoodVersionEntity from 'Models/Security/Acl/AgencyAdminMoodVersionEntity';
import AgencyPlaylisterMoodVersionEntity from 'Models/Security/Acl/AgencyPlaylisterMoodVersionEntity';
import MustardAdminMoodVersionEntity from 'Models/Security/Acl/MustardAdminMoodVersionEntity';
import ApplicationUserMoodVersionEntity from 'Models/Security/Acl/ApplicationUserMoodVersionEntity';
import MustardManagementMoodVersionEntity from 'Models/Security/Acl/MustardManagementMoodVersionEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IMoodVersionEntityAttributes extends IModelAttributes {
	version: number;
	agencyOwnerId: string;

	moodTrackss: Array<
		| Models.MoodTrackEntity
		| Models.IMoodTrackEntityAttributes
	>;
	moodId: string;
	mood: Models.MoodEntity | Models.IMoodEntityAttributes;
	playlistss: Array<
		| Models.MoodVersionsPlaylists
		| Models.IMoodVersionsPlaylistsAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('MoodVersionEntity', 'Mood Version')
// % protected region % [Customise your entity metadata here] end
export default class MoodVersionEntity extends Model
	implements IMoodVersionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsMoodVersionEntity(),
		new AgencyAdminMoodVersionEntity(),
		new AgencyPlaylisterMoodVersionEntity(),
		new MustardAdminMoodVersionEntity(),
		new ApplicationUserMoodVersionEntity(),
		new MustardManagementMoodVersionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'version',
			descending: true,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Version'] off begin
	@Validators.Integer()
	@observable
	@attribute<MoodVersionEntity, number>()
	@CRUD({
		name: 'Version',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public version: number;
	// % protected region % [Modify props to the crud options here for attribute 'Version'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<MoodVersionEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Mood Tracks'] off begin
		name: 'Mood Trackss',
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.MoodTrackEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'moodTrackss',
			oppositeEntity: () => Models.MoodTrackEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Mood Tracks'] end
	})
	public moodTrackss: Models.MoodTrackEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Mood'] off begin
		name: 'Mood',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.MoodEntity,
		// % protected region % [Modify props to the crud options here for reference 'Mood'] end
	})
	public moodId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public mood: Models.MoodEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Playlists'] off begin
		name: 'Playlists',
		displayType: 'reference-multicombobox',
		order: 50,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.MoodVersionsPlaylists,
		optionEqualFunc: makeJoinEqualsFunc('playlistsId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'moodVersionEntity',
			oppositeEntityName: 'playlistEntity',
			relationName: 'moodVersions',
			relationOppositeName: 'playlists',
			entity: () => Models.MoodVersionEntity,
			joinEntity: () => Models.MoodVersionsPlaylists,
			oppositeEntity: () => Models.PlaylistEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Playlists'] end
	})
	public playlistss: Models.MoodVersionsPlaylists[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IMoodVersionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IMoodVersionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.version !== undefined) {
				this.version = attributes.version;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.moodTrackss !== undefined && Array.isArray(attributes.moodTrackss)) {
				for (const model of attributes.moodTrackss) {
					if (model instanceof Models.MoodTrackEntity) {
						this.moodTrackss.push(model);
					} else {
						this.moodTrackss.push(new Models.MoodTrackEntity(model));
					}
				}
			}
			if (attributes.moodId !== undefined) {
				this.moodId = attributes.moodId;
			}
			if (attributes.mood !== undefined) {
				if (attributes.mood === null) {
					this.mood = attributes.mood;
				} else if (attributes.mood instanceof Models.MoodEntity) {
					this.mood = attributes.mood;
					this.moodId = attributes.mood.id;
				} else {
					this.mood = new Models.MoodEntity(attributes.mood);
					this.moodId = this.mood.id;
				}
			}
			if (attributes.playlistss !== undefined && Array.isArray(attributes.playlistss)) {
				for (const model of attributes.playlistss) {
					if (model instanceof Models.MoodVersionsPlaylists) {
						this.playlistss.push(model);
					} else {
						this.playlistss.push(new Models.MoodVersionsPlaylists(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		playlistss {
			${Models.MoodVersionsPlaylists.getAttributes().join('\n')}
			playlists {
				${Models.PlaylistEntity.getAttributes().join('\n')}
			}
		}
		moodTrackss {
			${Models.MoodTrackEntity.getAttributes().join('\n')}
		}
		mood {
			${Models.MoodEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			playlistss: {},
			moodTrackss: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'playlistss',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(MoodVersionEntity.prototype, 'created');
CRUD(modifiedAttr)(MoodVersionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
