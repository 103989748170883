import React from 'react';
import { TrackEntity } from '../../../Models/Entities';

interface TrackValidationPanelProps {
	validatedTracks: TrackEntity[];
	checkExisting: boolean;
}

const TrackValidationPanel = (props: TrackValidationPanelProps): JSX.Element => {
	const { validatedTracks, checkExisting } = props;
	
	return (
		<div className="existing-track-panel">
			<div className="existing-track-info">
				{validatedTracks.length}
				{' '}
				{`${checkExisting ? 'Existing' : 'Missing'} Tracks`}
			</div>
			
			<div className="existing-track-warning">
				{
					checkExisting ? 'Tracks will not be copied over but will still be visible in the bank.' 
						: 'You must purchase and upload the missing tracks to the Music Library, before they can be added to a playlist.'				
				}
			</div>
			
			<div className="existing-track-container">
				{validatedTracks.map(track => (
					<div className="existing-track-row">
						<div className="track-title">{track.title}</div>
						
						<div className="track-artist">
							{track.artistss.map((artist => (
								<>{artist.artists.name}</>
							)))}
						</div>
						
						<div className="track-album">{track.album && track.album.title}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default TrackValidationPanel;
