import React from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import classNames from 'classnames';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Components/Button/Button';
import {
	ClientEntity, VenueEntity,
} from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import ClientModal from './ClientModal';
import VenueModal from './Venues/VenueModal';
import EntityCRUD from '../../Components/CRUD/EntityCRUD';
import { IWhereCondition } from '../../Components/ModelCollection/ModelQuery';
import { archiveAction } from '../Admin/ArchiveAction';
import * as Models from '../../../Models/Entities';

interface EditClientTileProps {
	client: ClientEntity;
	refetchClient: () => void;
}

const EditClientTile = (props: EditClientTileProps): JSX.Element => {
	const {
		client,
		refetchClient,
	} = props;

	const history = useHistory();
	const location = useLocation();
	const match = useRouteMatch();

	const extraConditions = () => {
		const conditions = [] as IWhereCondition<VenueEntity>[];

		conditions.push(
			{
				comparison: 'equal',
				path: 'clientId',
				value: client.id,
			} as IWhereCondition<VenueEntity>,
		);

		return conditions;
	};

	const openEditClientModal = () => {
		store.modal.show(
			'Venue',
			<ClientModal
				title="Edit Client"
				client={client}
				refetchClient={refetchClient}
			/>,
			{ className: 'slideout-panel-right' },
		);
	};

	return (
		<div className="client-container">
			<div className="client-header">
				<h1>{client.name}</h1>
				<Button
					colors={Colors.Primary}
					display={Display.Text}
					sizes={Sizes.Medium}
					buttonProps={{ id: 'edit-client' }}
					onClick={() => openEditClientModal()}
					disabled={store.userGroups.some(ug => ug.name === 'AgencyPlaylister')}
				>
					Edit
				</Button>
			</div>
			<div className="client-contacts">
				<div className="main-client-contact">
					<h4>Main Contact</h4>
					<div className="detail-container">
						<p className="detail-label">Contact Name</p>
						<p className="detail-value">
							{client.contactss?.filter(contact => contact.mainContact)[0]?.name}
						</p>
					</div>
					<div className="detail-container">
						<p className="detail-label">Contact No.</p>
						<p className="detail-value">
							{client.contactss?.filter(contact => contact.mainContact)[0]?.phoneNumber}
						</p>
					</div>
					<div className="detail-container">
						<p className="detail-label">Email Address</p>
						<p className="detail-value">
							{client.contactss?.filter(contact => contact.mainContact)[0]?.emailAddress}
						</p>
					</div>
				</div>
			</div>
			<div className="client-venues">
				<EntityCRUD
					modelType={VenueEntity}
					history={history}
					location={location}
					match={match}
					perPage={5}
					extraConditions={extraConditions()}
					collectionCreateAction={() => (
						<Button
							key="create"
							className={classNames(Display.Solid)}
							icon={{ icon: 'create', iconPos: 'icon-right' }}
							buttonProps={{
								onClick: () => {
									store.modal.show(
										'Venue',
										<VenueModal
											client={client}
											redirect
											title="Add Venue"
										/>,
										{ className: 'slideout-panel-right' },
									);
								},
							}}
						>
							Create Venue
						</Button>
					)}
					collectionDeleteAction={() => undefined}
					entityCollectionProps={{
						additionalTableActions: [archiveAction(Models.ClientEntity, () => undefined)],
					}}
				/>
			</div>
		</div>
	);
};

export default observer(EditClientTile);
