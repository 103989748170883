/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { observer, useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import { DragDropContext } from 'react-beautiful-dnd';
import Axios, { AxiosResponse } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { Button, Sizes } from '../../../../Components/Button/Button';
import { alertModal, confirmModal } from '../../../../Components/Modal/ModalUtils';
import CreateMood from '../../Moods/CreateMood';
import {
	MoodEntity,
	PlaylistEntity,
	ProfileEntity,
} from '../../../../../Models/Entities';
import MoodTile from '../../Moods/MoodTile';
import Playlists from '../../Moods/Playlists';
import { SERVER_URL } from '../../../../../Constants';
import MoodContents from '../../Moods/MoodContents';
import { store } from '../../../../../Models/Store';
import alertToast from '../../../../../Util/ToastifyUtils';
import EditMoodModal from '../../Moods/EditMood';
import MoodSummary from '../MoodSummary';
import MoodPlaylistTab from './MoodPlaylistTab';

interface MoodTabProps {
	profile: ProfileEntity | undefined;
}

interface MoodStore {
	activeMood: MoodEntity | null;
}

interface PlaylistStore {
	playlist: PlaylistEntity | undefined;
}

const defaultMoodStore = {
	activeMood: null,
} as MoodStore;

const defaultPlaylistStore = {
	playlist: undefined,
} as PlaylistStore;

const MoodTab = observer((props: MoodTabProps): JSX.Element => {
	const { profileId } = useParams<{ profileId: string }>();
	const { profile } = props;

	const queryClient = useQueryClient();

	const moodStore = useLocalStore(() => (defaultMoodStore));

	const playlistStore = useLocalStore(() => (defaultPlaylistStore));

	const multiSelectStore = useLocalStore(() => ({
		start: -1,
		end: -1,
		selected: [] as string[],
		numSelected: 0,
		collectionId: '',
	}));

	const clearMultiSelect = action(() => {
		multiSelectStore.start = -1;
		multiSelectStore.end = -1;
		multiSelectStore.selected = [] as string[];
		multiSelectStore.numSelected = 0;
		multiSelectStore.collectionId = '';
	});

	// useEffect is required to initialise multiSelectStore
	// It can only be removed when refactoring completed on multi select functionality
	useEffect(() => {}, [multiSelectStore.numSelected]);

	const multiSelect = action((
		event: React.MouseEvent<HTMLInputElement> | KeyboardEvent,
		index = -1,
		start: boolean,
		track: string,
		trackList: string[],
		collectionId: string,
	) => {
		if (multiSelectStore.collectionId !== collectionId) {
			clearMultiSelect();
			multiSelectStore.collectionId = collectionId;
		}
		// allows of adding or removing individual tracks
		if (event.ctrlKey || event.metaKey) {
			if (!multiSelectStore.selected.includes(track)) {
				multiSelectStore.selected.push(track);
				multiSelectStore.numSelected += 1;
			} else {
				multiSelectStore.selected = multiSelectStore.selected.filter(x => x !== track);
				multiSelectStore.numSelected -= 1;
			}
			multiSelectStore.start = index;
		}

		// add tracks in bulk
		if (event.shiftKey) {
			multiSelectStore.end = index;
			if (multiSelectStore.start < multiSelectStore.end) {
				const tracksToAdd = trackList
					.slice(multiSelectStore.start, multiSelectStore.end + 1)
					.filter(t => !multiSelectStore.selected.includes(t));
				multiSelectStore.selected.push(...tracksToAdd);
				multiSelectStore.numSelected += tracksToAdd.length;
			} else {
				const tracksToAdd = trackList
					.slice(multiSelectStore.end, multiSelectStore.start + 1)
					.filter(t => !multiSelectStore.selected.map(x => x).includes(t));
				multiSelectStore.selected.push(...tracksToAdd);
				multiSelectStore.numSelected += tracksToAdd.length;
			}
			multiSelectStore.start = index;
		}

		// if neither shift or ctrl, reset multiselected.
		if (!event.shiftKey && !(event.ctrlKey || event.metaKey)) {
			multiSelectStore.start = index;
			multiSelectStore.end = -1;
			multiSelectStore.selected = [track];
			multiSelectStore.numSelected = 0;
			multiSelectStore.numSelected = 1;
		}
	});

	const createMood = (): void => {
		alertModal('', <CreateMood profileId={profileId} profile={profile || new ProfileEntity()} />);
	};

	const setActiveMood = action((mood: MoodEntity) => {
		moodStore.activeMood = mood;
	});

	const setActivePlaylist = action((playlist: PlaylistEntity | undefined) => {
		playlistStore.playlist = playlist;
		clearMultiSelect();
	});

	useEffect(() => {
		if (profile?.moodss?.some(m => m.id === moodStore.activeMood?.id)) {
			runInAction(() => {
				moodStore.activeMood = profile?.moodss.filter(m => m.id === moodStore.activeMood?.id)[0];
			});
		}
	}, [moodStore, profile]);

	const selectAll = (isRemoving: boolean) => {
		if (!moodStore.activeMood) {
			return;
		}
		const queryString = `${SERVER_URL}/api/entity/MoodEntity/`
			+ `${isRemoving ? 'RemovePlaylist' : 'AddPlaylist'}`
			+ `?moodId=${moodStore.activeMood.id}&playlistId=${playlistStore?.playlist?.id}`;

		Axios.post(queryString)
			.then(() => alertToast(`Successfully ${isRemoving ? 'removing' : 'adding'} all tracks`, 'success'))
			// .then(() => queryClient.refetchQueries('profile'))
			.then(() => {
				queryClient.invalidateQueries('settings_stats');
				queryClient.invalidateQueries('playlists');
				queryClient.invalidateQueries('mood_playlist');
				queryClient.invalidateQueries(['profile_summary', profileId]);
				queryClient.invalidateQueries(['profile', profileId]);
				queryClient.invalidateQueries(['settings_stats', moodStore.activeMood?.id]);
			})
			// .then(() => queryClient.refetchQueries('profile_summary'))
			// .then(() => queryClient.refetchQueries('collection_contents'))
			.catch(() => {
				alertToast('Something went wrong! Cannot add or remove tracks.', 'error');
			});
	};

	const selectTracks = (add: boolean) => {
		if (!moodStore.activeMood) {
			return;
		}
		Axios.post(
			`${SERVER_URL}/api/entity/MoodEntity/${add ? 'AddTracks' : 'RemoveTracks'}`,
			{
				moodId: moodStore.activeMood.id,
				playlistId: playlistStore.playlist?.id,
				trackIds: multiSelectStore.selected,
			},
		)
			.then(() => {
				alertToast(
					`Successfully ${add ? 'added' : 'removing'} ${multiSelectStore.selected.length} track(s)`,
					'success',
				);
			})
			.then(() => {
				queryClient.invalidateQueries('playlists');
				queryClient.invalidateQueries('mood_contents');
				queryClient.invalidateQueries('mood_playlist');
				queryClient.invalidateQueries(['profile_summary', profileId]);
				queryClient.invalidateQueries(['profile', profileId]);
				queryClient.invalidateQueries(['settings_stats', moodStore.activeMood?.id]);
				clearMultiSelect();
			})
			.catch(() => {
				alertToast('Something went wrong! Cannot add or remove tracks.', 'error');
			});
	};

	const onDragEnd = action(() => {
		if (!moodStore.activeMood) {
			alertToast('A mood must be selected', 'error');
			return;
		}
		Axios.post(
			`${SERVER_URL}/api/entity/MoodEntity/AddTracks`,
			{
				moodId: moodStore.activeMood.id,
				playlistId: playlistStore.playlist?.id,
				trackIds: multiSelectStore.selected,
			},
		)
			.then(() => {
				alertToast(`Successfully added ${multiSelectStore.selected.length} track(s)`, 'success');
				queryClient.refetchQueries('mood_contents');
				queryClient.refetchQueries('mood_playlist');
				clearMultiSelect();
			})
			.catch(() => {
				alertToast('Something went wrong! Cannot add tracks.', 'error');
			});
	});

	const archiveMood = async (moodId: string): Promise<void> => {
		confirmModal('Archive Mood', 'Are you sure you want to archive mood?')
			.then(() => {
				Axios.post(`${SERVER_URL}/api/entity/MoodEntity/ArchiveMood/${moodId}`)
					.then(res => {
						if (res.data) {
							// Hacky clear cache
							store.routerHistory.push('/');
							store.routerHistory.push(`/profile-management/edit/${profileId}`);
							alertToast('Mood archived successfully', 'success');
						} else {
							alertToast(
								'This mood has been assigned to a schedule. '
								+ 'Remove the mood from the schedule before deleting.',
								'error',
							);
						}
					}).catch(err => {
						alertToast(`Mood could not be deleted: ${err}`, 'error');
					});
			});
	};

	// List of menu actions for Mood tiles
	const returnMoodTileMenuActions = (mood: MoodEntity):{label: string; onClick: () => void}[] => ([
		{
			label: 'Edit Mood',
			onClick: () => {
				store.modal.show('Edit Modal', <EditMoodModal mood={mood} />, { className: 'slideout-panel-right' });
			},
		},
		{
			label: 'Archive Mood',
			onClick: (): Promise<void> => archiveMood(mood.id),
		},
	]);

	return (
		<div className="mood-tab-container">
			<div className="mood-tiles-container">
				<div className="mood-tab-header">
					<Button
						icon={{ icon: 'plus', iconPos: 'icon-top' }}
						sizes={Sizes.Small}
						onClick={() => createMood()}
					/>
				</div>

				<div className="moods">
					{ /* Moods */ }
					{profile?.moodss?.filter(mood => !mood.injectable).map((mood: MoodEntity) => (
						<MoodTile
							mood={mood}
							key={mood.id}
							onClick={() => setActiveMood(mood)}
							contextMenuActions={returnMoodTileMenuActions(mood)}
							isActive={mood.id === moodStore.activeMood?.id}
						/>
					))}

					{ /* Injectable Moods */ }
					{
						profile?.moodss?.some(m => m.injectable)
							&& <h4 style={{ marginTop: '3rem' }}>Injectable Moods</h4>
					}

					{profile?.moodss?.filter(mood => mood.injectable).map((mood: MoodEntity) => (
						<MoodTile
							mood={mood}
							onClick={() => setActiveMood(mood)}
							contextMenuActions={returnMoodTileMenuActions(mood)}
							isActive={mood.id === moodStore.activeMood?.id}
						/>
					))}
				</div>
			</div>

			{/* Mood Settings column */}
			{moodStore.activeMood ? (
				<div className="mood-setting-container">
					<MoodSummary mood={moodStore.activeMood} />
					<DragDropContext onDragEnd={onDragEnd}>
						<div className="playlists-container">
							<Playlists
								activePlaylist={playlistStore.playlist}
								setActivePlaylist={setActivePlaylist}
								mood={moodStore.activeMood}
							/>
						</div>
						{playlistStore.playlist ? (
							<div className="playlist-contents-container">
								<MoodPlaylistTab
									collection={playlistStore}
									multiSelectStore={multiSelectStore}
									multiSelect={multiSelect}
									moodVersion={moodStore.activeMood.draftMoodVersion}
									selectAll={selectAll}
									selectTracks={selectTracks}
									playlistId={playlistStore.playlist.id}
									playlistName={playlistStore.playlist.name}
								/>
							</div>
						) : (
							<div className="playlist-contents-container">
								<MoodContents
									mood={moodStore.activeMood}
									multiSelect={multiSelect}
									multiSelectStore={multiSelectStore}
									selectTracks={selectTracks}
								/>
							</div>
						)}
					</DragDropContext>
				</div>
			) : (
				<></>
			)}
		</div>
	);
});

export default MoodTab;
