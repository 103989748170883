import { observer, useLocalStore } from 'mobx-react';
import { useQuery } from 'react-query';
import {
	Button, Colors, Display, Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import ProfileModal from './Profiles/CreateProfileModal';
import { IProfileEntityAttributes, ProfileEntity } from '../../../Models/Entities';
import { Combobox } from '../../Components/Combobox/Combobox';
import { getFetchAllQuery } from '../../../Util/EntityUtils';
import ProfileDisplayTile from './Profiles/ProfileDisplayTile';
import LoadingContainer from 'Views/Components/LoadingContainer/LoadingContainer';

const orderBy = [
	{ path: 'name', descending: false },
];

export const ProfileManagementTile = observer((): JSX.Element => {
	const profileSearch = useLocalStore(() => ({ profile: undefined }));

	const fetchProfiles = async (): Promise<ProfileEntity[]> => {
		const { data } = await store.apolloClient.query<{profileEntitys: IProfileEntityAttributes[]}>({
			query: getFetchAllQuery(ProfileEntity, '', true),
			fetchPolicy: 'network-only',
			variables: { args: [], orderBy },
		});

		return data.profileEntitys.map(x => new ProfileEntity(x));
	};

	const {
		isLoading, isError, data: profiles,
	} = useQuery('profiles', fetchProfiles);

	if (isLoading) {
		return <LoadingContainer />;
	}

	if (isError) {
		return <span>Error occured</span>;
	}

	return (
		<div className="profile-manager-wrapper">
			<div className="profile-manager-header">
				<h1>Profile Manager</h1>

				<Button
					display={Display.Solid}
					sizes={Sizes.Small}
					colors={Colors.Primary}
					icon={{ icon: 'create', iconPos: 'icon-right' }}
					buttonProps={{
						onClick: (): void => {
							store.modal.show(
'Profile',
								<ProfileModal />,
{ className: 'slideout-panel-right' },
);
						},
					}}
				>
					Create Profile
				</Button>
			</div>

			{profiles?.length ? (
				<Combobox
					model={profileSearch}
					modelProperty="profile"
					label="Search Profile"
					options={profiles.map((x: ProfileEntity) => ({ display: x.name, value: x }))}
					getOptionValue={option => option?.id}
					className="profile-search"
					placeholder="Search Profile"
				/>
			) : <></>}

			<h3>Profile</h3>
			{ profileSearch.profile ? <ProfileDisplayTile profile={profileSearch.profile} /> : null }
		</div>
	);
});
