/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterScheduleEntity from 'Models/Security/Acl/AgencyPlaylisterScheduleEntity';
import AgencyAdminScheduleEntity from 'Models/Security/Acl/AgencyAdminScheduleEntity';
import MustardManagementScheduleEntity from 'Models/Security/Acl/MustardManagementScheduleEntity';
import ApplicationUserScheduleEntity from 'Models/Security/Acl/ApplicationUserScheduleEntity';
import VisitorsScheduleEntity from 'Models/Security/Acl/VisitorsScheduleEntity';
import MustardAdminScheduleEntity from 'Models/Security/Acl/MustardAdminScheduleEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IScheduleEntityAttributes extends IModelAttributes {
	name: string;
	agencyOwnerId: string;

	announcementTimeslotss: Array<
		| Models.AnnouncementTimeslotEntity
		| Models.IAnnouncementTimeslotEntityAttributes
	>;
	profileId?: string;
	profile?: Models.ProfileEntity | Models.IProfileEntityAttributes;
	timeslotss: Array<
		| Models.TimeslotEntity
		| Models.ITimeslotEntityAttributes
	>;
	volumeTimeslotss: Array<
		| Models.VolumeTimeslotEntity
		| Models.IVolumeTimeslotEntityAttributes
	>;
	zoness: Array<
		| Models.ZoneEntity
		| Models.IZoneEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ScheduleEntity', 'Schedule')
// % protected region % [Customise your entity metadata here] end
export default class ScheduleEntity extends Model
	implements IScheduleEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterScheduleEntity(),
		new AgencyAdminScheduleEntity(),
		new MustardManagementScheduleEntity(),
		new ApplicationUserScheduleEntity(),
		new VisitorsScheduleEntity(),
		new MustardAdminScheduleEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'name',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@observable
	@attribute<ScheduleEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] off begin
	@Validators.Uuid()
	@observable
	@attribute<ScheduleEntity, string>()
	@CRUD({
		name: 'Agency Owner Id',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public agencyOwnerId: string;
	// % protected region % [Modify props to the crud options here for attribute 'Agency Owner Id'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Announcement Timeslots'] off begin
		name: 'Announcement Timeslotss',
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.AnnouncementTimeslotEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'announcementTimeslotss',
			oppositeEntity: () => Models.AnnouncementTimeslotEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Announcement Timeslots'] end
	})
	public announcementTimeslotss: Models.AnnouncementTimeslotEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Profile'] off begin
		name: 'Profile',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.ProfileEntity,
		// % protected region % [Modify props to the crud options here for reference 'Profile'] end
	})
	public profileId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public profile: Models.ProfileEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Timeslots'] off begin
		name: 'Timeslotss',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.TimeslotEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'timeslotss',
			oppositeEntity: () => Models.TimeslotEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Timeslots'] end
	})
	public timeslotss: Models.TimeslotEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Volume Timeslots'] off begin
		name: 'Volume Timeslotss',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.VolumeTimeslotEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'volumeTimeslotss',
			oppositeEntity: () => Models.VolumeTimeslotEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Volume Timeslots'] end
	})
	public volumeTimeslotss: Models.VolumeTimeslotEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Zones'] off begin
		name: 'Zoness',
		displayType: 'reference-multicombobox',
		order: 70,
		referenceTypeFunc: () => Models.ZoneEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'zoness',
			oppositeEntity: () => Models.ZoneEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Zones'] end
	})
	public zoness: Models.ZoneEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IScheduleEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IScheduleEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.agencyOwnerId !== undefined) {
				this.agencyOwnerId = attributes.agencyOwnerId;
			}
			if (attributes.announcementTimeslotss !== undefined && Array.isArray(attributes.announcementTimeslotss)) {
				for (const model of attributes.announcementTimeslotss) {
					if (model instanceof Models.AnnouncementTimeslotEntity) {
						this.announcementTimeslotss.push(model);
					} else {
						this.announcementTimeslotss.push(new Models.AnnouncementTimeslotEntity(model));
					}
				}
			}
			if (attributes.profileId !== undefined) {
				this.profileId = attributes.profileId;
			}
			if (attributes.profile !== undefined) {
				if (attributes.profile === null) {
					this.profile = attributes.profile;
				} else if (attributes.profile instanceof Models.ProfileEntity) {
					this.profile = attributes.profile;
					this.profileId = attributes.profile.id;
				} else {
					this.profile = new Models.ProfileEntity(attributes.profile);
					this.profileId = this.profile.id;
				}
			}
			if (attributes.timeslotss !== undefined && Array.isArray(attributes.timeslotss)) {
				for (const model of attributes.timeslotss) {
					if (model instanceof Models.TimeslotEntity) {
						this.timeslotss.push(model);
					} else {
						this.timeslotss.push(new Models.TimeslotEntity(model));
					}
				}
			}
			if (attributes.volumeTimeslotss !== undefined && Array.isArray(attributes.volumeTimeslotss)) {
				for (const model of attributes.volumeTimeslotss) {
					if (model instanceof Models.VolumeTimeslotEntity) {
						this.volumeTimeslotss.push(model);
					} else {
						this.volumeTimeslotss.push(new Models.VolumeTimeslotEntity(model));
					}
				}
			}
			if (attributes.zoness !== undefined && Array.isArray(attributes.zoness)) {
				for (const model of attributes.zoness) {
					if (model instanceof Models.ZoneEntity) {
						this.zoness.push(model);
					} else {
						this.zoness.push(new Models.ZoneEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		announcementTimeslotss {
			${Models.AnnouncementTimeslotEntity.getAttributes().join('\n')}
		}
		profile {
			${Models.ProfileEntity.getAttributes().join('\n')}
		}
		timeslotss {
			${Models.TimeslotEntity.getAttributes().join('\n')}
		}
		volumeTimeslotss {
			${Models.VolumeTimeslotEntity.getAttributes().join('\n')}
		}
		zoness {
			${Models.ZoneEntity.getAttributes().join('\n')}
			${Models.ZoneEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] on begin
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
			announcementTimeslotss: {},
			timeslotss: {},
			volumeTimeslotss: {},
			zoness: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'announcementTimeslotss',
							'timeslotss',
							'volumeTimeslotss',
							'zoness',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ScheduleEntity.prototype, 'created');
CRUD(modifiedAttr)(ScheduleEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
