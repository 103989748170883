import React from 'react';
import Axios from 'axios';
import { runInAction } from 'mobx';
import { useQueryClient } from 'react-query';
import { observer } from 'mobx-react';
import { SERVER_URL } from '../../../Constants';
import alert from '../../../Util/ToastifyUtils';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';

interface IRemoveTrackConfirmationModalProps {
	payload: {
		trackIds: string[];
		playlistId: string;
	};
	multiSelectStore: {
		start: number;
		end: number;
		selected: string[];
		numSelected?: number;
		collectionId?: string;
	};
	orderStore: {
		orderBy: string;
	};
}

const RemoveTrackConfirmationModal = observer((props: IRemoveTrackConfirmationModalProps) => {
	const { payload, multiSelectStore, orderStore } = props;

	const queryClient = useQueryClient();

	const removeTracks = () => {
		Axios.post(`${SERVER_URL}/api/playlist_search/remove_tracks`, payload)
			.then(() => {
				runInAction(() => {
					multiSelectStore.selected = [];
				});
				queryClient.refetchQueries(['playlist_contents', payload.playlistId, orderStore.orderBy]);
				queryClient.refetchQueries(['summary', payload.playlistId]);

				alert('Tracks removed', 'success');
				store.modal.hide();
			})
			.catch(() => alert('Something went wrong', 'error'));
	};

	return (
		<div>
			<h4>Remove Tracks From Playlist</h4>
			<div className="modal-message">
				<p>One or more of the selected tracks are currently being used in a Mood.</p>
				<p>Removing them from this playlist will also remove them from any Mood they were added to from this Playlist.</p>
				<p>Do you want to proceed?</p>
			</div>
			<div className="form-controls">
				<Button
					display={Display.Outline}
					colors={Colors.White}
					sizes={Sizes.Medium}
					className="cancel"
					onClick={() => store.modal.hide()}
				>
					Cancel
				</Button>
				<Button
					display={Display.Solid}
					colors={Colors.Primary}
					sizes={Sizes.Medium}
					onClick={removeTracks}
				>
					Confirm
				</Button>
			</div>
		</div>
	);
});

export default RemoveTrackConfirmationModal;
