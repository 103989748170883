import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { useQuery, useQueryClient } from 'react-query';
import { NumberTextField } from '../../../Components/NumberTextBox/NumberTextBox';
import { DatePicker } from '../../../Components/DatePicker/DatePicker';
import {
	Button, Colors, Display, Sizes,
} from '../../../Components/Button/Button';
import { TextField } from '../../../Components/TextBox/TextBox';
import { store } from '../../../../Models/Store';
import alert from '../../../../Util/ToastifyUtils';
import { IMoodEntityAttributes, InjectableFrequencyEntity, MoodEntity } from '../../../../Models/Entities';
import { IMoodForm, moodColours, validateMoodFormFields } from './IMoodForm';
import { getFetchSingleQuery } from '../../../../Util/EntityUtils';

interface EditMoodProps {
	mood: MoodEntity;
}

const defaultMoodForm: IMoodForm = {
	name: '',
	colour: '',
	injectable: false,
	injectableFrequencyEntitys: [],
	errors: {},
};

const fetchMood = async (moodId: string): Promise<MoodEntity> => {
	const { data } = await store.apolloClient.query<{ moodEntity: IMoodEntityAttributes }>({
		query: getFetchSingleQuery(MoodEntity, '', true),
		fetchPolicy: 'network-only',
		variables: {
			args: [{
				path: 'id',
				comparison: 'equal',
				value: moodId,
			}],
		},
	});

	return new MoodEntity(data.moodEntity);
};

const EditMoodModal = (props: EditMoodProps): JSX.Element => {
	const { mood } = props;

	const queryClient = useQueryClient();

	const moodForm = useLocalStore(() => (
		defaultMoodForm
	));

	// Update mood versions
	fetchMood(mood.id).then((moodEntity => {
		runInAction(() => {
			mood.draftVersion = moodEntity.draftVersion;
			mood.publishedVersion = moodEntity.publishedVersion;
		});
	}));

	const {
		data: injectableFrequenciess,
	} = useQuery(`${mood.id}-injectableFrequenciess`, mood.fetchInjectableFrequencyEntitysQuery);

	useEffect(() => {
		runInAction(() => {
			moodForm.name = mood.name;
			moodForm.colour = mood.colour;
			moodForm.injectable = mood.injectable;

			// Injectable Frequencies 
			if (moodForm.injectable && injectableFrequenciess) {
				runInAction(() => {
					mood.injectableFrequenciess = injectableFrequenciess; // set the injectableFrequenciess on the mood
					moodForm.injectableFrequencyEntitys = [...injectableFrequenciess]; // set the injectableFrequenciess on the form
				});
			}
		});
	}, [injectableFrequenciess]);

	const removeInjectableFrequency = (index: number): void => {
		runInAction(() => moodForm.injectableFrequencyEntitys.splice(index, 1));
	};

	const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
		e.preventDefault();

		validateMoodFormFields(moodForm);

		if (Object.keys(moodForm.errors).length === 0) {
			runInAction(() => {
				// Delete removed injectable frequencies
				mood.injectableFrequenciess.filter(f => !moodForm.injectableFrequencyEntitys.includes(f)).forEach(
					f => f.delete()
						.catch(() => alert('Failed deleting injectable frequency', 'error')),
				);

				mood.name = moodForm.name;
				mood.colour = moodForm.colour;
				mood.injectable = moodForm.injectable;
				mood.injectableFrequenciess = moodForm.injectableFrequencyEntitys;

				mood.save({ injectableFrequenciess: {} })
					.then(() => queryClient.refetchQueries(`${mood.id}-injectableFrequenciess`))
					.then(() => queryClient.refetchQueries('profile'))
					.then(() => alert('Mood Saved', 'success'))
					.then(() => store.modal.hide())
					.catch(() => alert('Failed creating mood', 'error'));
			});
		}
	};

	return (
		<div className="edit-mood-modal-Container">
			<h4>Edit Mood</h4>

			<form onSubmit={onSubmit}>
				<div className="mood-modal-row top-row">
					<TextField
						className="row-element input-group"
						model={moodForm}
						modelProperty="name"
						label="Mood Name"
						isRequired
						errors={moodForm.errors.name}
					/>
					<div className="row-element">
						<p>Injectable Mood</p>
						<label className="switch" htmlFor="injectable-toggle">
							<input
								type="checkbox"
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => runInAction(() => moodForm.injectable = event.target.checked)}
								checked={moodForm.injectable}
								aria-label="injectable toggle"
								id="injectable-toggle"
							/>
							<span
								className="slider round"
							/>
						</label>
					</div>
				</div>

				{moodForm.injectable && (
					<>
						<h4>Injectable Frequencies</h4>
						<div>
							{moodForm.injectableFrequencyEntitys?.map((f: InjectableFrequencyEntity, index: number) => (
								<div className="mood-modal-row row" key={`${f.id} - ${index}`}>
									<NumberTextField
										className="row-element input-group"
										model={f}
										modelProperty="frequency"
										label="Frequency"
										isRequired
										tooltip="Inject a track every X tracks"
										errors={moodForm.errors.frequency ? moodForm.errors.frequency[index] : undefined}
									/>
									<DatePicker
										className="row-element input-group"
										model={f}
										modelProperty="start"
										label="Start Date"
										humanFriendly
										isRequired
										errors={moodForm.errors.startDate ? moodForm.errors.startDate[index] : undefined}
										dateFormat="d/m/Y"
										altFormat="d/m/Y"
									/>
									<DatePicker
										className="row-element input-group"
										model={f}
										modelProperty="end"
										label="End Date"
										humanFriendly
										isRequired
										errors={moodForm.errors.endDate ? moodForm.errors.endDate[index] : undefined}
										dateFormat="d/m/Y"
										altFormat="d/m/Y"
									/>
									<Button
										className="injectable-schedule"
										sizes={Sizes.Medium}
										colors={Colors.Error}
										display={Display.Text}
										icon={{ icon: 'bin-delete', iconPos: 'icon-top' }}
										onClick={() => removeInjectableFrequency(index)}
									/>
								</div>
							))}
							<Button
								sizes={Sizes.Medium}
								colors={Colors.Primary}
								display={Display.Text}
								icon={{ icon: 'plus', iconPos: 'icon-left' }}
								onClick={() => {
									runInAction(() => {
										moodForm.injectableFrequencyEntitys?.push(new InjectableFrequencyEntity());
									});
								}}
							>
								New
							</Button>
						</div>
					</>
				)}

				<h4>Colours</h4>
				<div className="mood-colours">
					{moodColours.map(colour => (
						<div key={colour} className={`colour-selector-wrapper ${colour} ${colour === moodForm.colour ? 'selected' : ''}`}>
							<div
								className="colour-selector"
								role="button"
								tabIndex={0}
								onClick={() => {
									runInAction(() => {
										moodForm.colour = colour;
									});
								}}
							>
								{colour === moodForm.colour && <p>Selected</p>}
							</div>
						</div>
					))}
				</div>

				<div className="form-controls">
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={(): void => store.modal.hide()}
					>
						Cancel
					</Button>
					<Button
						type="submit"
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'submit' }}
					>
						Save
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(EditMoodModal);
