import React from 'react';
import { RouteComponentProps } from 'react-router/index';
import classNames from 'classnames';
import EntityCRUD from '../../Components/CRUD/EntityCRUD';
import { VenueEntity } from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import VenueModal from '../Clients/Venues/VenueModal';
import { Button, Display } from '../../Components/Button/Button';
import { archiveAction } from '../Admin/ArchiveAction';
import * as Models from '../../../Models/Entities';

export const VenuesTile = (props: RouteComponentProps) => {
	const { history, location, match } = props;

	return (
		<div className={`venues-dashboard ${store.hasBackendAccess ? 'frontend-admin' : ''}`}>
			<EntityCRUD
				modelType={VenueEntity}
				history={history}
				location={location}
				match={match}
				perPage={8}
				collectionTitle="Venues"
				collectionCreateAction={() => (
					<Button
						key="create"
						className={classNames(Display.Solid)}
						icon={{ icon: 'create', iconPos: 'icon-right' }}
						buttonProps={{
							onClick: () => {
								store.modal.show(
									'Venue',
									<VenueModal
										redirect
										title="Add Venue"
									/>,
									{ className: 'slideout-panel-right', shouldCloseOnOverlayClick: false },
								);
							},
						}}
					>
						Create Venue
					</Button>
				)}
				collectionDeleteAction={() => undefined}
				entityCollectionProps={{
					additionalTableActions: [archiveAction(Models.ClientEntity, () => undefined)],
				}}
			/>
		</div>
	);
};
