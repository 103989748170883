import { FeedbackEntity } from '../../../../Models/Entities';
import DownArrow from '../../../../assets/down-arrow.svg';
import UpArrow from '../../../../assets/up-arrow.svg';

interface IFeedbackProps {
	feedback: FeedbackEntity[];
}

const Feedback = (props: IFeedbackProps) => {
	const { feedback } = props;
	
	return (
		<div className="feedback-container">
			<div className="feedback-item liked" data-testid={`liked-${feedback.length > 0 ? feedback[0].moodTrackId : 'none'}`}>
				<img src={UpArrow} alt="liked" />
				{feedback.filter(f => f.liked).length}
			</div>
			<div className="feedback-item disliked" data-testid={`disliked-${feedback.length > 0 ? feedback[0].moodTrackId : 'none'}`}>
				{feedback.filter(f => f.disliked).length}
				<img src={DownArrow} alt="disliked" />
			</div>
			<div className="feedback-item icon-slash icon-right" data-testid={`disabled-${feedback.length > 0 ? feedback[0].moodTrackId : 'none'}`}>{feedback.filter(f => f.disabled).length}</div>
		</div>
	);
};

export default Feedback;
