/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from '../../../../Components/TextBox/TextBox';
import { runInAction } from 'mobx';

interface IApplicationUserDetailsErrors {
	firstName: string;
	lastName: string;
	displayName: string;
	email: string;
}

interface ApplicationUserDetails {
	firstName: string;
	lastName: string;
	displayName: string;
	email: string;
	disableVolumeAdjustment: boolean;
	errors: IApplicationUserDetailsErrors;
}

interface IEditApplicationUserTileUserDetails {
	applicationUserDetails: ApplicationUserDetails;
	mode: { edit: boolean; }
}

export default observer((props: IEditApplicationUserTileUserDetails) => {
	const { applicationUserDetails, mode } = props;
	return (
		<div className="user-details">
			<div className="double-input">
				<TextField
					model={applicationUserDetails}
					modelProperty="firstName"
					errors={applicationUserDetails.errors.firstName}
					isDisabled={!mode.edit}
					testId="firstName-input"
					label="First Name"
				/>
				<TextField
					model={applicationUserDetails}
					modelProperty="lastName"
					errors={applicationUserDetails.errors.lastName}
					isDisabled={!mode.edit}
					testId="lastName-input"
					label="Last Name"
				/>
			</div>
			<TextField
				model={applicationUserDetails}
				modelProperty="displayName"
				errors={applicationUserDetails.errors.displayName}
				isDisabled={!mode.edit}
				testId="displayName-input"
				label="Display Name"
			/>
			<TextField
				model={applicationUserDetails}
				modelProperty="email"
				errors={applicationUserDetails.errors.email}
				isDisabled={!mode.edit}
				testId="email-input"
				label="Email"
			/>
			<div>
				<p>Disable Volume Adjustment</p>
				<label
					className="switch"
					htmlFor="disable-volume-adjustment"
				>
					<input
						id="disable-volume-adjustment"
						type="checkbox"
						disabled={!mode.edit}
						onChange={e => runInAction(() => {
							applicationUserDetails.disableVolumeAdjustment = e.target.checked;
						})}
						checked={applicationUserDetails.disableVolumeAdjustment}
					/>
					<span className="slider round" />
				</label>
			</div>
		</div>
	);
});
