/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { ILink } from 'Views/Components/Navigation/Navigation';
import { RouteComponentProps } from 'react-router-dom';
import { store } from 'Models/Store';
// % protected region % [Add any extra imports here] on begin
// This is a temporary feature for the navigation, adding a category to group up the navLinks
export const frontendNavCategories: string[]= [ "Clients", "Music Curation", "Library Management", "Reporting" ]
// % protected region % [Add any extra imports here] end

// % protected region % [Customise getFrontendNavLinks signature here] off begin
// eslint-disable-next-line @typescript-eslint/no-unused-vars,import/prefer-default-export
export function getFrontendNavLinks(pageProps: RouteComponentProps): ILink[][] {
// % protected region % [Customise getFrontendNavLinks signature here] end

	// % protected region % [Add any logic before displaying page links here] on begin
	// Due to the structure of the CodeBot component, there is no way but to create a new array that doesn't divide into top, middle and bottom nav sections.
	// Having this return before the code generated return (instead of deleting it) prevents the bot regenerate the code
	return [
		[
			{ label: 'Clients', path: '/clients', icon: 'person-group', iconPos: 'icon-left' },
			{ label: 'Venues', path: '/venues', icon: 'buildings', iconPos: 'icon-left' },
		],
		[
			{ label: 'Playlists', path: '/playlists', icon: 'music', iconPos: 'icon-left' },
			{ label: 'Profiles', path: '/profile-management', icon: 'file-text', iconPos: 'icon-left' },
		],
		[
			{ label: 'Uploads', path: '/uploads', icon: 'cloud', iconPos: 'icon-left' },
		],
		[
			{ 
				label: 'Reports',
				path: '/reports',
				icon: 'clipboard',
				iconPos: 'icon-left',
				shouldDisplay: () => store.userGroups.some(ug => ug.name === 'MustardManagement'),
			},
		],
		[
			{label: "Login", path: '/login', icon: "login", iconPos: 'icon-left', shouldDisplay: () => !store.loggedIn},
			{label: "Logout", path: '/logout', icon: "room", iconPos: 'icon-left', shouldDisplay: () => store.loggedIn}
		],
	];
	// % protected region % [Add any logic before displaying page links here] end
	return [
		[
			// % protected region % [Customise top nav section here] on begin
			{ label: 'Clients', path: '/clients', icon: 'person-group', iconPos: 'icon-left' },
			{ label: 'Venues', path: '/venues', icon: 'buildings', iconPos: 'icon-left' },
			// % protected region % [Customise top nav section here] end
		],
		[
			// % protected region % [Customise middle nav section here] on begin
			{ label: 'Playlists', path: '/playlists', icon: 'music', iconPos: 'icon-left' },
			{ label: 'Profiles', path: '/profile-management', icon: 'calender', iconPos: 'icon-left' },
			{ label: 'Uploads', path: '/uploads', icon: 'cloud', iconPos: 'icon-left' },
			{ 
				label: 'Reports',
				path: '/reports',
				icon: 'clipboard',
				iconPos: 'icon-left',
				shouldDisplay: () => store.userGroups.some(ug => ug.name === 'MustardManagement'),
			},
			// % protected region % [Customise middle nav section here] end
		],
		[
			// % protected region % [Customise bottom nav section here] off begin
			{
				label: 'Login',
				path: '/login',
				icon: 'login',
				iconPos: 'icon-left',
				shouldDisplay: () => !store.loggedIn,
			},
			{
				label: 'Logout',
				path: '/logout',
				icon: 'room',
				iconPos: 'icon-left',
				shouldDisplay: () => store.loggedIn,
			},
			// % protected region % [Customise bottom nav section here] end
		],
	];
}
