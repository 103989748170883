/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import AgencyAdminGenresReferenceManyToMany from '../Security/Acl/AgencyAdminGenresReferenceManyToMany';
import AgencyPlaylisterGenresReferenceManyToMany from '../Security/Acl/AgencyPlaylisterGenresReferenceManyToMany';
import VisitorsGenresReferenceManyToMany from '../Security/Acl/VisitorsGenresReferenceManyToMany';
import ApplicationUserGenresReferenceManyToMany from '../Security/Acl/ApplicationUserGenresReferenceManyToMany';
import MustardManagementGenresReferenceManyToMany from '../Security/Acl/MustardManagementGenresReferenceManyToMany';
import MustardAdminGenresReferenceManyToMany from '../Security/Acl/MustardAdminGenresReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITracksGenresAttributes extends IModelAttributes {
	tracksId: string;
	genresId: string;

	tracks: Models.TrackEntity | Models.ITrackEntityAttributes;
	genres: Models.GenreEntity | Models.IGenreEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('TracksGenres')
export default class TracksGenres
	extends Model
	implements ITracksGenresAttributes {
	public static acls: IAcl[] = [
		new AgencyAdminGenresReferenceManyToMany(),
		new AgencyPlaylisterGenresReferenceManyToMany(),
		new VisitorsGenresReferenceManyToMany(),
		new ApplicationUserGenresReferenceManyToMany(),
		new MustardManagementGenresReferenceManyToMany(),
		new MustardAdminGenresReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public tracksId: string;

	@observable
	@attribute()
	public genresId: string;

	@observable
	@attribute({ isReference: true })
	public tracks: Models.TrackEntity;

	@observable
	@attribute({ isReference: true })
	public genres: Models.GenreEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ITracksGenresAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.tracksId) {
				this.tracksId = attributes.tracksId;
			}
			if (attributes.genresId) {
				this.genresId = attributes.genresId;
			}

			if (attributes.tracks) {
				if (attributes.tracks instanceof Models.TrackEntity) {
					this.tracks = attributes.tracks;
					this.tracksId = attributes.tracks.id;
				} else {
					this.tracks = new Models.TrackEntity(attributes.tracks);
					this.tracksId = this.tracks.id;
				}
			} else if (attributes.tracksId !== undefined) {
				this.tracksId = attributes.tracksId;
			}

			if (attributes.genres) {
				if (attributes.genres instanceof Models.GenreEntity) {
					this.genres = attributes.genres;
					this.genresId = attributes.genres.id;
				} else {
					this.genres = new Models.GenreEntity(attributes.genres);
					this.genresId = this.genres.id;
				}
			} else if (attributes.genresId !== undefined) {
				this.genresId = attributes.genresId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
