// @ts-nocheck
import React,
{
	MouseEvent, KeyboardEvent, useState, useEffect,
} from 'react';
import { observer, useLocalStore } from 'mobx-react';
import axios, { AxiosResponse } from 'axios';
import { action, runInAction, toJS } from 'mobx';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { DragDropContext } from 'react-beautiful-dnd';
import { useQueryClient } from 'react-query';
import {
	AlbumEntity,
	ArtistEntity,
	GenreEntity,
	PlaylistEntity,
	TagEntity,
	TrackEntity,
} from '../../../../Models/Entities';
import { SERVER_URL } from '../../../../Constants';
import { store } from '../../../../Models/Store';
import SearchPanel from './SearchPanel';
import PlaylistTabs from './PlaylistTabs/PlaylistTabs';
import { PlaylistEditor } from '../PlaylistEditor';
import {
	getDestinationId,
	getSourceId,
	getTrackId,
	IEditorTrackLists,
	SearchResultObject,
	CustomTabObject,
	DefaultTrackFilterStore,
} from '../../../../Util/PlaylistUtils';
import { InfiniteSearchPanel } from './InfiniteSearchPanel';
import alertToast from '../../../../Util/ToastifyUtils';
import { FilterForm, FilterFormContainerDict } from './FilterModal';
import BankTrackDetailsPanel from '../../Uploads/BankTrackDetailsPanel';

export interface TabCount {
  [key: string]: number;
}

export const PlaylistingTile = observer(() => {
	const queryClient = useQueryClient();

  // stores the search term used in the search panel
	const searchStore = useLocalStore(() => ({
		term: '',
	}));

  // stores the results from the search on the search panel
	const resultStore = useLocalStore(() => ({
		playlists: [] as PlaylistEntity[],
		primaryGenres: [] as GenreEntity[],
		genres: [] as TagEntity[],
		tracks: [] as TrackEntity[],
		artists: [] as ArtistEntity[],
		albums: [] as AlbumEntity[],
	}));

	const [isFetchingResult, setIsFetchingResult] = useState(false);
  // show is for what types of entities are returned on the search panel. all is default, then playlist or genre etc is for infinite scroll.
	const panelStore = useLocalStore(() => ({
		show: 'all',
		isExpanded: false,
		hasExpanded: false,
	}));

	const tabStore = useLocalStore((): {open: CustomTabObject[]} => ({
		open: [],
	}));

	const openTab = (tab: CustomTabObject) => {
		if (tabStore.open.some(openedTab => openedTab.id === tab.id)) {
			return;
		}
		runInAction(() => {
			tabStore.open = [...tabStore.open, tab];
		});
	};

	const closeTab = (id: string) => {
		runInAction(() => {
			tabStore.open = tabStore.open.filter(t => t.id !== id);
		});
	};

  // stores the tracks that have bee selected with multiselect. numSelected is only used to force a rerender after selected has been modified.
	const multiSelectStore = useLocalStore(() => ({
		start: -1,
		end: -1,
		selected: [] as string[],
		numSelected: 0,
		collectionId: '',
	}));

  // stores the current order of the tracks, which is then updated when a data fetch occurs.
	const editorTrackLists = useLocalStore(() => ({
		editor: {} as IEditorTrackLists,
	}));

	const filterCountStore = useLocalStore(() => ({
		showCount: 0,
		showTabCount: {} as TabCount,
	}));

	const orderStore = useLocalStore(() => ({
		orderBy: 'Similarity',
	}));

	const trackFilterStore = useLocalStore(() => ({} as FilterFormContainerDict));

	const selectedTrackStore = useLocalStore(() => ({
		track: new TrackEntity(),
	}));

	const filterStore = useLocalStore(() => ({
		filters: DefaultTrackFilterStore,
		shouldUpdate: 0,
	}));

	const addTracklist = action((playlistId: string, tracks: TrackEntity[]) => {
		editorTrackLists.editor[playlistId] = tracks;
	});

	const updatePanel = action((collectionType: CollectionType) => {
		panelStore.show = collectionType;
	});

	const expandEditor = action(() => {
		panelStore.isExpanded = !panelStore.isExpanded;
		panelStore.hasExpanded = true;
	});

	const showCount = action(
		(shouldShowCount: number, tab: boolean, id: string) => {
			if (tab) {
				filterCountStore.showTabCount[id] = shouldShowCount;
			} else {
				filterCountStore.showCount = shouldShowCount;
			}
		},
	);

	const fetchSearch = AwesomeDebouncePromise(
		async (
			options: { pageNo: number; pageSize: number; filters?: FilterForm } = {
				pageNo: 0,
				pageSize: 5,
			},
		) => {
			setIsFetchingResult(true);
			let queryString = `${SERVER_URL}/api/playlist_search?searchTerm=${encodeURIComponent(
				searchStore.term,
			)}&PageNo=${0}&PageSize=${5}&collect=all`;

			if (options.filters !== undefined) {
				Object.keys(options.filters).forEach(key => {
					if (key === 'bpm') {
						if (
							options.filters?.bpm.min !== -1
              && options.filters?.bpm.max !== -1
						) {
							queryString += `&bpmMin=${options.filters?.bpm.min}&bpmMax=${options.filters?.bpm.max}`;
						}
					} else if (key === 'year') {
						if (
							options.filters?.year.start !== -1
              && options.filters?.year.end !== -1
						) {
							queryString += `&yearStart=${options.filters?.year.start}&yearEnd=${options.filters?.year.end}`;
						}
					} else if (
						options.filters !== undefined
            && options.filters[key] !== undefined
					) {
						if (Array.isArray(options.filters[key])) {
							queryString += `&${key}=${JSON.stringify(options.filters[key])}`;
						} else {
							queryString += `&${key}=${options.filters[key]}`;
						}
					}
				});
			}

      // TODO: replace the top part with the below part
      // const {
      //   bpm, year, primaryGenres, genres, moodFeel, instruments, lyricThemes, vocals, type,
      //   } = options.filters;

      // const queryString = `${SERVER_URL}/api/playlist_search`
      //   + `?PageNo=${0}`
      //   + `&PageSize=${5}`
      //   + `&searchTerm=${encodeURIComponent(searchStore.term)}`
      //   + `&bpmMin=${bpm.min ?? ''}`
      //   + `&bpmMax=${bpm.max ?? ''}`
      //   + `&yearStart=${year.start ?? ''}`
      //   + `&yearEnd=${year.end ?? ''}`
      //   + `&primaryGenres=${JSON.stringify(primaryGenres)}`
      //   + `&genres=${JSON.stringify(genres)}`
      //   + `&moodFeel=${JSON.stringify(moodFeel)}`
      //   + `&instruments=${JSON.stringify(instruments)}`
      //   + `&lyricThemes=${JSON.stringify(lyricThemes)}`
      //   + `&vocals=${JSON.stringify(vocals)}`
      //   + `&type=${JSON.stringify(type)}`;

			await axios
				.get(queryString)
				.then((res: AxiosResponse<SearchResultObject>) => {
					runInAction(() => {
						resultStore.playlists = res.data.playlists.map(
							(p: PlaylistEntity) => new PlaylistEntity(p),
						) as PlaylistEntity[];
						resultStore.primaryGenres = res.data.primaryGenres.map(
							(g: GenreEntity) => new GenreEntity(g),
						) as GenreEntity[];
						resultStore.genres = res.data.genres.map(
							(g: TagEntity) => new TagEntity(g),
						) as TagEntity[];
						resultStore.tracks = res.data.tracks.map(
							(t: TrackEntity) => new TrackEntity(t),
						) as TrackEntity[];
						resultStore.artists = res.data.artists.map(
							(a: ArtistEntity) => new ArtistEntity(a),
						) as ArtistEntity[];
						resultStore.albums = res.data.albums.map(
							(a: AlbumEntity) => new AlbumEntity(a),
						) as AlbumEntity[];
					});
				})
				.catch(err => console.log('error', err));
			setIsFetchingResult(false);
		},
		200,
	);

  // useEffect is required to initialise multiSelectStore
  // It can only be removed when refactoring completed on multi select functionality
	useEffect(() => {}, [multiSelectStore.selected, multiSelectStore.numSelected]);

	const applyFilter = action((filter: FilterForm) => {
		Object.keys(filter).forEach(key => {
			filterStore.filters[key] = filter[key];
		});
		filterStore.shouldUpdate = filterStore.shouldUpdate === 0 ? 1 : 0;
	});

	const clearMultiSelect = action(() => {
		multiSelectStore.start = -1;
		multiSelectStore.end = -1;
		multiSelectStore.selected = [];
		multiSelectStore.numSelected = 0;
		multiSelectStore.collectionId = '';
	});

	const multiSelect = action(
		(
			event: MouseEvent<HTMLInputElement> | KeyboardEvent,
			index = -1,
			start: boolean,
			track: string,
			trackList: string[],
			collectionId: string,
		) => {
      // reset the multiSelectStore if a track is selected in a different collection
			if (collectionId !== multiSelectStore.collectionId) {
				clearMultiSelect();
				multiSelectStore.collectionId = collectionId;
			}

      // allows of adding or removing individual tracks
			if (event.ctrlKey || event.metaKey) {
				if (!multiSelectStore.selected.includes(track)) {
					multiSelectStore.selected.push(track);
					multiSelectStore.numSelected += 1;
				} else {
					multiSelectStore.selected = multiSelectStore.selected.filter(
						x => x !== track,
					);
					multiSelectStore.numSelected -= 1;
				}
				multiSelectStore.start = index;
			}

      // add tracks in bulk
			if (event.shiftKey) {
				multiSelectStore.end = index;
				if (multiSelectStore.start < multiSelectStore.end) {
					const tracksToAdd = trackList
						.slice(multiSelectStore.start, multiSelectStore.end + 1)
						.filter(t => !multiSelectStore.selected.includes(t));
					multiSelectStore.selected.push(...tracksToAdd);
					multiSelectStore.numSelected += tracksToAdd.length;
				} else {
					const tracksToAdd = trackList
						.slice(multiSelectStore.end, multiSelectStore.start + 1)
						.filter(t => !multiSelectStore.selected.includes(t));
					multiSelectStore.selected.push(...tracksToAdd);
					multiSelectStore.numSelected += tracksToAdd.length;
				}
				multiSelectStore.start = index;
			}

      // if neither shift or ctrl, reset multiselected.
			if (!event.shiftKey && !(event.ctrlKey || event.metaKey)) {
				multiSelectStore.start = index;
				multiSelectStore.end = -1;
				multiSelectStore.selected = [track];

        // Set numSelected to 0 first to ensure a render occurs
        // when numSelected is already 1
				multiSelectStore.numSelected = 0;
				multiSelectStore.numSelected = 1;
			}
		},
	);

	const onDragEnd = action((result: any) => {
		if (
			result.destination === null
      || result.destination.droppableId.includes('source')
		) {
			return;
		}

		const sourceId = getSourceId(result.source.droppableId);
		const destinationId = getDestinationId(result.destination.droppableId);
		const trackId = getTrackId(result.draggableId);

		if (sourceId === destinationId) {
			return;
		}
    // otherwise it's adding a track
		axios
			.post(`${SERVER_URL}/api/playlist_search/add_tracks`, {
				trackIds: multiSelectStore.selected.length
					? multiSelectStore.selected
					: [trackId],
				playlistId: destinationId,
			})
			.then(() => {
				alertToast(`Successfully added ${multiSelectStore.selected.length} track(s)`, 'success');
				queryClient.refetchQueries(
					['playlist_contents', destinationId, orderStore.orderBy],
				);
				queryClient.refetchQueries(`collection_contents-${destinationId}`);
				queryClient.refetchQueries(['summary', destinationId]);
			})
			.catch(err => {
				alertToast('Failed to add tracks', 'error');
			});
	});

	const getFilterCount = (filters: FilterForm): number => {
		let count = 0;

		if (filters.genres.length > 0) {
			count += 1;
		}
		if (filters.vocals.length > 0) {
			count += 1;
		}
		if (filters.instruments.length > 0) {
			count += 1;
		}
		if (filters.year.start && filters.year.end) {
			count += 1;
		}
		if (filters.bpm.min && filters.bpm.max) {
			count += 1;
		}
		if (filters.lyricThemes.length > 0) {
			count += 1;
		}
		if (filters.moodFeel.length > 0) {
			count += 1;
		}
		if (filters.type.length > 0) {
			count += 1;
		}

		return count;
	};

	const updateSelectedTrackStore = (t: TrackEntity) => runInAction(() => {
		selectedTrackStore.track = t;
	});

	const clearSelectedTrackStore = () => {
		updateSelectedTrackStore(new TrackEntity());
	};

	const customTrackClickEvent = (
		t: TrackEntity,
		e: MouseEvent<HTMLInputElement>,
	) => {
    // double clicks to update state when selectedTrackStore.id doesn't exists
		if (!selectedTrackStore.track.id && e.detail === 2) {
			updateSelectedTrackStore(t);
		} else if (selectedTrackStore.track.id === t.id && e.detail === 2) {
      // double click to clear state if the same track being passed in
			clearSelectedTrackStore();
		} else if (!!selectedTrackStore.track.id && e.detail === 1) {
      // single click to update state when selectedTrackStore.id exists
			updateSelectedTrackStore(t);
		}
	};

	return (
		<div
			className={`playlisting-container ${
				store.hasBackendAccess ? 'admin' : ''
			}`}
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<div className={`tab-group${panelStore.isExpanded ? ' expanded' : ''}`}>
					{panelStore.show === 'all' ? (
						<SearchPanel
							fetchSearch={fetchSearch}
							searchStore={searchStore}
							resultStore={resultStore}
							openTab={openTab}
							panelStore={panelStore}
							multiSelect={multiSelect}
							multiSelectStore={multiSelectStore}
							applyFilter={applyFilter}
							filterStore={filterStore.filters}
							showCount={showCount}
							getFilterCount={getFilterCount}
							filterCountStore={filterCountStore}
							customTrackClickEvent={customTrackClickEvent}
							isFetchingResult={isFetchingResult}
						/>
					) : (
						<InfiniteSearchPanel
							openTab={openTab}
							updatePanel={updatePanel}
							searchStore={searchStore}
							panelEntity={panelStore.show}
							isExpanded={panelStore.isExpanded}
							multiSelect={multiSelect}
							multiSelectStore={multiSelectStore}
							clearMultiSelect={clearMultiSelect}
							applyFilter={applyFilter}
							filterStore={filterStore}
							showCount={showCount}
							getFilterCount={getFilterCount}
							filterCountStore={filterCountStore}
							customTrackClickEvent={customTrackClickEvent}
						/>
					)}
					<PlaylistTabs
						tabStore={tabStore}
						closeTab={closeTab}
						multiSelect={multiSelect}
						multiSelectStore={multiSelectStore}
						clearMultiSelect={clearMultiSelect}
						isExpanded={panelStore.isExpanded}
						hasExpanded={panelStore.hasExpanded}
						showCount={showCount}
						getFilterCount={getFilterCount}
						filterCountStore={filterCountStore}
						trackFilterStore={trackFilterStore}
						customTrackClickEvent={customTrackClickEvent}
					/>
					{!!selectedTrackStore.track.id && (
						<BankTrackDetailsPanel
							track={selectedTrackStore.track}
							closePanel={clearSelectedTrackStore}
						/>
					)}
				</div>
				<PlaylistEditor
					isExpanded={panelStore.isExpanded}
					hasExpanded={panelStore.hasExpanded}
					expandEditor={expandEditor}
					multiSelect={multiSelect}
					multiSelectStore={multiSelectStore}
					clearMultiSelect={clearMultiSelect}
					editorTrackLists={editorTrackLists}
					addTracklist={addTracklist}
					tabStore={tabStore}
				/>
			</DragDropContext>
		</div>
	);
});
