import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import Axios from 'axios';
import {
	Button, Colors, Display, Sizes, 
} from '../../../Components/Button/Button';
import { TextField } from '../../../Components/TextBox/TextBox';
import { AgencyEntity, ProfileEntity } from '../../../../Models/Entities';
import { store } from '../../../../Models/Store';
import alert from '../../../../Util/ToastifyUtils';
import { Combobox } from '../../../Components/Combobox/Combobox';
import { SERVER_URL } from '../../../../Constants';

interface IProfileForm {
	name: string;
	errors: {
		agencyOwnerId?: string;
		name?: string;
	};
}

const defaultProfileForm: IProfileForm = {
	name: '',
	errors: {
		agencyOwnerId: '',
		name: '',
	},
};

const errorMessages = {
	name: 'Profile must have a name.',
	agency: 'An agency is required',
};

const CreateProfileModal = (): JSX.Element => {
	const profileForm = useLocalStore(() => (
		defaultProfileForm
	));

	const agencyStore = useLocalStore(() => ({
		agencies: [] as AgencyEntity[],
		agency: '',
	}));

	useEffect(() => {
		if (store.isMustardAdminOrMustardManager) {
			Axios.get(`${SERVER_URL}/api/entity/AgencyEntity/GetWithMustardAdminId`)
				.then(res => {
					runInAction(() => agencyStore.agencies = res.data.map((a: AgencyEntity) => new AgencyEntity(a)));
				})
				.catch(err => console.log('err'));
		}
	}, []);

	const validateField = (): void => {
		if (profileForm.name.length === 0) {
			runInAction(() => profileForm.errors.name = errorMessages.name);
		} else {
			runInAction(() => delete profileForm.errors.name);
		}
	};

	const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
		e.preventDefault();

		validateField();

		runInAction(() => {
			if (store.isMustardAdminOrMustardManager) {
				if (agencyStore.agency === '') {
					profileForm.errors.agencyOwnerId = errorMessages.agency;
				} else {
					delete profileForm.errors.agencyOwnerId;
				}
			} else {
				delete profileForm.errors.agencyOwnerId;
			}
		});
		
		if (Object.keys(profileForm.errors).length === 0) {
			const agency = await store.getLoggedInAgency();

			const newProfile = new ProfileEntity();
			newProfile.name = profileForm.name;
			newProfile.agencyOwnerId = agency.id;

			if (store.isMustardAdminOrMustardManager) {
				if (agencyStore.agency !== '') {
					newProfile.agencyOwnerId = agencyStore.agency;
				}
			} else {
				newProfile.agencyOwnerId = agency.id;
			}

			newProfile.save().then(() => {
				alert('Created Profile', 'success');
				store.modal.hide();
				store.routerHistory.push(`/profile-management/edit/${newProfile.id}`);
			}).catch(() => {
				alert('Failed creating profile', 'error');
			});
		}
	};

	return (
		<div className="venue-modal-Container">
			<h4>Create Profile</h4>
			
			<form onSubmit={onSubmit}>
				{store.isMustardAdminOrMustardManager && (
					<Combobox
						model={agencyStore}
						modelProperty="agency"
						label="Agency"
						className="agency"
						options={agencyStore.agencies
							.map(a => ({ display: a.name, value: a.id }))
							.sort((a, b) => a.display.localeCompare(b.display))}
						isRequired
						errors={profileForm.errors.agencyOwnerId}
					/>
				)}
				<TextField
					model={profileForm}
					modelProperty="name"
					label="Profile Name"
					isRequired
					errors={profileForm.errors.name}
				/>
				<div className="form-controls">
					<Button 
						type="button" 
						colors={Colors.Primary} 
						display={Display.Outline} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'cancel' }}
						onClick={(): void => store.modal.hide()}
					>
						Cancel
					</Button>
					
					<Button 
						type="submit" 
						colors={Colors.Primary} 
						display={Display.Solid} 
						sizes={Sizes.Medium} 
						buttonProps={{ id: 'submit' }}
					>
						Save
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(CreateProfileModal);
