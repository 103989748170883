import moment from 'moment';

export const formatDateTime = (dateTime: string | Date) => {
	return `${moment(dateTime).format('DD/MM/YYYY')} at ${moment(dateTime).format('hh:mm a')}`;
};

export const renderScheduleDateTime = (dateTime: string | null) => {
	if (!dateTime) {
		return 'Profile not published';
	}
	const currentTime = moment();
	const scheduledDateTime = moment(dateTime).utc();
	const scheduledDateTimeString = `${scheduledDateTime.format(
		'DD/MM/YYYY',
	)} at ${scheduledDateTime.format('hh:mm a')}`;

	if (!scheduledDateTime.isValid()) {
		return 'Profile not published';
	}
	if (currentTime.isBefore(scheduledDateTime)) {
		return `Scheduled for ${scheduledDateTimeString}`;
	}
	return `Last published on ${scheduledDateTimeString}`;
};
