/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import AgencyAdminPlaylistTagsReferenceManyToMany from '../Security/Acl/AgencyAdminPlaylistTagsReferenceManyToMany';
import AgencyPlaylisterPlaylistTagsReferenceManyToMany from '../Security/Acl/AgencyPlaylisterPlaylistTagsReferenceManyToMany';
import VisitorsPlaylistTagsReferenceManyToMany from '../Security/Acl/VisitorsPlaylistTagsReferenceManyToMany';
import ApplicationUserPlaylistTagsReferenceManyToMany from '../Security/Acl/ApplicationUserPlaylistTagsReferenceManyToMany';
import MustardManagementPlaylistTagsReferenceManyToMany from '../Security/Acl/MustardManagementPlaylistTagsReferenceManyToMany';
import MustardAdminPlaylistTagsReferenceManyToMany from '../Security/Acl/MustardAdminPlaylistTagsReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ITagsPlaylistTagsAttributes extends IModelAttributes {
	tagsId: string;
	playlistTagsId: string;

	tags: Models.TagEntity | Models.ITagEntityAttributes;
	playlistTags: Models.PlaylistEntity | Models.IPlaylistEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('TagsPlaylistTags')
export default class TagsPlaylistTags
	extends Model
	implements ITagsPlaylistTagsAttributes {
	public static acls: IAcl[] = [
		new AgencyAdminPlaylistTagsReferenceManyToMany(),
		new AgencyPlaylisterPlaylistTagsReferenceManyToMany(),
		new VisitorsPlaylistTagsReferenceManyToMany(),
		new ApplicationUserPlaylistTagsReferenceManyToMany(),
		new MustardManagementPlaylistTagsReferenceManyToMany(),
		new MustardAdminPlaylistTagsReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public tagsId: string;

	@observable
	@attribute()
	public playlistTagsId: string;

	@observable
	@attribute({ isReference: true })
	public tags: Models.TagEntity;

	@observable
	@attribute({ isReference: true })
	public playlistTags: Models.PlaylistEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<ITagsPlaylistTagsAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.tagsId) {
				this.tagsId = attributes.tagsId;
			}
			if (attributes.playlistTagsId) {
				this.playlistTagsId = attributes.playlistTagsId;
			}

			if (attributes.tags) {
				if (attributes.tags instanceof Models.TagEntity) {
					this.tags = attributes.tags;
					this.tagsId = attributes.tags.id;
				} else {
					this.tags = new Models.TagEntity(attributes.tags);
					this.tagsId = this.tags.id;
				}
			} else if (attributes.tagsId !== undefined) {
				this.tagsId = attributes.tagsId;
			}

			if (attributes.playlistTags) {
				if (attributes.playlistTags instanceof Models.PlaylistEntity) {
					this.playlistTags = attributes.playlistTags;
					this.playlistTagsId = attributes.playlistTags.id;
				} else {
					this.playlistTags = new Models.PlaylistEntity(attributes.playlistTags);
					this.playlistTagsId = this.playlistTags.id;
				}
			} else if (attributes.playlistTagsId !== undefined) {
				this.playlistTagsId = attributes.playlistTagsId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
