import React, { useEffect } from 'react';
import {
	autorun, runInAction,
} from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import {
	Button, Colors, Display, Sizes,
} from '../../../Components/Button/Button';
import { TextField } from '../../../Components/TextBox/TextBox';
import { store } from '../../../../Models/Store';
import alert from '../../../../Util/ToastifyUtils';
import { MoodEntity, ProfileEntity } from '../../../../Models/Entities';
import {
	IMoodForm, moodColours, validateMoodFormFields,
} from './IMoodForm';

interface CreateMoodProps {
	profileId: string;
	profile: ProfileEntity;
}

const defaultMoodForm: IMoodForm = {
	name: '',
	colour: '',
	injectable: false,
	injectableFrequencyEntitys: [],
	errors: {},
};

const CreateMoodModal = (props: CreateMoodProps): JSX.Element => {
	const moodForm = useLocalStore(() => (
		defaultMoodForm
	));

	const queryClient = useQueryClient();

	// Validate fields when they change
	useEffect(() => autorun(() => {
		if (JSON.stringify(defaultMoodForm) !== JSON.stringify(moodForm)) {
			validateMoodFormFields(moodForm);
		}
	}));
	const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
		e.preventDefault();

		validateMoodFormFields(moodForm);

		if (Object.keys(moodForm.errors).length === 0) {
			const { profileId, profile } = props;

			// Create Mood
			const newMood = new MoodEntity();
			newMood.agencyOwnerId = profile.agencyOwnerId;
			newMood.name = moodForm.name;
			newMood.profileId = profileId;
			newMood.allowExplicits = false;
			newMood.draftVersion = uuidv4();
			newMood.colour = moodColours[Math.floor(Math.random() * 18)];
			newMood.injectable = moodForm.injectable;
			newMood.injectableFrequenciess = moodForm.injectableFrequencyEntitys;

			newMood.saveWithId({ moodVersionss: {}, injectableFrequenciess: {} })
				.then(() => {
					alert('Created Mood', 'success');
					store.modal.hide();
					store.routerHistory.push('/');
					store.routerHistory.push(`/profile-management/edit/${profileId}`);
				})
				.then(() => queryClient.refetchQueries('profile'))
				.catch(() => {
					alert('Failed creating mood', 'error');
				});
		}
	};

	return (
		<div className="mood-modal-Container">
			<h4>Add Mood</h4>

			<form onSubmit={onSubmit}>
				<div className="mood-modal-row top-row">
					<TextField
						className="row-element"
						model={moodForm}
						modelProperty="name"
						label="Mood Name"
						isRequired
						errors={moodForm.errors.name}
					/>
					<div className="row-element">
						<p>Injectable Mood</p>
						<label className="switch" htmlFor="injectable-toggle">
							<input
								type="checkbox"
								onChange={
									(event: React.ChangeEvent<HTMLInputElement>) => runInAction(() => {
										moodForm.injectable = event.target.checked;
									})
								}
								checked={moodForm.injectable}
								aria-label="injectable toggle"
								id="injectable-toggle"
							/>
							<span
								className="slider round"
							/>
						</label>
					</div>
				</div>
				<div className="form-controls">
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={(): void => store.modal.hide()}
					>
						Cancel
					</Button>

					<Button
						type="submit"
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'submit' }}
					>
						Save
					</Button>
				</div>
			</form>
		</div>
	);
};

export default observer(CreateMoodModal);
