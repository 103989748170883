import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { Colors, Display } from '../../Components/Button/Button';
import { store } from '../../../Models/Store';

interface IDeleteBankConfirmationProps {
	bankName: string;
	deleteBank: () => void;
}

const DeleteBankConfirmation = (props: IDeleteBankConfirmationProps) => {
	const { bankName, deleteBank } = props;

	return (
		<div className="delete-confirmation-modal">
			<h4>
				{`Are you sure you want to delete ${bankName}?`}
			</h4>
			<div className="form-controls">
				<Button
					className="cancel"
					display={Display.Outline}
					colors={Colors.White}
					onClick={() => store.modal.hide()}
				>
					Cancel
				</Button>
				<Button
					className="submit"
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={() => {
						deleteBank();
						store.modal.hide();
					}}
				>
					Delete Bank
				</Button>
			</div>
		</div>
	);
};

export default observer(DeleteBankConfirmation);
