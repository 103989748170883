/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import AgencyPlaylisterAgencyAdminEntity from 'Models/Security/Acl/AgencyPlaylisterAgencyAdminEntity';
import ApplicationUserAgencyAdminEntity from 'Models/Security/Acl/ApplicationUserAgencyAdminEntity';
import VisitorsAgencyAdminEntity from 'Models/Security/Acl/VisitorsAgencyAdminEntity';
import MustardAdminAgencyAdminEntity from 'Models/Security/Acl/MustardAdminAgencyAdminEntity';
import AgencyAdminAgencyAdminEntity from 'Models/Security/Acl/AgencyAdminAgencyAdminEntity';
import MustardManagementAgencyAdminEntity from 'Models/Security/Acl/MustardManagementAgencyAdminEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { SERVER_URL } from '../../Constants';
import Axios from 'axios';
import { store } from '../Store';
import { AgencyEntity } from 'Models/Entities';
// % protected region % [Add any further imports here] end

export interface IAgencyAdminEntityAttributes extends IModelAttributes {
	email: string;
	userName: string;
	twoFactorEnabled: boolean;
	givenName: string;
	familyName: string;
	uploader: boolean;
	setFades: boolean;
	publisher: boolean;

	agencyId: string;
	agency: Models.AgencyEntity | Models.IAgencyEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('AgencyAdminEntity', 'Agency Admin')
// % protected region % [Customise your entity metadata here] end
export default class AgencyAdminEntity extends Model
	implements IAgencyAdminEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new AgencyPlaylisterAgencyAdminEntity(),
		new ApplicationUserAgencyAdminEntity(),
		new VisitorsAgencyAdminEntity(),
		new MustardAdminAgencyAdminEntity(),
		new AgencyAdminAgencyAdminEntity(),
		new MustardManagementAgencyAdminEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		'userName',
		'twoFactorEnabled',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	/**
	 * The default order by field when the collection is loaded .
	 */
	public get orderByField(): IOrderByCondition<Model> | undefined {
		// % protected region % [Modify the order by field here] off begin
		return {
			path: 'givenName',
			descending: false,
		};
		// % protected region % [Modify the order by field here] end
	}

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 10,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	@observable
	@attribute()
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] off begin
	@CRUD({
		name: 'TwoFactorEnabled',
		displayType: 'displayfield',
		order: 20,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public twoFactorEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'UserName'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'UserName',
		displayType: 'hidden',
	})
	public userName: string;
	// % protected region % [Modify props to the crud options here for attribute 'UserName'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] off begin
	@Validators.Length(12, 128)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] off begin
	@Validators.Custom('Password Match', (e: string, target: AgencyAdminEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 40,
		createFieldType: 'password',
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Given Name'] off begin
	@Validators.Required()
	@observable
	@attribute<AgencyAdminEntity, string>()
	@CRUD({
		name: 'Given Name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public givenName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Given Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Family Name'] off begin
	@Validators.Required()
	@observable
	@attribute<AgencyAdminEntity, string>()
	@CRUD({
		name: 'Family Name',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public familyName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Family Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Uploader'] off begin
	@observable
	@attribute<AgencyAdminEntity, boolean>()
	@CRUD({
		name: 'Uploader',
		displayType: 'checkbox',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public uploader: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Uploader'] end

	// % protected region % [Modify props to the crud options here for attribute 'Set Fades'] off begin
	@observable
	@attribute<AgencyAdminEntity, boolean>()
	@CRUD({
		name: 'Set Fades',
		displayType: 'checkbox',
		order: 80,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public setFades: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Set Fades'] end

	// % protected region % [Modify props to the crud options here for attribute 'Publisher'] off begin
	@observable
	@attribute<AgencyAdminEntity, boolean>()
	@CRUD({
		name: 'Publisher',
		displayType: 'checkbox',
		order: 90,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public publisher: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Publisher'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Agency'] off begin
		name: 'Agency',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.AgencyEntity,
		// % protected region % [Modify props to the crud options here for reference 'Agency'] end
	})
	public agencyId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public agency: Models.AgencyEntity;

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IAgencyAdminEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IAgencyAdminEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.userName !== undefined) {
				this.userName = attributes.userName;
			}
			if (attributes.twoFactorEnabled !== undefined) {
				this.twoFactorEnabled = attributes.twoFactorEnabled;
			}
			if (attributes.givenName !== undefined) {
				this.givenName = attributes.givenName;
			}
			if (attributes.familyName !== undefined) {
				this.familyName = attributes.familyName;
			}
			if (attributes.uploader !== undefined) {
				this.uploader = attributes.uploader;
			}
			if (attributes.setFades !== undefined) {
				this.setFades = attributes.setFades;
			}
			if (attributes.publisher !== undefined) {
				this.publisher = attributes.publisher;
			}
			if (attributes.agencyId !== undefined) {
				this.agencyId = attributes.agencyId;
			}
			if (attributes.agency !== undefined) {
				if (attributes.agency === null) {
					this.agency = attributes.agency;
				} else if (attributes.agency instanceof Models.AgencyEntity) {
					this.agency = attributes.agency;
					this.agencyId = attributes.agency.id;
				} else {
					this.agency = new Models.AgencyEntity(attributes.agency);
					this.agencyId = this.agency.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		agency {
			${Models.AgencyEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
		};

		if (formMode === 'create') {
			relationPath.password = {};

			if (this.password !== this._confirmPassword) {
				throw Error('Password fields do not match');
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public async getAgency() {
		let fetchedAgency = new AgencyEntity();
		await Axios.get(`${SERVER_URL}/api/entity/AgencyEntity/GetWithAgencyAdminId/${store.userId}`)
			.then((res: any) => {
				fetchedAgency = new AgencyEntity(res.data);
			})
			.catch(err => console.log('Could not retrieve agency', err));
		return fetchedAgency;
	}
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(AgencyAdminEntity.prototype, 'created');
CRUD(modifiedAttr)(AgencyAdminEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
