/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { action, runInAction } from 'mobx';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import {
	ZoneEntity,
	AgencyEntity,
	VenueEntity,
} from '../../../../Models/Entities';
import { TextField } from '../../../Components/TextBox/TextBox';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../../Components/Button/Button';
import { store } from '../../../../Models/Store';
import alert from '../../../../Util/ToastifyUtils';
import alertToast from '../../../../Util/ToastifyUtils';
import { SERVER_URL } from '../../../../Constants';
import FileUpload from '../../../Components/FileUpload/FileUpload';
import Modal from '../../../Components/Modal/Modal';
import ZoneSyncSection from './ZoneTile/ZoneSyncSection';

interface IZoneModalProps {
	refetchVenue: () => void;
	agencyId: string;
	venueId: string;
	agency: AgencyEntity;
	zone?: ZoneEntity;
	venue: VenueEntity;
}

interface IConfirmRefreshProductKeyModalProps {
	refreshProductKey: () => void,
	isOpen: boolean,
	label: string,
	closeRefreshProductKeyModal: () => void;
}

interface IConfirmDeleteZoneModalProps {
	deleteZone: () => void,
	isOpen: boolean;
	label: string;
	closeConfirmDeleteZoneModal: () => void;
}

interface IConfirmInactiveModalProps {
	inactivateZone: () => void,
	isOpen: boolean;
	label: string;
	closeConfirmInactiveZoneModal: () => void;
}

interface IZoneFormError {
	name: string;
	description: string;
	fadeInDuration: string;
	fadeOutDuration: string;
	fadeVolume: string;
	latitude: string,
	longitude: string,
	logo: string,
}

interface IZoneForm {
	logo: Blob | null;
	logoId: string;
	logoFileName: string;
	logoUrl: string;
	id: string;
	name: string;
	description: string;
	active: boolean;
	isStreaming: boolean;
	productKey: string;
	fadeInDuration: number | null;
	fadeOutDuration: number | null;
	fadeVolume: number;
	isFadeOverridden: boolean;
	latitude: number | null,
	longitude: number | null,
	errors: IZoneFormError;
}

const defaultZoneForm = (agency: AgencyEntity, venue:VenueEntity): IZoneForm => ({
	logo: null,
	logoId: '',
	logoFileName: '',
	logoUrl: '',
	id: '',
	name: '',
	description: '',
	active: false,
	isStreaming: false,
	productKey: uuidv4(),
	fadeInDuration: agency.fadeInDuration || 2.5,
	fadeOutDuration: agency.fadeOutDuration || 2.5,
	fadeVolume: agency.fadeVolume || 0,
	isFadeOverridden: false,
	latitude: venue.latitude || null,
	longitude: venue.longitude || null,
	errors: {} as IZoneFormError,
});

const errorMessages = {
	name: 'Zone must have a name.',
	description: 'Zone must have a description.',
	fadeDuration: 'Fade duration must be greater than or equal to 0.',
	fadeVolume: 'Fade volume must be between 0% and 100%.',
	latitude: 'Latitude must be between -90 and 90',
	longitude: 'Longitude must be between -190 and 180',
	logo: 'A logo must be uploaded',
};

const ConfirmRefreshProductKeyModal = (props: IConfirmRefreshProductKeyModalProps): JSX.Element => {
	const {
		isOpen,
		refreshProductKey,
		label,
		closeRefreshProductKeyModal,
	} = props;

	return (
		<Modal isOpen={isOpen} label={label}>
			<div className="product-key-refresh-confirmation-modal">
				<div key="header" className="modal__header">
					<h2 key="header-item" className="modal__title">
						Are you sure you want to refresh the product key?
					</h2>
				</div>
				<br />
				<div key="message" className="modal__message">
					The current zone will be disabled and all data on the device will be
					lost!
				</div>
				<div key="actions" className="modal__actions">
					<Button
						className="modal--cancel"
						key="cancel"
						onClick={closeRefreshProductKeyModal}
						display={Display.Outline}
					>
						Cancel
					</Button>
					<Button
						className="modal--confirm"
						key="confirm"
						onClick={() => {
							refreshProductKey();
							closeRefreshProductKeyModal();
						}}
						display={Display.Solid}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Modal>
	);
};

const ConfirmDeleteZoneModal = (
	props: IConfirmDeleteZoneModalProps,
): JSX.Element => {
	const {
		isOpen, deleteZone, label, closeConfirmDeleteZoneModal,
	} = props;

	return (
		<Modal isOpen={isOpen} label={label}>
			<div className="delete-zone-confirmation-modal">
				<div key="header" className="modal__header">
					<h2 key="header-item" className="modal__title">
						Are you sure you want to archive this zone?
					</h2>
				</div>
				<br />
				<div key="message" className="modal__message">
					The current zone will be archived and all data on the device will be
					lost!
				</div>
				<div key="actions" className="modal__actions">
					<Button
						className="modal--cancel"
						key="cancel"
						onClick={closeConfirmDeleteZoneModal}
						display={Display.Outline}
					>
						Cancel
					</Button>
					<Button
						className="modal--confirm"
						key="confirm"
						onClick={() => {
							deleteZone();
							closeConfirmDeleteZoneModal();
						}}
						display={Display.Solid}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Modal>
	);
};

const ConfirmInactiveModal = (
	props: IConfirmInactiveModalProps,
): JSX.Element => {
	const {
		isOpen, inactivateZone, label, closeConfirmInactiveZoneModal,
	} = props;

	return (
		<Modal isOpen={isOpen} label={label}>
			<div className="inactive-zone-confirmation-modal">
				<div key="header" className="modal__header">
					<h2 key="header-item" className="modal__title">
						Are you sure you want to deactivate this zone?
					</h2>
				</div>
				<br />
				<div key="message" className="modal__message">
					The current zone will be deactivated!
				</div>
				<div key="actions" className="modal__actions">
					<Button
						className="modal--cancel"
						key="cancel"
						onClick={closeConfirmInactiveZoneModal}
						display={Display.Outline}
					>
						Cancel
					</Button>
					<Button
						className="modal--confirm"
						key="confirm"
						onClick={() => {
							inactivateZone();
							closeConfirmInactiveZoneModal();
						}}
						display={Display.Solid}
					>
						Confirm
					</Button>
				</div>
			</div>
		</Modal>
	);
};

const ZoneModal = (props: IZoneModalProps): JSX.Element => {
	const {
		zone, refetchVenue, agencyId, agency, venueId, venue,
	} = props;

	const [refreshModalOpen, setRefreshModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [inactiveModalOpen, setInactiveModalOpen] = useState(false);

	const openRefreshProductKeyModal = () => setRefreshModalOpen(true);
	const closeRefreshProductKeyModal = () => setRefreshModalOpen(false);

	const openDeleteModal = () => setDeleteModalOpen(true);
	const closeDeleteModal = () => setDeleteModalOpen(false);

	const openInactiveModal = () => setInactiveModalOpen(true);
	const closeInactiveModal = () => setInactiveModalOpen(false);

	const submitStatus = useLocalStore(() => ({ canSubmit: true }));

	const zoneForm = useLocalStore(() => zone?.name
		? {
			logo: zone.logo,
			logoId: zone.logoId,
			logoFileName: '',
			logoUrl: '',
			id: zone.id,
			name: zone.name,
			description: zone.description,
			active: zone.isActive,
			isStreaming: zone.isStreaming,
			productKey: zone.productKey,
			fadeInDuration: zone.isFadeOverridden
				? zone.fadeInDuration
				: agency.fadeInDuration || 2.5,
			fadeOutDuration: zone.isFadeOverridden
				? zone.fadeOutDuration
				: agency.fadeOutDuration || 2.5,
			fadeVolume: zone.isFadeOverridden
				? zone.fadeVolume
				: agency.fadeVolume || 0,
			isFadeOverridden: zone.isFadeOverridden,
			latitude: zone.latitude,
			longitude: zone.longitude,
			errors: {
				name: '',
				description: '',
				fadeInDuration: '',
				fadeOutDuration: '',
				fadeVolume: '',
				latitude: '',
				longitude: '',
				logo: '',
			},
		}
		: defaultZoneForm(agency, venue));

	const validateField = action((field: string) => {
		if (field === 'name') {
			if (zoneForm.name === '' || zoneForm.name === undefined) {
				zoneForm.errors.name = errorMessages.name;
			} else {
				zoneForm.errors.name = '';
			}
		}

		if (field === 'description') {
			if (zoneForm.description === '' || zoneForm.description === undefined) {
				zoneForm.errors.description = errorMessages.description;
			} else {
				zoneForm.errors.description = '';
			}
		}

		if (field === 'fadeInDuration') {
			if (
				zoneForm.fadeInDuration === null
        || zoneForm.fadeInDuration < 0
        || zoneForm.fadeInDuration === undefined
			) {
				zoneForm.errors.fadeInDuration = errorMessages.fadeDuration;
			} else {
				zoneForm.errors.fadeInDuration = '';
			}
		}

		if (field === 'fadeOutDuration') {
			if (
				zoneForm.fadeOutDuration === null
        || zoneForm.fadeOutDuration < 0
        || zoneForm.fadeOutDuration === undefined
			) {
				zoneForm.errors.fadeOutDuration = errorMessages.fadeDuration;
			} else {
				zoneForm.errors.fadeOutDuration = '';
			}
		}

		if (field === 'fadeVolume') {
			if (
				zoneForm.fadeVolume < 0
        || zoneForm.fadeVolume > 100
        || zoneForm.fadeVolume === undefined
			) {
				zoneForm.errors.fadeVolume = errorMessages.fadeVolume;
			} else {
				zoneForm.errors.fadeVolume = '';
			}
		}

		if (field === 'latitude') {
			if (Number.isNaN(Number(zoneForm.latitude))) {
				zoneForm.errors.latitude = 'Latitude must be a number';
				return;
			}

			let latitude = 0;
			if (zoneForm.latitude !== 0) {
				latitude = zoneForm.latitude ?? -91;
			}

			if (latitude < -90 || latitude > 90) {
				zoneForm.errors.latitude = errorMessages.latitude;
				return;
			}

			zoneForm.errors.latitude = '';
		}

		if (field === 'longitude') {
			if (Number.isNaN(Number(zoneForm.longitude))) {
				zoneForm.errors.longitude = 'Longitude must be a number';
				return;
			}

			let longitude = 0;
			if (zoneForm.longitude !== 0) {
				longitude = zoneForm.longitude ?? -181;
			}

			if (longitude < -180 || longitude > 180) {
				zoneForm.errors.longitude = errorMessages.longitude;
				return;
			}

			zoneForm.errors.longitude = '';
		}

		if (field === 'logo') {
			if (
				(zoneForm.logo === null || zoneForm.logo === undefined)
        && (zoneForm.logoId === null
          || zoneForm.logoId === undefined
          || zoneForm.logoId === '')
			) {
				zoneForm.errors.logo = errorMessages.logo;
				return;
			}

			zoneForm.errors.logo = '';
		}
	});

	const queryClient = useQueryClient();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();
		setIsSubmitting(true);

		runInAction(() => { submitStatus.canSubmit = false; });
		Object.keys(zoneForm).forEach(field => {
			validateField(field);
		});

		if (Object.values(zoneForm.errors).filter(v => v !== '').length === 0) {
			runInAction(() => {
				if (zone !== null && zone !== undefined) {
					if (
						zone.name !== zoneForm.name
						|| zone.description !== zoneForm.description
						|| zone.isActive !== zoneForm.active
						|| zone.isStreaming !== zoneForm.isStreaming
						|| zone.fadeInDuration !== zoneForm.fadeInDuration
						|| zone.fadeOutDuration !== zoneForm.fadeOutDuration
						|| zone.fadeVolume !== zoneForm.fadeVolume
						|| zone.isFadeOverridden !== zoneForm.isFadeOverridden
						|| zone.latitude !== zoneForm.latitude
						|| zone.longitude !== zoneForm.longitude
						|| zone.logo !== zoneForm.logo
					) {
						zone.name = zoneForm.name;
						zone.description = zoneForm.description;
						zone.isActive = zoneForm.active;
						zone.isStreaming = zoneForm.isStreaming;
						zone.productKey = zoneForm.productKey;
						zone.fadeVolume = zoneForm.fadeVolume;
						zone.isFadeOverridden = zoneForm.isFadeOverridden;
						if (zoneForm.fadeInDuration) { zone.fadeInDuration = zoneForm.fadeInDuration; }
						if (zoneForm.fadeOutDuration) { zone.fadeOutDuration = zoneForm.fadeOutDuration; }
						if (zoneForm.latitude) { zone.latitude = zoneForm.latitude; }
						if (zoneForm.longitude) { zone.longitude = zoneForm.longitude; }

						zone
							.save()
							.then(() => {
								if (zoneForm.logo && zoneForm.logo !== zone.logo) {
									runInAction(async () => {
										if (zoneForm.logo && zoneForm.logo !== zone.logo) {
											zone.logo = zoneForm.logo;
											await zone
												.save(undefined, {
													contentType: 'multipart/form-data',
												})
												.then(() => {
													alert('Updated Zone', 'success');
													if (refetchVenue) {
														refetchVenue();
													}
													store.modal.hide();
												})
												.catch(err => {
													alert('Failed updating zone', 'error');
												});
										} else {
											alert('Updated Zone', 'success');
											if (refetchVenue) {
												refetchVenue();
											}
											store.modal.hide();
										}
									});
								} else {
									refetchVenue();
									store.modal.hide();
								}
							})
							.catch(() => alert('Something went wrong', 'error'))
							.finally(() => {
								refetchVenue();
								runInAction(() => {
									submitStatus.canSubmit = true;
								});
							});
					}
				} else {
					const newZone = new ZoneEntity({
						name: zoneForm.name,
						description: zoneForm.description,
						venueId,
						isActive: zoneForm.active,
						isStreaming: zoneForm.isStreaming,
						productKey: zoneForm.productKey,
						agencyOwnerId: agencyId,
						fadeInDuration: zoneForm.fadeInDuration!,
						fadeOutDuration: zoneForm.fadeOutDuration!,
						fadeVolume: zoneForm.fadeVolume,
						isFadeOverridden: zoneForm.isFadeOverridden,
						latitude: zoneForm.latitude ?? 0,
						longitude: zoneForm.longitude ?? 0,
					});

					newZone
						.save()
						.then(() => {
							runInAction(async () => {
								if (zoneForm.logo) {
									newZone.logo = zoneForm.logo;
									await newZone
										.save(undefined, {
											contentType: 'multipart/form-data',
										})
										.then(() => {
											alert('Updated Zone', 'success');
											store.modal.hide();
										})
										.catch(err => {
											alert('Failed updating zone', 'error');
										});
								} else {
									alert('Updated Zone', 'success');
									store.modal.hide();

									if (refetchVenue) {
										refetchVenue();
									}
								}
							});
						})
						.catch(() => alert('Something went wrong', 'error'))
						.finally(() => {
							refetchVenue();
							runInAction(() => { submitStatus.canSubmit = true; });
						});
				}
			});
		}
		runInAction(() => { submitStatus.canSubmit = true; });
		setIsSubmitting(false);
	};

	const refreshProductKey = () => {
		runInAction(() => {
			const newProductKey = uuidv4();
			zoneForm.productKey = newProductKey;
			zoneForm.active = false;

			if (zone === null || zone === undefined) {
				return;
			}

			zone.productKey = newProductKey;
			zone.isActive = false;
			zone.syncStatus = 'AWAITING_SYNC';

			zone
				.save()
				.then(() => alertToast('Zone has been deactivated', 'success'));
		});
	};

	const deleteZone = () => {
		if (zone === null || zone === undefined) {
			return;
		}
		runInAction(() => {
			zone.archived = true;
		});
		zone
			.save()
			.then(() => {
				refetchVenue();
				alertToast('Zone Archived', 'success');
				store.modal.hide();
			})
			.catch(err => alertToast('Unable to Archive zone', 'error'));
	};

	const inactivateZone = () => {
		if (zone === null || zone === undefined) {
			return;
		}

		runInAction(() => {
			zoneForm.active = false;
		});
		zone
			.save()
			.then(() => {
				refetchVenue();
			})
			.catch(err => alertToast('Unable to deactivate zone', 'error'));
	};

	return (
		<div className="zone-modal-container">
			<form onSubmit={onSubmit}>
				<div className="zone-wrapper">
					<FileUpload
						model={zoneForm}
						modelProperty="logo"
						label="Logo"
						labelVisible={false}
						disableDropArea
						errors={zoneForm.errors.logo}
						buttonText="Upload Logo"
						tooltip="The zone logo works best with a 7:5 aspect ratio."
						preview={(file, onDelete) => {
							if (file) {
								const reader = new FileReader();
								reader.onload = (event: ProgressEvent<FileReader>) => {
									const result = event.target?.result;
									if (typeof result === 'string') {
										runInAction(() => {
											zoneForm.logoUrl = result;
										});
									}
								};
								reader.readAsDataURL(file);
								return (
									<img
										className="logo-preview"
										src={zoneForm.logoUrl}
										alt={zoneForm.logoFileName}
									/>
								);
							}

							if (zone?.logoId) {
								return (
									<img
										className="logo-preview"
										src={`${SERVER_URL}/api/files/${zone.logoId}`}
										alt={zoneForm.logoFileName}
									/>
								);
							}

							return <div className="logo-preview-na" />;
						}}
					/>
					<TextField
						model={zoneForm}
						modelProperty="name"
						label="Zone Name"
						className="zoneName"
						placeholder="Zone Name"
						isRequired
						errors={zoneForm.errors.name}
					/>
					<TextField
						model={zoneForm}
						modelProperty="description"
						label="Zone Description"
						className="zoneDescription"
						placeholder="Zone Description"
						errors={zoneForm.errors.description}
					/>
					<div className="double-input">
						<TextField
							model={zoneForm}
							modelProperty="latitude"
							label="Latitude"
							className="latitude"
							placeholder="Latitude"
							isRequired
							errors={zoneForm.errors.latitude}
						/>
						<TextField
							model={zoneForm}
							modelProperty="longitude"
							label="Longitude"
							className="longitude"
							placeholder="Longitude"
							isRequired
							errors={zoneForm.errors.longitude}
						/>
					</div>
					<div className="switch-control-container">
						<div>
							<p>Active status</p>
							<label className="switch">
								<input
									type="checkbox"
									onChange={() => runInAction(() => {
										if (zoneForm.active === true) {
											openInactiveModal();
										} else {
											zoneForm.active = !zoneForm.active;
										}
									})}
									checked={zoneForm.active}
								/>
								<span className="slider round" />
							</label>
						</div>
						<div className="streaming-toggle">
							<p>Streaming only</p>
							<label className="switch">
								<input
									type="checkbox"
									onChange={() => runInAction(() => {
										zoneForm.isStreaming = !zoneForm.isStreaming;
									})}
									checked={zoneForm.isStreaming}
									disabled={store.userGroups.some(
										ug => ug.name === 'AgencyPlaylister',
									)}
								/>
								<span
									className="slider round"
									style={
										store.userGroups.some(
											ug => ug.name === 'AgencyPlaylister',
										)
											? { cursor: 'not-allowed' }
											: {}
									}
								/>
							</label>
						</div>
						<div className="fade-overriden-toggle">
							<p>Fade overriden</p>
							<label className="switch">
								<input
									type="checkbox"
									onChange={() => {
										runInAction(() => {
											zoneForm.isFadeOverridden = !zoneForm.isFadeOverridden;
										});

										if (!zoneForm.isFadeOverridden) {
											queryClient.resetQueries('agency').then(() => {
												runInAction(() => {
													zoneForm.fadeInDuration = agency?.fadeInDuration || 2.5;
													zoneForm.fadeOutDuration = agency?.fadeOutDuration || 2.5;
													zoneForm.fadeVolume = agency?.fadeVolume || 0;
												});
											});
										}
									}}
									checked={zoneForm.isFadeOverridden}
								/>
								<span className="slider round" />
							</label>
						</div>
					</div>
					<div className="fade-settings">
						<TextField
							model={zoneForm}
							modelProperty="fadeVolume"
							label="Fade volume (%)"
							className="fadeVolume"
							placeholder="Fade volume"
							isDisabled={!zoneForm.isFadeOverridden}
							errors={zoneForm.errors.fadeVolume}
						/>
						<TextField
							model={zoneForm}
							modelProperty="fadeInDuration"
							label="Fade in duration (seconds)"
							className="fadeInDuration"
							placeholder="Fade in duration"
							isDisabled={!zoneForm.isFadeOverridden}
							errors={zoneForm.errors.fadeInDuration}
						/>
						<TextField
							model={zoneForm}
							modelProperty="fadeOutDuration"
							label="Fade out duration (seconds)"
							className="fadeOutDuration"
							placeholder="Fade out duration"
							isDisabled={!zoneForm.isFadeOverridden}
							errors={zoneForm.errors.fadeOutDuration}
						/>
					</div>
					<div className="productKey-container">
						<TextField
							model={zoneForm}
							modelProperty="productKey"
							label="Product Key"
							className="productKey"
							placeholder="00000000-0000-0000-0000-000000000000"
							isDisabled
						/>
						<Button
							type="button"
							colors={Colors.Primary}
							display={Display.Outline}
							sizes={Sizes.Small}
							icon={{ icon: 'refresh-cw', iconPos: 'icon-left' }}
							className="refresh-key-button"
							onClick={openRefreshProductKeyModal}
						>
							Refresh Product Key
						</Button>
						<Button
							type="button"
							colors={Colors.Secondary}
							display={Display.Outline}
							sizes={Sizes.Small}
							icon={{ icon: 'copy', iconPos: 'icon-top' }}
							className="copy-button"
							onClick={() => {
								navigator.clipboard
									.writeText(zoneForm.productKey)
									.then(() => {
										alertToast('Copied product key!', 'success');
									})
									.catch(() => {
										alertToast('Error copying product key!', 'error');
									});
							}}
						/>
					</div>
				</div>
				<div className="form-controls">
					<Button
						type="button"
						colors={Colors.Error}
						display={Display.Outline}
						sizes={Sizes.Medium}
						onClick={openDeleteModal}
					>
						Archive Zone
					</Button>
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={() => store.modal.hide()}
					>
						Cancel
					</Button>
					<Button
						type="submit"
						colors={Colors.Primary}
						display={Display.Solid}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'submit' }}
						disabled={!submitStatus.canSubmit || isSubmitting}
					>
						Save Zone
					</Button>
				</div>
			</form>
			<ConfirmRefreshProductKeyModal
				isOpen={refreshModalOpen}
				label="Refresh Product Key"
				refreshProductKey={refreshProductKey}
				closeRefreshProductKeyModal={closeRefreshProductKeyModal}
			/>
			<ConfirmDeleteZoneModal
				isOpen={deleteModalOpen}
				label="Archive Zone"
				deleteZone={deleteZone}
				closeConfirmDeleteZoneModal={closeDeleteModal}
			/>
			<ConfirmInactiveModal
				isOpen={inactiveModalOpen}
				label="Archive Zone"
				inactivateZone={inactivateZone}
				closeConfirmInactiveZoneModal={closeInactiveModal}
			/>
		</div>
	);
};

export default observer(ZoneModal);
