// @ts-nocheck
import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Link } from 'react-router-dom';
import { runInAction } from 'mobx';
import Axios from 'axios';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Components/Button/Button';
import { AgencyEntity, ClientEntity, ContactEntity } from '../../../Models/Entities';
import alert from '../../../Util/ToastifyUtils';
import { TextField } from '../../Components/TextBox/TextBox';
import { isEmail } from '../../../Validators/Functions/Email';
import { store } from '../../../Models/Store';
import { SERVER_URL } from '../../../Constants';
import { Combobox } from '../../Components/Combobox/Combobox';

export interface IContactDetails {
	contactName?: string;
	contactNumber?: string;
	emailAddress?: string;
	id?: string;
	mainContact?: boolean;
}

interface INewClientFormState {
	clientName: string;
	contacts: IContactDetails[];
	errors: {
		clientName: string;
		contacts: IContactDetails[];
		agency: string;
	};
}

const defaultNewClientFormState: INewClientFormState = {
	clientName: '',
	contacts: [{
		contactName: '',
		contactNumber: '',
		emailAddress: '',
	}],
	errors: {
		clientName: '',
		contacts: [{}] as IContactDetails[],
		agency: '',
	},
};

const errorMessages = {
	clientName: 'Client Name is required',
	contactName: 'Contact Name is required',
	contactNumber: 'A contact number is required if no email address is provided.',
	emailAddress: 'A contact email is required if no contact number is provided.',
	agency: 'An agency is required',
};

const CreateClientPage = (): JSX.Element => {
	const agencyStore = useLocalStore(() => ({
		agencies: [] as AgencyEntity[],
		agency: '',
	}));

	useEffect(() => {
		if (store.isMustardAdminOrMustardManager) {
			Axios.get(`${SERVER_URL}/api/entity/AgencyEntity/GetWithMustardAdminId`)
				.then(res => {
					// eslint-disable-next-line no-return-assign
					runInAction(() => agencyStore.agencies = res.data.map((a: AgencyEntity) => new AgencyEntity(a)));
				})
				.catch(err => console.log('err'));
		}
	}, []);

	const newClientForm = useLocalStore(() => (defaultNewClientFormState));

	const validateField = (field: string, index: number = -1): void => {
		if (field === 'contacts') {
			newClientForm.contacts.forEach((contact, i) => {
				Object.keys(contact).forEach(contactField => validateField(contactField, i));
			});
			if (newClientForm.errors.contacts?.map(contact => Object.keys(contact).length).reduce((a: number, b: number) => a + b, 0) === 0) {
				runInAction(() => {
					if (newClientForm.errors.contacts) {
						// @ts-ignore
						delete newClientForm.errors.contacts;
					}
				});
			}
			return;
		}
		runInAction(() => {
			if (field === 'contactNumber') {
				if (newClientForm.contacts[index].contactNumber === '') {
					if (newClientForm.contacts[index].emailAddress !== '') {
						if (newClientForm.errors.contacts) { delete newClientForm.errors.contacts[index].contactNumber; }
					} else {
						newClientForm.errors.contacts[index].contactNumber = errorMessages.contactNumber;
					}
				} else if (Number.isNaN(Number(newClientForm.contacts[index].contactNumber))) {
					newClientForm.errors.contacts[index].contactNumber = 'Not a valid number.';
				} else if (newClientForm.errors.contacts) { delete newClientForm.errors.contacts[index].contactNumber; }
			} else if (field === 'emailAddress') {
				if (newClientForm.contacts[index].emailAddress === '') {
					if (newClientForm.contacts[index].contactNumber !== '') {
						if (newClientForm.errors.contacts) { delete newClientForm.errors.contacts[index].emailAddress; }
					} else {
						newClientForm.errors.contacts[index].emailAddress = errorMessages.emailAddress;
					}
				} else if (!isEmail(newClientForm.contacts[index].emailAddress || '')) {
					newClientForm.errors.contacts[index].emailAddress = 'Not a valid email.';
				} else if (newClientForm.errors.contacts) { delete newClientForm.errors.contacts[index].emailAddress; }
			} else if (field === 'contactName') {
				if (newClientForm.contacts[index].contactName === '' || newClientForm.contacts[index].contactName === null) {
					newClientForm.errors.contacts[index].contactName = errorMessages.contactName;
				} else if (newClientForm.errors.contacts) { delete newClientForm.errors.contacts[index].contactName; }
			} else if (newClientForm[field] === '' || newClientForm[field] === null) {
				newClientForm.errors[field] = errorMessages[field];
			} else {
				delete newClientForm.errors[field];
			}
		});
	};

	const clearForm = (): void => {
		runInAction((() => {
			Object.keys(newClientForm).forEach(field => {
				newClientForm[field] = defaultNewClientFormState[field];
			});
		}));
	};

	const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
		e.preventDefault();
		Object.keys(newClientForm).forEach(field => {
			if (field !== 'errors') { validateField(field); }
		});

		runInAction(() => {
			if (store.isMustardAdminOrMustardManager) {
				if (agencyStore.agency === '') {
					newClientForm.errors.agency = errorMessages.agency;
				} else {
					// @ts-ignore
					delete newClientForm.errors.agency;
				}
			} else {
				// @ts-ignore
				delete newClientForm.errors.agency;
			}
		});

		if (Object.keys(newClientForm.errors).length === 0) {
			const newClient = new ClientEntity();
			newClient.name = newClientForm.clientName;
			
			if (store.userGroups.some(ug => ug.name === 'AgencyAdmin')) {
				newClient.agency = await store.getLoggedInAgency();
				newClient.agencyId = newClient.agency.id;
				newClient.agencyOwnerId = newClient.agencyId;
			} 
			
			if (store.isMustardAdminOrMustardManager) {
				[newClient.agency] = agencyStore.agencies.filter((a: AgencyEntity) => a.id === agencyStore.agency);
				newClient.agencyId = agencyStore.agency;
				newClient.agencyOwnerId = agencyStore.agency;
			}

			newClientForm.contacts.forEach((contact: IContactDetails, index: number) => {
				const newContact = new ContactEntity();
				newContact.mainContact = index === 0;
				newContact.name = contact.contactName || '';
				if (store.userGroups.some(ug => ug.name === 'AgencyAdmin')) {
					if (newClient.agencyId) {
						newContact.agencyOwnerId = newClient.agencyId;
					}
				}

				if (store.isMustardAdminOrMustardManager) {
					newContact.agencyOwnerId = agencyStore.agency;
				}
				if (newClientForm.contacts[index].contactNumber) { newContact.phoneNumber = newClientForm.contacts[index].contactNumber || ''; }
				if (newClientForm.contacts[index].emailAddress) { newContact.emailAddress = newClientForm.contacts[index].emailAddress || ''; }

				newClient.contactss.push(newContact);
			});

			newClient.save({ contactss: {} }).then(() => {
				alert('Created Client', 'success');
				store.routerHistory.push(`/clients/edit/${newClient.id}`);
			}).catch(() => {
				alert('Client Creation Failed', 'error');
			});
		}
	};

	useEffect(() => (): void => {
		clearForm();
	}, []);

	return (
		<div className="new-client-container">
			<Link to="/clients">
				<Button colors={Colors.Secondary} display={Display.Text} sizes={Sizes.Medium} buttonProps={{ id: 'return' }} icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}>
					Back
				</Button>
			</Link>
			<h1>New Client</h1>
			<form onSubmit={onSubmit}>
				{store.isMustardAdminOrMustardManager && (
					<Combobox
						model={agencyStore}
						modelProperty="agency"
						label="Agency"
						className="agency"
						options={agencyStore.agencies
							.map(a => ({ display: a.name, value: a.id }))
							.sort((a, b) => a.display.localeCompare(b.display))}
						isRequired
						errors={newClientForm.errors.agency}
					/>
				)}
				<TextField
					model={newClientForm}
					modelProperty="clientName"
					label="Client Name"
					className="clientName"
					isRequired
					errors={newClientForm.errors.clientName}
				/>
				<h3>Main Contact</h3>
				<div className="contact-details">
					<TextField
						model={newClientForm.contacts[0]}
						modelProperty="contactName"
						label="Contact Name"
						className="contactName"
						isRequired
						errors={newClientForm.errors.contacts !== undefined ? newClientForm.errors.contacts[0].contactName : ''}
					/>
					<TextField
						model={newClientForm.contacts[0]}
						modelProperty="contactNumber"
						label="Contact Number"
						className="contactNumber"
						errors={newClientForm.errors.contacts !== undefined ? newClientForm.errors.contacts[0].contactNumber : ''}
					/>
					<TextField
						model={newClientForm.contacts[0]}
						modelProperty="emailAddress"
						label="Email Address"
						className="emailAddress"
						errors={newClientForm.errors.contacts !== undefined ? newClientForm.errors.contacts[0].emailAddress : ''}
					/>
				</div>
				<h3>Additional Contacts</h3>
				<Button
					type="button"
					className="add-contact"
					colors={Colors.Primary}
					display={Display.Text}
					sizes={Sizes.Medium}
					icon={{ icon: 'plus', iconPos: 'icon-left' }}
					onClick={(): void => runInAction((() => {
						newClientForm.contacts = [...newClientForm.contacts, {
							contactName: '',
							contactNumber: '',
							emailAddress: '',
						}];
						if (!newClientForm.errors.contacts) { newClientForm.errors.contacts = [{}]; }
						newClientForm.errors.contacts = [...newClientForm.errors.contacts, {
							contactName: '',
							contactNumber: '',
							emailAddress: '',
						}];
					}))}
				>
					Add Contact
				</Button>
				{newClientForm.contacts.map((contact: IContactDetails, index: number) => {
					if (index) {
						return (
							// eslint-disable-next-line react/no-array-index-key
							<div className="contact-details" key={`${contact}-${index}`}>
								<TextField
									model={contact}
									modelProperty="contactName"
									label="Contact Name"
									className="contactName"
									isRequired
									errors={newClientForm.errors.contacts !== undefined ? newClientForm.errors.contacts[index].contactName : ''}
								/>
								<TextField
									model={contact}
									modelProperty="contactNumber"
									label="Contact Number"
									className="contactNumber"
									errors={newClientForm.errors.contacts !== undefined ? newClientForm.errors.contacts[index].contactNumber : ''}
								/>
								<TextField
									model={contact}
									modelProperty="emailAddress"
									label="Email Address"
									className="emailAddress"
									errors={newClientForm.errors.contacts !== undefined ? newClientForm.errors.contacts[index].emailAddress : ''}
								/>
								<Button
									type="button"
									colors={Colors.Error}
									display={Display.Text}
									sizes={Sizes.Medium}
									icon={{ icon: 'bin-delete', iconPos: 'icon-left' }}
									onClick={(): void => runInAction((() => {
										newClientForm.contacts = [...newClientForm.contacts.slice(0, index), ...newClientForm.contacts.slice(index + 1)];
										newClientForm.errors.contacts = [...newClientForm.errors.contacts.slice(0, index), ...newClientForm.errors.contacts.slice(index + 1)];
									}))}
								/>
							</div>
						);
					}
					return <></>;
				})}
				<Link to="/clients" className="cancel-button">
					<Button colors={Colors.Secondary} display={Display.Outline} sizes={Sizes.Medium} buttonProps={{ id: 'cancel' }}>
						Cancel
					</Button>
				</Link>
				<Button type="submit" colors={Colors.Primary} display={Display.Solid} sizes={Sizes.Medium} buttonProps={{ id: 'submit' }}>
					Save Client
				</Button>
			</form>
		</div>
	);
};

export default observer(CreateClientPage);
