/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import ApplicationUserBankTracksReferenceManyToMany from '../Security/Acl/ApplicationUserBankTracksReferenceManyToMany';
import AgencyAdminBankTracksReferenceManyToMany from '../Security/Acl/AgencyAdminBankTracksReferenceManyToMany';
import VisitorsBankTracksReferenceManyToMany from '../Security/Acl/VisitorsBankTracksReferenceManyToMany';
import AgencyPlaylisterBankTracksReferenceManyToMany from '../Security/Acl/AgencyPlaylisterBankTracksReferenceManyToMany';
import MustardAdminBankTracksReferenceManyToMany from '../Security/Acl/MustardAdminBankTracksReferenceManyToMany';
import MustardManagementBankTracksReferenceManyToMany from '../Security/Acl/MustardManagementBankTracksReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IBanksBankTracksAttributes extends IModelAttributes {
	banksId: string;
	bankTracksId: string;

	banks: Models.BankEntity | Models.IBankEntityAttributes;
	bankTracks: Models.TrackEntity | Models.ITrackEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('BanksBankTracks')
export default class BanksBankTracks
	extends Model
	implements IBanksBankTracksAttributes {
	public static acls: IAcl[] = [
		new ApplicationUserBankTracksReferenceManyToMany(),
		new AgencyAdminBankTracksReferenceManyToMany(),
		new VisitorsBankTracksReferenceManyToMany(),
		new AgencyPlaylisterBankTracksReferenceManyToMany(),
		new MustardAdminBankTracksReferenceManyToMany(),
		new MustardManagementBankTracksReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public banksId: string;

	@observable
	@attribute()
	public bankTracksId: string;

	@observable
	@attribute({ isReference: true })
	public banks: Models.BankEntity;

	@observable
	@attribute({ isReference: true })
	public bankTracks: Models.TrackEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IBanksBankTracksAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.banksId) {
				this.banksId = attributes.banksId;
			}
			if (attributes.bankTracksId) {
				this.bankTracksId = attributes.bankTracksId;
			}

			if (attributes.banks) {
				if (attributes.banks instanceof Models.BankEntity) {
					this.banks = attributes.banks;
					this.banksId = attributes.banks.id;
				} else {
					this.banks = new Models.BankEntity(attributes.banks);
					this.banksId = this.banks.id;
				}
			} else if (attributes.banksId !== undefined) {
				this.banksId = attributes.banksId;
			}

			if (attributes.bankTracks) {
				if (attributes.bankTracks instanceof Models.TrackEntity) {
					this.bankTracks = attributes.bankTracks;
					this.bankTracksId = attributes.bankTracks.id;
				} else {
					this.bankTracks = new Models.TrackEntity(attributes.bankTracks);
					this.bankTracksId = this.bankTracks.id;
				}
			} else if (attributes.bankTracksId !== undefined) {
				this.bankTracksId = attributes.bankTracksId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
