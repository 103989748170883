import * as React from 'react';
import { runInAction } from 'mobx';
import { confirmModal } from '../../Components/Modal/ModalUtils';
import alertToast from '../../../Util/ToastifyUtils';
import { IModelType, Model } from '../../../Models/Model';
import { ICollectionItemActionProps } from '../../Components/Collection/Collection';

export function archiveAction<T extends Model & {archived?: boolean}>(ModelType: IModelType<T>, refetch?: () => void): ICollectionItemActionProps<T> {
	return {
		action: async (item: T) => {
			await confirmModal('Please confirm', 'Are you sure you want to archive this item?');
			runInAction(() => {
				item.archived = true;
			});
			try {
				await item.save();
				refetch?.();
				alertToast(`${item.getModelDisplayName()} Archived`, 'success');
			} catch (e) {
				alertToast(`${item.getModelDisplayName()} could not be Archived`, 'error');
			}
		},
		label: 'Archive',
		showIcon: true,
		icon: 'bin-full',
		iconPos: 'icon-top',
	};
}
