/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import { IAcl } from '../Security/IAcl';
import { observable } from 'mobx';
import AgencyPlaylisterManagesVenueReferenceManyToMany from '../Security/Acl/AgencyPlaylisterManagesVenueReferenceManyToMany';
import VisitorsManagesVenueReferenceManyToMany from '../Security/Acl/VisitorsManagesVenueReferenceManyToMany';
import ApplicationUserManagesVenueReferenceManyToMany from '../Security/Acl/ApplicationUserManagesVenueReferenceManyToMany';
import AgencyAdminManagesVenueReferenceManyToMany from '../Security/Acl/AgencyAdminManagesVenueReferenceManyToMany';
import MustardAdminManagesVenueReferenceManyToMany from '../Security/Acl/MustardAdminManagesVenueReferenceManyToMany';
import MustardManagementManagesVenueReferenceManyToMany from '../Security/Acl/MustardManagementManagesVenueReferenceManyToMany';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IManagedByManagesVenueAttributes extends IModelAttributes {
	managedById: string;
	managesVenueId: string;

	managedBy: Models.ApplicationUserEntity | Models.IApplicationUserEntityAttributes;
	managesVenue: Models.VenueEntity | Models.IVenueEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

@entity('ManagedByManagesVenue')
export default class ManagedByManagesVenue
	extends Model
	implements IManagedByManagesVenueAttributes {
	public static acls: IAcl[] = [
		new AgencyPlaylisterManagesVenueReferenceManyToMany(),
		new VisitorsManagesVenueReferenceManyToMany(),
		new ApplicationUserManagesVenueReferenceManyToMany(),
		new AgencyAdminManagesVenueReferenceManyToMany(),
		new MustardAdminManagesVenueReferenceManyToMany(),
		new MustardManagementManagesVenueReferenceManyToMany(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	@observable
	@attribute()
	public managedById: string;

	@observable
	@attribute()
	public managesVenueId: string;

	@observable
	@attribute({ isReference: true })
	public managedBy: Models.ApplicationUserEntity;

	@observable
	@attribute({ isReference: true })
	public managesVenue: Models.VenueEntity;
	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	constructor(attributes?: Partial<IManagedByManagesVenueAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		if (attributes) {
			if (attributes.managedById) {
				this.managedById = attributes.managedById;
			}
			if (attributes.managesVenueId) {
				this.managesVenueId = attributes.managesVenueId;
			}

			if (attributes.managedBy) {
				if (attributes.managedBy instanceof Models.ApplicationUserEntity) {
					this.managedBy = attributes.managedBy;
					this.managedById = attributes.managedBy.id;
				} else {
					this.managedBy = new Models.ApplicationUserEntity(attributes.managedBy);
					this.managedById = this.managedBy.id;
				}
			} else if (attributes.managedById !== undefined) {
				this.managedById = attributes.managedById;
			}

			if (attributes.managesVenue) {
				if (attributes.managesVenue instanceof Models.VenueEntity) {
					this.managesVenue = attributes.managesVenue;
					this.managesVenueId = attributes.managesVenue.id;
				} else {
					this.managesVenue = new Models.VenueEntity(attributes.managesVenue);
					this.managesVenueId = this.managesVenue.id;
				}
			} else if (attributes.managesVenueId !== undefined) {
				this.managesVenueId = attributes.managesVenueId;
			}
		}

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}
