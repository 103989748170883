// NOTE! refactored
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
	RefObject,
	useRef,
	MouseEvent,
	KeyboardEvent,
	useEffect,
	useState,
} from 'react';
import { observer, useLocalStore, useObserver } from 'mobx-react';
import {
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	Droppable,
} from 'react-beautiful-dnd';
import { useInfiniteQuery } from 'react-query';
import Axios from 'axios';
import { contextMenu } from 'react-contexify';
import { ContextMenu } from '../../../../Components/ContextMenu/ContextMenu';
import alertToast from '../../../../../Util/ToastifyUtils';
import AlbumArtPlaceholder from '../../../../../assets/empty-album-art.svg';
import useAsync from '../../../../../Hooks/useAsync';
import TrackTile from 'Views/Pages/Playlisting/TrackTile';
import {
	CheckedTrackEntity,
	ContentsSearchResultObject,
	MoodPlaylistTrackEntity,
	TabCollectionContainer,
} from 'Util/PlaylistUtils';
import { TextField } from 'Views/Components/TextBox/TextBox';
import {
	TrackEntity,
	MoodVersionEntity,
} from 'Models/Entities';
import { SERVER_URL } from 'Constants';
import useIntersectionObserver from 'Util/useIntersectionObserver';
import LoadingContainer from 'Views/Components/LoadingContainer/LoadingContainer';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

interface MoodPlaylistTabProps {
  collection: TabCollectionContainer;
  customTrackClickEvent?: (
    track: TrackEntity,
    e: MouseEvent<HTMLInputElement>
  ) => void;
  multiSelect?: (
    event: React.MouseEvent<HTMLInputElement>,
    index: number,
    start: boolean,
    track: string,
    trackList: string[],
    collectionId: string
  ) => void;
  multiSelectStore: {
    start: number;
    end: number;
    selected: string[];
    numSelected: number;
    collectionId: string;
  };
  moodVersion: MoodVersionEntity;
  selectAll: (isRemoving: boolean) => void;
  selectTracks: (val: boolean) => void;
	playlistId: string;
	playlistName: string;
}

const MoodPlaylistTab = observer((props: MoodPlaylistTabProps) => {
	const {
		multiSelectStore,
		multiSelect,
		moodVersion,
		selectAll,
		selectTracks,
		customTrackClickEvent,
		playlistId,
		playlistName,
	} = props;

	// local store for search term
	const tabStore = useLocalStore(() => ({
		term: '',
	}));
	const [searchTerm, setSearchTerm] = useState('');
	useEffect(() => {
		const handler = setTimeout(() => setSearchTerm(tabStore.term), 1000);

		return () => clearTimeout(handler);
	}, [tabStore.term]);

	// useEffect is required to initialise multiSelectStore
	// It can only be removed when refactoring completed on multi select functionality
	useEffect(() => {}, [multiSelectStore.selected, multiSelectStore.numSelected]);

	// Defines page size for mood_playlist query and multiselect index
	const PAGE_SIZE = 50;

	const onKeyDown = (
		e: MouseEvent | KeyboardEvent,
		snapshot: DraggableStateSnapshot,
	) => {
		if (e.defaultPrevented || snapshot.isDragging) return;
		e.preventDefault();
	};

	const {
		data: moodPlaylistData, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, refetch,
	} = useInfiniteQuery(
		['mood_playlist', playlistId, moodVersion?.moodId, searchTerm],
		async ({ pageParam = 0 }): Promise<ContentsSearchResultObject> => {
			const queryString = `${SERVER_URL}/api/playlist_search/collection_contents/${playlistId}`
			+ `?PageNo=${pageParam}`
			+ `&PageSize=${PAGE_SIZE}`
			+ '&entityType=playlist'
			+ `&searchTerm=${searchTerm}`
			+ `${moodVersion?.moodId ? `&moodId=${moodVersion?.moodId}` : ''}`;

			const res = await Axios.get(queryString);
			return res.data;
		},
		{
			getNextPageParam: (lastPage: any) => lastPage.nextPageNo === -1 ? undefined : lastPage.nextPageNo,
		},
	);

  // triggers fetching more content when reached
	const loadMoreButtonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

  // tracks the position of the scroll container to see if the loadMoreButtonRef has been reached
	useIntersectionObserver({
		root: null,
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage,
	});

	// TODO: This fetch a list of track ids for the multi select, can be extracted in data
	// This list is needed for the multi-select shift click
	const trackIds = useAsync(async () => {
		const queryString = `${SERVER_URL}/api/playlist_search/collection_list_ids/${playlistId}`
			+ '?&entityType=playlist'
			+ `&searchTerm=${searchTerm}`
			+ `${moodVersion?.moodId ? `&moodId=${moodVersion?.moodId}` : ''}`;

		const res = await Axios.get(queryString);
		return res.data as string[];
	}, [searchTerm, moodVersion?.moodId, playlistId]);

	const actions: { label: string; onClick: () => void }[] = [
		{
			label: 'Add All',
			onClick: () => { selectAll(false); refetch(); },
		},
		{
			label: 'Remove All',
			onClick: (): void => { selectAll(true); refetch(); },
		},
		{
			label: 'Add Selected Tracks',
			onClick: (): void => {
				if (multiSelectStore && multiSelectStore.selected.length > 0) {
					selectTracks(true);
				} else {
					alertToast('No tracks selected', 'error');
				}
				refetch();
			},
		},
		{
			label: 'Remove Selected Tracks',
			onClick: (): void => {
				if (multiSelectStore && multiSelectStore.selected.length > 0) {
					selectTracks(false);
				} else {
					alertToast('No tracks selected', 'error');
				}
				refetch();
			},
		},
	];

	return useObserver(() => (
		<div className="playlist-tab">
			<div className="playlists-header">
				<h4>{playlistName}</h4>
				<div className="actions-wrapper">
					<div
						role="button"
						className="icon-more-vertical icon-top"
						data-testid="context-menu-playlist-actions"
						onClick={e => contextMenu.show({
							event: e,
							id: 'context-menu-playlist-actions',
						})}
					/>
				</div>
				<ContextMenu
					menuId="context-menu-playlist-actions"
					actions={actions}
				/>
			</div>
			<div className="search">
				<div className="search-filter-container tab">
					<TextField
						model={tabStore}
						modelProperty="term"
						className="search-term"
						placeholder="Search Tracks"
					/>
				</div>

				<div className="playlist-summary">
					<p>{moodPlaylistData?.pages[0].summary ?? 'Summary Unavailable'}</p>
				</div>
			</div>
			<div className="result-section">
				<div className="results">
					<Droppable
						droppableId={`source-${playlistId}`}
						isDropDisabled
						// mode="virtual"
						renderClone={provided => (
							<div
								className="track-wrapper-clone"
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
							>
								<div className="select-count">{multiSelectStore?.numSelected}</div>
								<img
									src={AlbumArtPlaceholder}
									alt="album-art"
									className="album-art"
								/>
							</div>
						)}
					>
						{provided => (
							<>
								{isLoading ? <LoadingContainer />
									: (
										<div
											ref={provided.innerRef}
											className="result-tracks"
									/* eslint-disable react/jsx-props-no-spreading */
											{...provided.droppableProps}
										>
											{moodPlaylistData?.pages.map((page: any, index: number) => (
												page.playlist.map((a: MoodPlaylistTrackEntity, pagedIndex: number) => {
													const checkedTrack = new CheckedTrackEntity(a.track);
													checkedTrack.isIncluded = a.isIncluded;

													return (
														<Draggable
															draggableId={`tab-${checkedTrack.id}`}
															index={(index * PAGE_SIZE) + pagedIndex}
															key={`track-${checkedTrack.id}`}
															isDragDisabled={!multiSelectStore?.selected.includes(checkedTrack.id)}
														>
															{(
																draggableProvided: DraggableProvided,
																snapshot: DraggableStateSnapshot,
															) => (
														// eslint-disable-next-line jsx-a11y/no-static-element-interactions
																<div
																	className="track-wrapper"
																	ref={draggableProvided.innerRef}
																	{...draggableProvided.draggableProps}
																	{...draggableProvided.dragHandleProps}
																	onClick={(e: React.MouseEvent<HTMLInputElement>) => {
																// eslint-disable-next-line no-unused-expressions
																		!!customTrackClickEvent
																	&& customTrackClickEvent(checkedTrack, e);

																		if (multiSelect) {
																			e.preventDefault();
																			const isStart = e.ctrlKey || !e.shiftKey;
																			multiSelect(
																				e,
																				(index * PAGE_SIZE) + pagedIndex,
																				isStart,
																				checkedTrack.id,
																				trackIds.data ?? [],
																				playlistId,
																			);
																		}
																	}}
																	onKeyDown={(e: React.KeyboardEvent) => onKeyDown(e, snapshot)}
																	style={{ margin: '0 5px' }}
																>
																	<div
																		className={`result-item track art ${
																			multiSelectStore?.selected.includes(checkedTrack.id)
																				? 'selected'
																				: ''
																		}`}
																	>
																		<TrackTile
																			track={checkedTrack}
																			includeSelected
																			isSelected={a.isIncluded}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												})))}
											<button
												className="infiniteListEndButton"
												ref={loadMoreButtonRef}
												onClick={() => fetchNextPage()}
												disabled={!hasNextPage || isFetchingNextPage}
											>
												{isLoading || isFetchingNextPage || hasNextPage
													? 'Loading...'
													: 'Nothing more to load'}
											</button>
										</div>
									)}
							</>
						)}
					</Droppable>
				</div>
			</div>
		</div>
	));
});

export default MoodPlaylistTab;
