import React from 'react';
import { observer } from 'mobx-react';
import {
	VenueEntity, VenueTypeEntity, 
} from '../../../Models/Entities';
import { store } from '../../../Models/Store';
import VenueModal from '../Clients/Venues/VenueModal';
import VenueTile from '../Clients/Venues/VenueTile';
import { RouteComponentProps } from 'react-router';

interface EditVenueTileProps extends RouteComponentProps {
	venue: VenueEntity;
	refetchClient?: () => void;
	refetchVenue?: () => void;
}

const EditVenueTile = (props: EditVenueTileProps): JSX.Element => {
	const {
		venue,
		refetchClient,
		refetchVenue,
		history,
		location,
		match,
	} = props;

	const openEditVenueModal = (v: VenueEntity) => {
		store.modal.show(
			'Venue',
			<VenueModal
				title="Edit Venue"
				client={venue.client}
				venue={v} refetchClient={refetchClient}
			/>,
			{ className: 'slideout-panel-right' });
	};
	
	const refetchProps = {} as {refetchClient?: () => void, refetchVenue?: () => void;};
	
	if (refetchClient) {
		refetchProps.refetchClient = refetchClient;
	}

	if (refetchVenue) {
		refetchProps.refetchVenue = refetchVenue;
	}

	return (
		<div className="client-container">
			<div className="client-venues">
				<div className="client-venues-container">
					<VenueTile
						client={venue.client}
						venue={venue}
						openEditVenueModal={openEditVenueModal}
						key={`venue-${venue.id}`}
						history={history}
						location={location}
						match={match}
						{...refetchProps}
					/>
				</div>
			</div>
		</div>
	);
};

export default observer(EditVenueTile);
