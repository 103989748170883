import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { TrackEntity } from '../../../../../Models/Entities';
import Tabs from '../../../../Components/Tabs/Tabs';
import { CustomTabObject } from '../../../../../Util/PlaylistUtils';
import { FilterForm, FilterFormContainerDict } from '../FilterModal';
import PlaylistTab from './PlaylistTab';

interface PlaylistTabsProps {
  tabStore: {
    open: CustomTabObject[];
  }
  closeTab:(id: string) => void;
  multiSelect: (
    event: React.MouseEvent<HTMLInputElement>,
    index: number,
    start: boolean,
    track: string,
    trackList: string[],
    collectionId: string
  ) => void;
  multiSelectStore: {
    start: number;
    end: number;
    selected: string[];
    numSelected: number;
    collectionId: string;
  };
  clearMultiSelect: () => void;
  isExpanded: boolean;
  hasExpanded: boolean;
  showCount: (shouldShowCount: number, tab: boolean, id: string) => void;
  filterCountStore: {
    showCount: number;
    showTabCount: { [key: string]: number };
  };
  getFilterCount: (filterStore: FilterForm) => number;
  trackFilterStore: FilterFormContainerDict;
  customTrackClickEvent: (
    track: TrackEntity,
    e: MouseEvent<HTMLInputElement>
  ) => void;
}

const PlaylistTabs = observer((props: PlaylistTabsProps) => {
  const {
    tabStore,
    closeTab,
    multiSelect,
    multiSelectStore,
    clearMultiSelect,
    isExpanded,
    hasExpanded,
    showCount,
    filterCountStore,
    customTrackClickEvent,
  } = props;

  return (
    <div
      className={`playlist-tabs-container${isExpanded ? ' expanded' : ''}${
        hasExpanded ? '' : ' start-open'
      }`}
      style={{ minWidth: '300px' }}
    >
      {
        tabStore.open.length > 0 && (
          <Tabs
            tabs={
              tabStore.open.map(tab => ({
                component: (<PlaylistTab
                  collectionId={tab.id}
                  entityType={tab.entityType}
                  multiSelect={multiSelect}
                  multiSelectStore={multiSelectStore}
                  showCount={showCount}
                  filterCountStore={filterCountStore}
                  customTrackClickEvent={customTrackClickEvent}
                  defaultPrimaryGenre={tab.primaryGenre}
                />),
                name: `${tab.name}`,
                className: 'playlist-tab-container',
                key: tab.id,
              }))
            }
            closeTab={closeTab}
            clearMultiSelect={clearMultiSelect}
          />
        )
      }
    </div>
  );
});

export default PlaylistTabs;
