import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import axios, { AxiosResponse } from 'axios';
import {
	Button, Colors, Display, Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import CustomSpinner from '../../Components/CustomSpinner/CustomSpinner';
import { TrackEntity } from '../../../Models/Entities';
import TrackValidationPanel from '../Uploads/TrackValidationPanel';
import TrackErrorPanel from '../Uploads/TrackErrorPanel';
import alert from '../../../Util/ToastifyUtils';
import { PlaylistSummary } from '../../../Util/PlaylistUtils';

interface IM3UImportModalProps {
	name: string;
	file: File;
	openPlaylist: (playlist: PlaylistSummary) => void;
}

interface IM3UImportResult {
	existingTracks: TrackEntity[];
	missingTracks: TrackEntity[];
}
interface LineError {
	lineNumber: number;
	lineContents: string;
}

interface IM3UImportTrackData {
	artists: string[];
	title: string;
}

const M3UImportModal = (props: IM3UImportModalProps): JSX.Element => {
	const { name, file, openPlaylist } = props;
	const loadStates = useLocalStore(() => ({
		checkingForExisting: true,
		importingPlaylist: false,
	}));
	
	const importData = new Array<IM3UImportTrackData>();

	const trackStore = useLocalStore(() => ({
		existingTracks: new Array<TrackEntity>(),
		missingTracks: new Array<TrackEntity>(),
		lineIssues: new Array<LineError>(),
		noExistingTracks: false,
	}));
	
	const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
		e.preventDefault();

		runInAction(() => {
			loadStates.importingPlaylist = true;
		});

		axios.post('/api/entity/PlaylistEntity/ImportPlaylist', {
			name,
			trackIds: trackStore.existingTracks.map(t => t.id), 
		}).then((res: AxiosResponse<PlaylistSummary>) => {
			openPlaylist({
				id: res.data.id,
				name: res.data.name,
				summary: res.data.summary,
			});
			store.modal.hide();
			alert(`Successfully created playlist: ${name}`, 'success');
		}).catch(() => {
			alert('An error occurred', 'error');
		}).finally(() => {
			runInAction(() => {
				loadStates.importingPlaylist = false;
			});
		});
	};

	const checkForExistingTracks = (): void => {
		runInAction(() => {
			trackStore.existingTracks = [];
			loadStates.checkingForExisting = true;
			trackStore.noExistingTracks = false;
		});
		
		axios.post('/api/entity/PlaylistEntity/M3UImport/MissingTracks', importData)
			.then((res: AxiosResponse<IM3UImportResult>) => {
				if (res.data.missingTracks.length > 0) {
					runInAction(() => {
						trackStore.existingTracks = res.data.existingTracks;
						trackStore.missingTracks = res.data.missingTracks;
					});
				} else {
					runInAction(() => {
						trackStore.noExistingTracks = true;
					});
				}
			}).catch(() => {
				alert('Could not retrieve playlist. Playlist might not be public.', 'error');
				store.modal.hide();
			}).finally(() => {
				runInAction(() => {
					loadStates.checkingForExisting = false;
				});
			});
	};

	const readM3UTracks = (): void => {
		const reader = new FileReader();
		
		reader.onload = () => {
			const textFile = reader.result as string;
			const allLines = textFile?.split(/\r\n|\r|\n/);
			let lineNum = 1;
			// Reading line by line
			allLines.forEach((line: string) => {
				// check if tag is #EXTINF: then add track if it is.
				if (line.includes('#EXTINF:')) {
					const lineSplit = line.split(':');
					
					// Check for only one colon in the line
					if (lineSplit.length === 2 && lineSplit[0] === '#EXTINF') { // check in here for EXTINF in the first element
						const commaSplit = lineSplit[1].split(',');
						
						// Check for only one comma in the line
						if (commaSplit.length === 2) {
							const trackData = commaSplit[1].split('-');
							
							// Check that there is only one dash as this is the separator used in M3U
							if (trackData.length === 2) {
								const artist = trackData[0].trim();
								const title = trackData[1].trim();
								importData.push({ artists: [artist], title });
							} else {
								runInAction(() => {
									trackStore.lineIssues.push({ lineNumber: lineNum, lineContents: line });
								});
								lineNum += 1;
								return;
							}
						} else {
							runInAction(() => {
								trackStore.lineIssues.push({ lineNumber: lineNum, lineContents: line });
							});
							lineNum += 1;
							return;
						}
					} else {
						runInAction(() => {
							trackStore.lineIssues.push({ lineNumber: lineNum, lineContents: line });
						});
						lineNum += 1;
						return;
					}
				}
				lineNum += 1;
			});
			if (importData.length === 0) {
				alert('No tracks found in M3U', 'error');
				store.modal.hide();
				return;
			}
			
			checkForExistingTracks();
		};
		
		reader.onerror = event => {
			alert(event.target?.error?.name);
		};
		
		reader.readAsText(file);
	};
	
	const createCSV = ():void => {
		let csvContent = 'Title,Artist\n\r';
		csvContent += trackStore.missingTracks.map(te => `${te.title},${te.artistss.map(a => a.artists.name)}`).join('\n\r');
		csvContent += '\n\r\n\r';
		csvContent += 'Line Number, Line Content\n\r';
		csvContent += trackStore.lineIssues.map(l => `${l.lineNumber},"${l.lineContents}"`).join('\n\r');

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
		const uri = URL.createObjectURL(blob);

		const downloadLink = document.createElement('a');
		downloadLink.href = uri;
		downloadLink.download = `${name}-Report.csv`;

		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};
	
	useEffect(() => {
		readM3UTracks();
	}, []);

	return (
		<div className="bank-modal-container">
			<h4>Import From M3U</h4>

			{loadStates.checkingForExisting ? (
				<div>
					<p>Checking for missing tracks</p>
					<CustomSpinner />
				</div>
			) : (
				<></>
			)}

			{trackStore.missingTracks.length > 0 && (
				<TrackValidationPanel validatedTracks={trackStore.missingTracks} checkExisting={false} />
			)}

			{trackStore.lineIssues.length > 0 && (
				<TrackErrorPanel incorrectTracks={trackStore.lineIssues} />
			)}

			{trackStore.noExistingTracks && (
				<div className="no-existing">No Missing Tracks</div>
			)}

			<form onSubmit={onSubmit}>
				<div className="form-controls">
					{trackStore.missingTracks.length && (
						<Button
							type="button"
							className="csv-download"
							colors={Colors.Secondary}
							display={Display.Outline}
							sizes={Sizes.Medium}
							icon={{ icon: 'download', iconPos: 'icon-left' }}
							onClick={(e): void => {
								e.preventDefault();
								createCSV();
							}}
						>
							Download Report
						</Button>
					)}
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={(): void => store.modal.hide()}
					>
						Cancel
					</Button>

					{loadStates.importingPlaylist
						? <CustomSpinner />
						: (
							<Button
								type="submit"
								disabled={loadStates.checkingForExisting}
								colors={Colors.Primary}
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'submit' }}
							>
								Continue
							</Button>
						)}
				</div>
			</form>
		</div>
	);
};

export default observer(M3UImportModal);
