import React from 'react';
import { TrackEntity } from '../../../Models/Entities';

interface BankUploadErrorPanelProps {
	validatedTracks: TrackEntity[];
}

const BankUploadErrorPanel = (props: BankUploadErrorPanelProps): JSX.Element => {
	const { validatedTracks } = props;
	
	return (
		<div className="existing-track-panel">
			<div className="existing-track-info">
				{validatedTracks.length}
				{' '}
				{`Failed Tracks`}
			</div>
			
			<div className="existing-track-warning">
				{ 'Tracks in Bank failed to upload' }
			</div>
			
			<div className="existing-track-container">
				{validatedTracks.map(track => (
					<div className="existing-track-row">

						<div className='track-discoTrackId'>{track.discoTrackID}</div>

						<div className="track-title">{track.title}</div>
						
						{/* <div className="track-artist">
							{track.artistss.map((artist => (
								<>{artist.artists.name}</>
							)))}
						</div> */}
						
						{/* <div className="track-album">{track.album && track.album.title}</div> */}

						<div className="track-error">{track.uploadErrorMessage}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default BankUploadErrorPanel;
