/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { observer, useLocalStore } from 'mobx-react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import Axios from 'axios';
import React, { RefObject, useEffect, useRef } from 'react';
import { runInAction } from 'mobx';
import { Droppable } from 'react-beautiful-dnd';
import { MoodPlaylistEntity, PlaylistsSearchResultObject } from '../../../../Util/PlaylistUtils';
import { SERVER_URL } from '../../../../Constants';
import useIntersectionObserver from '../../../../Util/useIntersectionObserver';
import { TextField } from '../../../Components/TextBox/TextBox';
import {
	MoodEntity,
	PlaylistEntity,
} from '../../../../Models/Entities';
import LoadingContainer from 'Views/Components/LoadingContainer/LoadingContainer';

interface IPlaylistsProps {
	activePlaylist: PlaylistEntity | undefined;
	setActivePlaylist: (playlist: PlaylistEntity | undefined) => void;
	mood: MoodEntity;
}

const Playlists = observer((props: IPlaylistsProps) => {
	const {
		activePlaylist,
		setActivePlaylist,
		mood,
	} = props;

	const searchStore = useLocalStore(() => ({
		term: '',
	}));

	const moodStore = useLocalStore(() => ({
		moodVersionIdToUse: mood.draftVersion,
	}));

	useEffect(() => {
		runInAction(() => {
			moodStore.moodVersionIdToUse = mood.draftVersion;
		});
	}, [mood.draftVersion, mood.id, moodStore]);

	const {
		data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading,
	} = useInfiniteQuery(
		['playlists', moodStore.moodVersionIdToUse, searchStore.term],
		async ({ pageParam = 0 }): Promise<PlaylistsSearchResultObject> => {
			const res = await Axios.post(`${SERVER_URL}/api/entity/MoodEntity/playlists?`
			+ `moodVersionId=${moodStore.moodVersionIdToUse}`
			+ `&searchTerm=${searchStore.term}`
			+ `&PageNo=${pageParam}`
			+ '&PageSize=50');
			return res.data as PlaylistsSearchResultObject;
		},
		{
			getNextPageParam: (lastPage: any) => lastPage.nextPageNo === -1 ? undefined : lastPage.nextPageNo,
		},
	);

	// this ref is used for auto fetching more data upon reaching scroll position
	const loadMoreButtonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

	// tracks the position of the scroll container to see if the loadMoreButtonRef has been reached
	useIntersectionObserver({
		root: null,
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage,
	});

	const renderList = (fetchedData: InfiniteData<PlaylistsSearchResultObject> | undefined) => {
		const listOfLists = [].concat.apply(fetchedData?.pages.map(page => page.playlists)) as MoodPlaylistEntity[][];

		return (
			<>
				{
					listOfLists.map(list => list?.map(playlist => (
							<div
								className={`result-item playlist${playlist.id === activePlaylist?.id ? ' selected' : ''}`}
								key={`infinite-playlist-${playlist.id}`}
								onClick={() => setActivePlaylist(playlist)}
							>
								<div className={`hightlighted-name${playlist.isIncluded ? ' highlighted' : ''}`}>
									{playlist.isIncluded && (
										<div className="included" />
									)}
									<p>
										{playlist.name}
									</p>
								</div>
							</div>
						)))
				}
			</>
		);
	};

	return (
		<div className="playlists-wrapper">
			<div className="search">
				<div className="search-filter-container">
					<TextField
						model={searchStore}
						modelProperty="term"
						className="search-term search-panel"
						placeholder="Search Playlists"
					/>
				</div>
			</div>
			<div className="results">
				<div className="result-section">
					{isLoading
					? <LoadingContainer />
						: (
					<>
							<div
								className={`result-item mood${activePlaylist?.id ? '' : ' selected'}`}
								key={`infinite-playlist-${mood.id}`}
								onClick={() => setActivePlaylist(undefined)}
								data-testid={`open-mood-${mood.name}`}
							>
								<div className="hightlighted-name icon-right icon-arrow-right">
									<p>
										View all tracks in
										{' '}
										{mood.name}
									</p>
								</div>
							</div>
							<Droppable droppableId={`mood-${mood.id}`}>
								{provided => (
									<div
										className="drop-area"
										role="drop-area"
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										<div className="add-to-mood icon-plus icon-left">
											<p>Drag tracks to mood</p>
										</div>
									</div>
								)}
							</Droppable>
							{renderList(data)}

							<button
								className="infiniteListEndButton"
								ref={loadMoreButtonRef}
								onClick={() => fetchNextPage()}
								disabled={!hasNextPage || isFetchingNextPage}
							>
								{isLoading || isFetchingNextPage || hasNextPage ? 'Loading...' : 'Nothing more to load'}
							</button>
					</>
					)}
				</div>
			</div>
		</div>
	);
});

export default Playlists;
