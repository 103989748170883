import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import axios, { AxiosResponse } from 'axios';
import {
	Button, Colors, Display, Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import { TrackEntity } from '../../../Models/Entities';
import alert from '../../../Util/ToastifyUtils';
import BankUploadErrorPanel from './BankUploadErrorPanel';

interface BankUploadErrorModalProps {
	bankId: string;
}

const BankUploadErrorModal = (props: BankUploadErrorModalProps): JSX.Element => {
	const bankUploadErrorStore = useLocalStore(() => ({
		bankId: props.bankId,
		failedTracks: new Array<TrackEntity>(),
	}));

	const checkForFailedTracks = (e: React.SyntheticEvent | null): void => {
		e?.preventDefault();

		runInAction(() => {
			bankUploadErrorStore.failedTracks = [];
		});

		axios.post(`/api/entity/TrackEntity/getFailedTracks/${bankUploadErrorStore.bankId}`)
			.then((res: AxiosResponse<TrackEntity[]>) => {
				if (res.data.length > 0) {
					runInAction(() => {
						bankUploadErrorStore.failedTracks = res.data;
					});
				}
			}).catch(() => {
				alert('Unknown error occurred', 'error');
			})
			.finally(() => {

			});
	};

	const retryDiscoImport = (e: React.SyntheticEvent): void => {
		e.preventDefault();

		runInAction(() => {
			bankUploadErrorStore.failedTracks = [];
		});

		axios.post(`/api/entity/DiscoTrackEntity/retryDiscoImport/${bankUploadErrorStore.bankId}`)
			.then(res => {
				runInAction(() => {
					alert(`Starting Background Import`, 'success');
					store.modal.hide();
					store.apolloClient.clearStore();
				});
			}).catch(err => {
				alert('Something went wrong retrying import', 'error');
				console.error(err.response.data);
				store.modal.hide();
			});
	};

	// Only load the failed tracks on mount and unmount
	useEffect(() => {
		checkForFailedTracks(null);
	}, []);

	return (
		<div className="bank-modal-container">
			<h4>Failed Tracks</h4>

			<Button
				display={Display.Solid}
				colors={Colors.Secondary}
				onClick={(e): void => checkForFailedTracks(e)}
			>
				Refresh
			</Button>

			{bankUploadErrorStore.failedTracks.length > 0 && (
				<BankUploadErrorPanel validatedTracks={bankUploadErrorStore.failedTracks} />
			)}

			{bankUploadErrorStore.failedTracks.length <= 0 && (
				<div className="no-existing">Loading failed tracks</div>
			)}

				<div className="form-controls">
					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={(): void => store.modal.hide()}
					>
						Close
					</Button>

					<Button
						type="button"
						colors={Colors.Primary}
						display={Display.Outline}
						sizes={Sizes.Medium}
						buttonProps={{ id: 'cancel' }}
						onClick={retryDiscoImport}
					>
						Retry Import
					</Button>
				</div>
		</div>
	);
};

export default observer(BankUploadErrorModal);
