import {
	AlbumEntity,
	ArtistEntity, ArtistsTracks, BankEntity, GenreEntity, MoodTrackEntity,
	PlaylistEntity, PlaylistTrackEntity, TagEntity, TagsTrackTags,
	TrackEntity,
} from '../Models/Entities';

export const playlistRunTime = (tracks: TrackEntity[], runTime = -1) => {
	let totalSeconds = tracks.map((t: TrackEntity) => t.length).reduce((t: number, acc: number) => t + acc, 0);

	if (runTime !== -1) {
		totalSeconds = runTime;
	}

	const days = Math.floor(totalSeconds / 86400);
	const hours = Math.floor((totalSeconds % 86400) / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = Math.floor(totalSeconds % 60);

	let result = '';

	if (days > 0) {
		result += `${days}:${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	} else if (hours > 0) {
		result += `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	} else if (minutes > 0) {
		result += `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	} else if (seconds > 0) {
		result += `${seconds < 10 ? '0' : ''}${seconds}`;
	} else {
		result += 'NA';
	}

	return result;
};

export const collectionSummary = (collection: TabCollectionContainer): string => {
	if (collection.playlist) {
		return `${collection.playlist.playlistTrackss.length} TRACK${collection.playlist.playlistTrackss.length > 1 ? 'S' : ''} | ${playlistRunTime(collection.playlist.playlistTrackss.map((pt: PlaylistTrackEntity) => pt.track))}`;
	}
	if (collection.genre) {
		return `${collection.genre.trackTagss.length} TRACK${collection.genre.trackTagss.length > 1 ? 'S' : ''} | ${playlistRunTime(collection.genre.trackTagss.map((tt: TagsTrackTags) => tt.trackTags))}`;
	}
	if (collection.artist) {
		return `${collection.artist.trackss.length} TRACK${collection.artist.trackss.length > 1 ? 'S' : ''} | ${playlistRunTime(collection.artist.trackss.map((at: ArtistsTracks) => at.tracks))}`;
	}
	if (collection.album) {
		return `${collection.album.trackss.length} TRACK${collection.album.trackss.length > 1 ? 'S' : ''} | ${playlistRunTime(collection.album.trackss.map((t: TrackEntity) => t))}`;
	}
	return '';
};

interface ITrigramResult {
	similarity: number;
}

export interface ITrackResult extends ITrigramResult {
	track: TrackEntity;
}

export interface IPlaylistResult extends ITrigramResult {
	playlist: PlaylistEntity;
}

export interface IGenreResult extends ITrigramResult {
	genre: TagEntity;
}

export interface IArtistResult extends ITrigramResult {
	artist: ArtistEntity;
}

export interface IAlbumResult extends ITrigramResult {
	album: AlbumEntity;
}

export interface SearchResultObject {
	playlists: PlaylistEntity[];
	primaryGenres: GenreEntity[];
	genres: TagEntity[];
	tracks: TrackEntity[];
	artists: ArtistEntity[];
	albums: AlbumEntity[];
	nextPageNo: number;
}

export class CheckedTrackEntity extends TrackEntity {
	isIncluded: boolean;
}

export interface MoodPlaylistTrackEntity extends PlaylistTrackEntity {
	isIncluded: boolean;
}

export interface MoodPlaylistEntity extends PlaylistEntity {
	isIncluded: boolean;
}

export interface PlaylistsSearchResultObject {
	playlists: MoodPlaylistEntity[];
	nextPageNo: number;
}

export interface ContentsSearchResultObject {
	playlist: PlaylistTrackEntity[];
	genre: TagsTrackTags[];
	artist: TrackEntity[];
	album: TrackEntity[];
	nextPageNo: number;
	previousPageNo: number;
	summary: string;
	allInMood: boolean;
	bank: TrackEntity[];
}

export interface MoodContentsSearchResultObject {
	moodContents: MoodTrackEntity[];
	nextPageNo: number;
	summary: string;
}

export interface PlaylistSearchResultObject {
	playlists: PlaylistSummary[];
	nextPageNo: number;
}

// We use this when we need the stats on a playlist without wanting to grab the whole list which could contain over a thousand tracks
export interface PlaylistSummary {
	id: string;
	name: string;
	summary: string;
	created?: string;
	modified?: string;
	mood?: string;
}

// helps make the tabs generic for different types of track collections
export interface TabCollectionContainer {
	playlist?: PlaylistEntity;
	primaryGenre?: GenreEntity;
	genre?: TagEntity;
	artist?: ArtistEntity;
	album?: AlbumEntity;
	bank?: BankEntity;
}

export interface TabCollection {
	name: string;
	tracks: TrackEntity[];
	summary: string;
}

export interface IEditorTrackLists {
	[playlistId: string]: TrackEntity[];
}

export const getSourceId = (source: string): string => {
	if (source.includes('search')) {
		return 'search';
	}
	if (source.includes('source')) {
		return source.replace('source-', '');
	}
	return source.replace('destination-', '');
};

export const getDestinationId = (destination: string): string => {
	if (destination.includes('search')) {
		return 'search';
	}
	if (destination.includes('source')) {
		return destination.replace('source-', '');
	}
	if (destination.includes('destination-tile')) {
		return destination.replace('destination-tile-', '');
	}
	return destination.replace('destination-', '');
};

export const getTrackId = (track: string): string => {
	const trackId = track.replace('tab-', '').replace('editor-', '').replace('search-', '');

	if (trackId.includes('playlist')) {
		return trackId.substring(0, 36);
	}

	return trackId;
};

export type CollectionType = 'all' | 'tracks'| 'albums'| 'playlists'| 'primaryGenres'| 'genres'| 'artists'

export const returnCollectionType = (collection: CollectionType) => {
	switch (collection) {
		case 'tracks':
			return 'track';
		case 'albums':
			return 'album';
		case 'playlists':
			return 'playlist';
		case 'primaryGenres':
			return 'primary genre';
		case 'genres':
			return 'secondary genre';
		case 'artists':
			return 'artist';
		default:
			return '';
	}
};

export type CollectionEntity = PlaylistEntity | TagEntity | AlbumEntity | ArtistEntity | GenreEntity

export interface CollectionResult {
	playlists: PlaylistEntity[];
	primaryGenres: GenreEntity[];
	genres: TagEntity[];
	tracks: TrackEntity[];
	artists: ArtistEntity[];
	albums: AlbumEntity[];
}

export interface MultiSelectStore{
	start: number;
	end: number;
	selected: string[];
	numSelected: number;
	collectionId: string;
}

export interface CustomTabObject {
	name: string;
	entityType: CollectionType
	id: string;
	primaryGenre?: string;
}

export const DefaultTrackFilterStore = {
	primaryGenres: [],
	genres: [],
	bpm: {
		min: undefined,
		max: undefined,
	},
	year: {
		start: undefined,
		end: undefined,
	},
	moodFeel: [],
	instruments: [],
	lyricThemes: [],
	vocals: [],
	type: [],
};
