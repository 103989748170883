import React, {
	RefObject, SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { observer, useLocalStore, useObserver } from 'mobx-react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import Axios from 'axios';
import { contextMenu } from 'react-contexify';
import useContainerScroll from '../../../../Util/useContainerScroll';
import { SERVER_URL } from '../../../../Constants';
import flatten from 'lodash/flatten';
import {
	MoodEntity,
	MoodTrackEntity,
	MoodVersionEntity,
	TrackEntity,
} from '../../../../Models/Entities';
import { MoodContentsSearchResultObject } from '../../../../Util/PlaylistUtils';
import useIntersectionObserver from '../../../../Util/useIntersectionObserver';
import { TextField } from '../../../Components/TextBox/TextBox';
import TrackTile from '../../Playlisting/TrackTile';
import { ContextMenu } from '../../../Components/ContextMenu/ContextMenu';
import alertToast from '../../../../Util/ToastifyUtils';
import LoadingContainer from 'Views/Components/LoadingContainer/LoadingContainer';
import {
	Button, Colors, Display, Sizes,
} from '../../../Components/Button/Button';
import { Combobox } from 'Views/Components/Combobox/Combobox';
import { DropdownProps } from 'semantic-ui-react';
import { runInAction } from 'mobx';
import { store } from '../../../../Models/Store';
import NewPlaylistModal from '../../Playlisting/NewPlaylistModal';
import axios from 'axios';

const SORT_ORDER: Record<string, string> = {
	None: '',
	Liked: 'liked',
	Disliked: 'disliked',
	Disabled: 'disabled',
} as const;

interface IMoodContentsProps {
	mood: MoodEntity;
	customTrackClickEvent?: (track: TrackEntity) => void;
	moodVersion?: MoodVersionEntity;
	multiSelect: (
		event: React.MouseEvent<HTMLInputElement>,
		index: number,
		start: boolean,
		track: string,
		trackList: string[],
		collectionId: string
	) => void;
	multiSelectStore?: {
		start: number;
		end: number;
		selected: string[];
		numSelected: number;
		collectionId: string;
	};
	selectTracks: (val: boolean) => void;
}

// Defines page size for mood_contents query and multiselect index
const PAGE_SIZE = 50;

const MoveTracksModal = observer(({
	selectedTracks, onClose, moods, currentMood,
}: { selectedTracks?: TrackEntity[], onClose: () => void, moods: MoodEntity[], currentMood: MoodEntity }) => {
	const [selectedMood, setSelectedMood] = useState<string>('');
	const queryClient = useQueryClient();

	const profileMoodsStore = useLocalStore(() => ({
		profileMoods: [] as MoodEntity[],
		profileMood: '',
	}));

	useEffect(() => {
		Axios.get(`${SERVER_URL}/api/entity/MoodEntity/moods_in_profile/${currentMood.id}`)
			.then(res => {
				runInAction(() => {
					profileMoodsStore.profileMoods = res.data.map((a: MoodEntity) => new MoodEntity(a));
				});
			})
			.catch(err => {
				console.log('err');
				alertToast('Error retrieving moods in profile', 'error');
			});
	}, [currentMood.id, profileMoodsStore]);

	const handleMoodChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		if (typeof data.value === 'string') {
			setSelectedMood(data.value);
			profileMoodsStore.profileMood = data.value;
		}
	};

	const handleSubmit = async (e: { preventDefault: () => void; }) => {
		e.preventDefault();

		try {
			const selectedTrackIds = selectedTracks?.map(track => track.id);
			await Axios.post(`${SERVER_URL}/api/entity/MoodEntity/move_tracks`, {
				selectedMood: selectedMood,
				selectedTracks: selectedTrackIds,
				currentMood: currentMood.id,
			});

			queryClient.refetchQueries('profile');
			queryClient.refetchQueries('mood_contents');
			queryClient.refetchQueries('settings_stats');

			onClose();
		} catch (error) {
			console.error('Error moving tracks:', error);
			alertToast('Failed to move tracks', 'error');
		}
	};

   // taking out the current mood because we shouldnt show that in the dropdown
	const filteredMoods = profileMoodsStore.profileMoods.filter(m => m.id !== currentMood.id);

	return (
		<div className="modal-container">
			<div className="modal-content">
				<div className="modal-header">
					<h3 className="modal-title">Move Tracks</h3>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="moods-list">
						<Combobox
							className="moods-list"
							model={profileMoodsStore}
							modelProperty="profileMood"
							placeholder="Select Mood"
							onChange={handleMoodChange}
							options={filteredMoods
								.map(a => ({ display: a.name, value: a.id }))}
							label="Moods in Profile"
						/>
					</div>

					{selectedMood && (
						<h4>
							Selected tracks will be moved from &quot;{currentMood.name}&quot;
							to &quot;{moods.find(m => m.id === selectedMood)?.name}&quot;
						</h4>
					)}

					<div className="move-tracks-form-controls">
						<div className="cancel">
							<Button
								type="button"
								colors={Colors.Primary}
								display={Display.Outline}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'cancel' }}
								onClick={onClose}
							>
								Cancel
							</Button>
						</div>
						<div className="move">
							<Button
								type="submit"
								colors={Colors.Primary}
								display={Display.Solid}
								sizes={Sizes.Medium}
								buttonProps={{ id: 'submit' }}
								disabled={!selectedMood}
							>
								Move
							</Button>
						</div>

					</div>
				</form>
			</div>
		</div>
	);
});

const MoodContents = observer((props: IMoodContentsProps) => {
	const {
		mood, multiSelect, multiSelectStore, selectTracks,
	} = props;

	const scrollRef = useContainerScroll(`${mood.id}-tab`);
	const [showMoveTracksModal, setShowMoveTracksModal] = useState(false);
	const [moods, setMoods] = useState<MoodEntity[]>([]);

	const tabStore = useLocalStore(() => ({
		term: '',
		trackList: [] as TrackEntity[],
	}));
	const [searchTerm, setSearchTerm] = useState('');
	useEffect(() => {
		const handler = setTimeout(() => setSearchTerm(tabStore.term), 1000);
		return () => clearTimeout(handler);
	}, [tabStore.term]);

	const [orderBy, setOrderBy] = useState('');

	const {
		data: moodTracksData,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isLoading,
	} = useInfiniteQuery(
		['mood_contents', mood.id, searchTerm, orderBy],
		async ({ pageParam = 0 }): Promise<MoodContentsSearchResultObject> => {
			const queryString = `${SERVER_URL}/api/entity/MoodEntity/mood_contents/${mood.id}`
				+ `?PageNo=${pageParam}`
				+ `&PageSize=${PAGE_SIZE}`
				+ `&searchTerm=${searchTerm}`
				+ `&orderBy=${orderBy}`;

			const res = await Axios.get(queryString);
			return res.data as MoodContentsSearchResultObject;
		},
		{
			getNextPageParam: (lastPage: any) => lastPage.nextPageNo === -1 ? undefined : lastPage.nextPageNo,
		},
	);

	// This track id list extracts all the track ID and provide it for the multiselect feature
	const tracks: TrackEntity[] = flatten(moodTracksData?.pages.map(page => page.moodContents.map((mt: MoodTrackEntity) => mt.track)));

	// triggers fetching more content when reached
	const loadMoreButtonRef = useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

	// tracks the position of the scroll container to see if the loadMoreButtonRef has been reached
	useIntersectionObserver({
		root: null,
		target: loadMoreButtonRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage,
	});

	const actions = [
		{
			label: 'Remove Selected Tracks',
			onClick: (): void => {
				if (multiSelectStore && multiSelectStore.selected.length > 0) {
					selectTracks(false);
				} else {
					alertToast('No tracks selected', 'error');
				}
			},
		},
		{
			label: 'Export Mood To CSV',
			onClick: (): void => {
				window.open(
					`${SERVER_URL}/api/entity/MoodEntity/ExportToCSV/${mood.id}`,
					'_blank',
				);
			},
		},
		{
			label: 'Export Mood To Playlist',
			onClick: () => store.modal.show(
				'Export Mood to Playlist',
				<NewPlaylistModal
					agencyId={mood.agencyOwnerId}
					showNameOnly
					openPlaylist={data => {
						axios.post(
							`${SERVER_URL}/api/entity/MoodEntity/ExportToPlaylist/${data.id}/${mood.id}`,
						);
					}}
				/>,
				{ className: 'new-playlist-modal' },
			),
		},
		{
			label: 'Move Selected Tracks',
			onClick: async (): Promise<void> => {
				if (multiSelectStore && multiSelectStore.selected.length > 0) {
					try {
						const response = await Axios.get(
							`${SERVER_URL}/api/entity/MoodEntity/moods_in_profile/${mood.id}`,
						);
						setMoods(response.data);
						setShowMoveTracksModal(true);
					} catch (error) {
						alertToast('Failed to retrieve moods in profile');
					}
				} else {
					alertToast('No tracks selected', 'error');
				}
			},
		},
	];

	const ContextMenuOption = (label: string, sort: string) => {
		return {
			buttonClass: orderBy === sort ? 'btn--solid' : '',
			label,
			onClick: () => setOrderBy(sort),
		};
	};

	return useObserver(() => (
		<div className="playlist-tab" style={{ overflowX: 'hidden' }}>
			<div className="playlists-header">
				<h4>{mood.name}</h4>
				<div className="actions-wrapper">
					<div
						className="icon-order icon-top sort-playlist"
						onClick={e => contextMenu.show({ event: e, id: 'sort-menu-options' })}
					/>
					<div
						role="button"
						className="icon-more-vertical icon-top"
						data-testid="context-menu-playlist-actions"
						onClick={e => contextMenu.show({
							event: e,
							id: 'context-menu-playlist-actions',
						})}
					/>
				</div>
				<ContextMenu
					menuId="sort-menu-options"
					actions={Object.entries(SORT_ORDER).map(([key, value]) => ContextMenuOption(key, value))}
				/>
				<ContextMenu menuId="context-menu-playlist-actions" actions={actions} />
			</div>

			<div className="search">
				<div className="search-filter-container tab">
					<TextField
						model={tabStore}
						modelProperty="term"
						className="search-term"
						placeholder="Search Tracks"
					/>
				</div>
				<p>{isLoading ? '' : moodTracksData?.pages[0].summary ?? 'Summary Unavailable'}</p>
			</div>

			{isLoading ? <LoadingContainer />
				: (
					<>
						<div
							className="results"
							ref={scrollRef}
							style={{ padding: '0 10px', overflowX: 'hidden', height: '95%' }}
						>
							{
								moodTracksData?.pages.map((page, index) => page.moodContents.map((mt: MoodTrackEntity, pagedIndex: number) => (
									<div
										key={`mood-track-${mt.id}`}
										className={`result-item track art ${multiSelectStore?.selected.includes(mt.trackId ?? '') ? 'selected' : ''}`}
										onClick={(e: React.MouseEvent<HTMLInputElement>) => {
											e.preventDefault();
											const isStart = e.ctrlKey || !e.shiftKey;
											multiSelect(
												e,
												(index * PAGE_SIZE) + pagedIndex,
												isStart,
												mt.track.id,
												tracks.map(track => track.id),
												'mood-contents',
											);
										}}
									>
										<TrackTile
											track={mt.track}
											includeFeedback
											feedbacks={mt.feedbackss}
										/>
									</div>
								)))
							}

							<button
								className="infiniteListEndButton"
								ref={loadMoreButtonRef}
								onClick={() => fetchNextPage()}
								disabled={!hasNextPage || isFetchingNextPage}
							>
								{isLoading || isFetchingNextPage || hasNextPage ? 'Loading...' : 'Nothing more to load'}
							</button>
						</div>
					</>
				)}
			{showMoveTracksModal && (
				<MoveTracksModal
					selectedTracks={
						multiSelectStore?.selected
							.map(id => tracks.find(track => track.id === id))
							.filter((track): track is TrackEntity => track !== undefined)
					}
					onClose={() => setShowMoveTracksModal(false)}
					moods={moods}
					currentMood={mood}
				/>
			)}
		</div>
	));
});

export default MoodContents;
