import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { runInAction } from 'mobx';
import {
	Button,
	Colors,
	Display,
	Sizes,
} from '../../Components/Button/Button';
import { store } from '../../../Models/Store';
import { getFetchAllQuery, getFetchSingleQuery } from '../../../Util/EntityUtils';
import {
	ClientEntity,
	CountryEntity,
	RegionEntity, VenueEntity,
	VenueTypeEntity,
} from '../../../Models/Entities';
import EditVenueTile from './EditVenueTile';

const orderBy = [
	{ path: 'name', descending: false },
];

interface EditVenuePageProps extends RouteComponentProps {
	match: {
		params: {
			id?: string;
		};
		isExact: boolean;
		path: string;
		url: string;
	};
}

const EditVenuePage = (props: EditVenuePageProps): JSX.Element => {
	const venueStore = useLocalStore(() => ({
		venue: new VenueEntity(),
		loading: true,
	}));
	
	const { history, match, location } = props;

	useEffect(() => {
		const { match: { params: { id } } } = props;

		const fetchData = async () => {
			await store.apolloClient.query({
				query: getFetchSingleQuery(VenueEntity, new VenueEntity().venuePageExpands, false),
				fetchPolicy: 'network-only',
				variables: {
					args: [{
						path: 'id',
						comparison: 'equal',
						value: id,
					}],
					orderBy,
				},
			}).then((res: any) => {
				runInAction(() => {
					venueStore.venue = new VenueEntity(res.data.venueEntity);
				});
			}).catch(err => console.log('could not fetch client', err));

			runInAction(() => { venueStore.loading = false; });
		};

		fetchData();
	}, []);

	const refetchVenue = async () => {
		const { match: { params: { id } } } = props;

		await store.apolloClient.query({
			query: getFetchSingleQuery(VenueEntity, new VenueEntity().venuePageExpands, false),
			fetchPolicy: 'network-only',
			variables: {
				args: [{
					path: 'id',
					comparison: 'equal',
					value: id,
				}],
				orderBy,
			},
		}).then((res: any) => {
			runInAction(() => {
				venueStore.venue = new VenueEntity(res.data.venueEntity);
			});
		}).catch(err => console.log('could not fetch venue', err));
	};

	if (venueStore.loading) {
		return <p>Loading...</p>;
	}

	return (
		<div className="client-wrapper">
			<Link to="/venues">
				<Button colors={Colors.Secondary} display={Display.Text} sizes={Sizes.Medium} buttonProps={{ id: 'return' }} icon={{ icon: 'chevron-left', iconPos: 'icon-left' }}>
					Back
				</Button>
			</Link>
			<EditVenueTile
				venue={venueStore.venue}
				refetchVenue={refetchVenue}
				history={history}
				location={location}
				match={match}
			/>
		</div>
	);
};

export default observer(EditVenuePage);
