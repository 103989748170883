import { useLocalStore } from 'mobx-react';
import React, {
	useCallback, useMemo, useState,
} from 'react';
import { Combobox } from '../../../../Components/Combobox/Combobox';
import { store } from '../../../../../Models/Store';
import { useLeavePageConfirmation } from '../../../../../Util/LeavePageConfirmation';
import { Button } from '../../../../Components/Button/Button';
import { Colors, Display, Sizes } from '../../../../Components/Button/Button';
import { action, runInAction } from 'mobx';
import { ProfileEntity, ScheduleEntity, VolumeTimeslotEntity } from '../../../../../Models/Entities';
import { sortBy } from 'lodash';
import alertToast from '../../../../../Util/ToastifyUtils';
import { useQueryClient } from 'react-query';

interface IVolumeTabProps {
	profile: ProfileEntity;
	scheduleId?: string;
}

interface IVolumeState {
	scheduleId?: string | null;
}

const defaultVolumeState: IVolumeState = {
	scheduleId: null,
};

const DayOfWeek = {
	Mon: 0,
	Tue: 1,
	Wed: 2,
	Thu: 3,
	Fri: 4,
	Sat: 5,
	Sun: 6,
} as const;

type DayOfWeekKeys = keyof typeof DayOfWeek;

const TIME_MAP = [
	'12am',
	'1am',
	'2am',
	'3am',
	'4am',
	'5am',
	'6am',
	'7am',
	'8am',
	'9am',
	'10am',
	'11am',
	'12pm',
	'1pm',
	'2pm',
	'3pm',
	'4pm',
	'5pm',
	'6pm',
	'7pm',
	'8pm',
	'9pm',
	'10pm',
	'11pm',
];

const NUMBER_OF_TIME_SLOTS = TIME_MAP.length * 2;

const DefaultVolumeSchedule: number[][] = Array(7).fill(Array(NUMBER_OF_TIME_SLOTS).fill(1));

const convertDayToDayIndex = (day: DayOfWeekKeys | null): number => {
	return !!day ? DayOfWeek[day] : -1;
};

const convertDayIndexToDayOfWeekEnum = (dayIndex: number) => {
	switch (dayIndex) {
		case 0: return 'MONDAY';
		case 1: return 'TUESDAY';
		case 2: return 'WEDNESDAY';
		case 3: return 'THURSDAY';
		case 4: return 'FRIDAY';
		case 5: return 'SATURDAY';
		case 6: return 'SUNDAY';
		default: return 'MONDAY';
	}
};

const convertTimeToTimeIndex = (time: Date): number => {
	// special case: add 1 if time is 23:59:59
	if (time.getHours() === 23 && time.getMinutes() === 59 && time.getSeconds() === 59) {
		return time.getHours() * 2 + Math.floor(time.getMinutes() / 30) + 1;
	}
	return time.getHours() * 2 + Math.floor(time.getMinutes() / 30);
};
const convertTimeIndexToTime = (timeIndex: number): Date => {
	const newDate = new Date(0, 0, 0, 0, 0, 0);
	newDate.setMinutes(timeIndex * 30);
	// special case: have to set time to 23:59:59 for the last timeIndex, which is 48
	if (timeIndex === NUMBER_OF_TIME_SLOTS) {
		newDate.setMilliseconds(-1);
	}
	return newDate;
};

const convertDayOfWeekEnumToDayIndex = (dayOfWeekEnum: string) => {
	switch (dayOfWeekEnum) {
		case 'MONDAY': return 0;
		case 'TUESDAY': return 1;
		case 'WEDNESDAY': return 2;
		case 'THURSDAY': return 3;
		case 'FRIDAY': return 4;
		case 'SATURDAY': return 5;
		case 'SUNDAY': return 6;
		default: return -1;
	}
};

const returnTimeSlotCellColor = (value: number) => (`rgb(${244 + 0}, ${63 + value * 157}, ${62 + 0})`);

const returnTimeSlotCellClassName = (
	{ currentValue, previousValue, nextValue }:
		{ currentValue: number, previousValue: number, nextValue: number },
): string => {
	let className = 'time-slot-cell';
	if (currentValue !== previousValue) {
		className += ' left-end-cell first-volume-schedule';
	}
	if (currentValue !== nextValue) {
		className += ' right-end-cell';
	}

	return className;
};

const VolumeTab = (props: IVolumeTabProps) => {
	const { profile, scheduleId } = props;

	const queryClient = useQueryClient();

	useLeavePageConfirmation(
		store.editingVolumeSchedule,
		'Unsaved Changes Warning',
		<div>You have unsaved changes. Are you sure you want to leave?</div>,
		{
			onConfirmCustom: () => { resetVolumeSchedule(); },
		},
	);

	const volumeState = useLocalStore(() => defaultVolumeState);

	const [selectedSchedule, setSelectedSchedule] = useState<ScheduleEntity | null>(null);
	// The current volume schedule in a matrix form of 7 by 48 cells filled with numbers representing the volume value
	const [currentVolumeSchedule, setCurrentVolumeSchedule] = useState<number[][]>(DefaultVolumeSchedule);
	const [initVolumeSchedule, setInitVolumeSchedule] = useState<number[][]>(DefaultVolumeSchedule);

	// Temp storage of day and timeIndex when a time slot is selected
	const [selectedDay, setSelectedDay] = useState<DayOfWeekKeys | null>(null);
	const [selectedTimeIndex, setSelectedTimeIndex] = useState<number | null>(null);

	const [selectedVolumeValue, setSelectedVolumeValue] = useState<number>(1);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const loadVolumeSchedule = (schedule: ScheduleEntity | undefined) => {
		// init a new volume schedule
		const newVolumeSchedule: number[][] = DefaultVolumeSchedule.map(r => [...r]);

		// loop over the volume schedule and substitute  the volumeTimeSlot value into the matrix
		schedule?.volumeTimeslotss.forEach(timeSlot => {
			const dayIndex = convertDayOfWeekEnumToDayIndex(timeSlot.dayOfWeek);
			const startIndex = convertTimeToTimeIndex(timeSlot.start);
			const endIndex = convertTimeToTimeIndex(timeSlot.end) - 1;

			for (let i = startIndex; i <= endIndex; i++) {
				newVolumeSchedule[dayIndex][i] = timeSlot.volume;
			}
		});
		// save the initial state of the selected schedule's volume schedule
		setInitVolumeSchedule(newVolumeSchedule);
		// create a copy of the volume schedule and apply changes to it
		setCurrentVolumeSchedule(newVolumeSchedule);
	};

	// upon profile update, parse the schedules for schedule options
	const scheduleOptions = useMemo(() => {
		if (profile.scheduless.length === 0) {
			return [];
		}

		if (!selectedSchedule) {
			const firstSchedule = scheduleId ? profile.scheduless.find(s => s.id === scheduleId)
				: profile.scheduless[0];
			if (!!firstSchedule) {
				// set default volumeState to have the first schedule option
				runInAction(() => { volumeState.scheduleId = firstSchedule.id; });
				// set selectedSchedule to the first schedule option
				setSelectedSchedule(firstSchedule);
				// fill the table with volumeSchedule
				loadVolumeSchedule(firstSchedule);
			}
		}

		return profile?.scheduless.map((schedule: ScheduleEntity) => ({ display: schedule.name, value: schedule.id }));
	}, [profile.scheduless, scheduleId, selectedSchedule, volumeState]);

	const resetState = () => {
		setSelectedDay(null);
		setSelectedTimeIndex(null);
	};

	// Selecting a schedule using the combo box
	const onSelectSchedule = () => {
		const schedule = profile.scheduless.find(s => s.id === volumeState.scheduleId);
		setSelectedSchedule(schedule ?? null);

		loadVolumeSchedule(schedule);

		resetState();
		runInAction(() => { store.editingVolumeSchedule = false; });
	};

	// update the selectedTimeSlotsMatrix, marking the selected ones true, and the rest are false
	const updateSelectedTimeSlots = useCallback(({
		startDayIndex, endDayIndex, startTimeIndex, endTimeIndex,
	}: { startDayIndex: number, endDayIndex: number, startTimeIndex: number, endTimeIndex: number }) => {
		// Creating a new instance of selectedTimeSlotsMatrix
		const newTimeSlotMatrix: number[][] = currentVolumeSchedule.map(row => [...row]);
		// loop through the range and update the specific value to true
		for (let day = startDayIndex; day <= endDayIndex; day++) {
			for (let i = startTimeIndex; i <= endTimeIndex; i++) {
				newTimeSlotMatrix[day][i] = selectedVolumeValue;
			}
		}
		// update to the new timeSlot matrix
		setCurrentVolumeSchedule(newTimeSlotMatrix);
	}, [currentVolumeSchedule, selectedVolumeValue]);

	// action when a time slot is clicked
	const onClickTimeSlot = action((day: DayOfWeekKeys, timeIndex: number) => {
		if (!store.isEditingVolumeSchedule) {
			store.editingVolumeSchedule = true;
		}
		// first click on timeSlot
		if (!selectedDay || selectedTimeIndex === null) {
			setSelectedDay(day);
			setSelectedTimeIndex(timeIndex);
			return;
		}

		// arrange dayIndex and TimeIndex to ascending order
		const sortedDayIndex = sortBy([DayOfWeek[selectedDay], DayOfWeek[day]]);
		const sortedTimeIndex = sortBy([selectedTimeIndex, timeIndex]);

		// second click on timeSlot
		updateSelectedTimeSlots({
			startDayIndex: sortedDayIndex[0],
			endDayIndex: sortedDayIndex[1],
			startTimeIndex: sortedTimeIndex[0],
			endTimeIndex: sortedTimeIndex[1],
		});

		// clear out the temp day and timeIndex state
		resetState();
	});

	const saveVolumeSchedule = action(async () => {
		setIsSubmitting(true);
		if (!!selectedDay || !!selectedTimeIndex) {
			resetState();
		}
		if (!volumeState.scheduleId || !selectedSchedule) {
			return;
		}
		const volumeTimeSlots: VolumeTimeslotEntity[] = [];

		currentVolumeSchedule.forEach((day, dayIndex) => {
			// init a new VolumeTimeSlot's volume value and compare to the current timeSlotValue
			let volume: number = -1;
			let startTimeIndex: number = 0;
			for (let timeSlotIndex = 0; timeSlotIndex <= NUMBER_OF_TIME_SLOTS; timeSlotIndex++) {
				const timeSlotValue = day[timeSlotIndex];

				// init a new state for the first time slot
				if (timeSlotIndex === 0) {
					volume = timeSlotValue;
				}
				// if this time slot is in between a volume time slot, do nothing
				if (timeSlotValue !== volume) {
					// only when volume is a valid value
					if (volume < 1 && volume >= 0) {
						// create a new VolumeTimeslotEntity and push to the volumeTimeSlots array
						const newVolumeTimeSlotEntity = new VolumeTimeslotEntity();
						newVolumeTimeSlotEntity.dayOfWeek = convertDayIndexToDayOfWeekEnum(dayIndex);
						newVolumeTimeSlotEntity.volume = volume;
						newVolumeTimeSlotEntity.scheduleId = volumeState.scheduleId ?? '';
						// use the startTimeIndex as start
						newVolumeTimeSlotEntity.start = convertTimeIndexToTime(startTimeIndex);
						// use the previous time slot as end
						newVolumeTimeSlotEntity.end = convertTimeIndexToTime(timeSlotIndex);

						volumeTimeSlots.push(newVolumeTimeSlotEntity);
					}

					// reset the state of the first time slot of a volumeTimeSlot
					volume = timeSlotValue;
					startTimeIndex = timeSlotIndex;
				}
			}
		});

		selectedSchedule.volumeTimeslotss = volumeTimeSlots;
		try {
			await selectedSchedule?.save(
				{ volumeTimeslotss: {} },
				{
					options: [
						{
							key: 'mergeReferences',
							graphQlType: '[String]',
							value: 'volumeTimeslotss',
						},
					],
				},
			);
			setInitVolumeSchedule(currentVolumeSchedule.map(r => [...r]));
			runInAction(() => { store.editingVolumeSchedule = false; });
			alertToast('Saved schedule.', 'success');
			queryClient.refetchQueries(['profile', profile.id]);
		} catch (err) {
			alertToast('Error saving volume schedule.', 'error');
		} finally {
			setIsSubmitting(false);
		}
	});

	const resetVolumeSchedule = action(() => {
		setCurrentVolumeSchedule(initVolumeSchedule);
		resetState();
		runInAction(() => { store.editingVolumeSchedule = false; });
	});

	return (
		<div className="volume-tab-container">
			{/* ---------- Left hand side - volume control ---------- */}
			<div className="volume-controller-container">
				<p className="label">Select a volume value</p>
				<div className="volume-buttons-container">
					<p>Max volume</p>
					{Array.from({ length: 11 }, (_, i) => (10 - i) / 10).map((volume, i) => (
						<button
							key={`volume-${volume}`}
							className="volume-button"
							onClick={() => setSelectedVolumeValue(volume)}
						>
							<div
								className="volume-content"
								style={{
									width: `${90 - i * 8}%`,
									backgroundColor: returnTimeSlotCellColor(volume),
									opacity: volume === selectedVolumeValue ? '1' : '0.4',
								}}
							>
								{volume * 10}
							</div>
						</button>
					))}
					<p>Silence</p>
				</div>
			</div>

			{/* ---------- Right hand side - volume schedule control ---------- */}
			<div className="schedule-controller-container">
				<div className="schedule-selection-container">
					<Combobox
						model={volumeState}
						modelProperty="scheduleId"
						label="Schedule"
						labelVisible={false}
						options={scheduleOptions}
						className="combobox"
						isRequired
						isDisabled={store.isEditingVolumeSchedule}
						placeholder="Select Schedule"
						onAfterChange={onSelectSchedule}
					/>
					{store.isEditingVolumeSchedule
						&& (
							<div className="schedule-selection-btns-container">
								<Button
									colors={Colors.Primary}
									display={Display.Solid}
									sizes={Sizes.Medium}
									onClick={saveVolumeSchedule}
									disabled={!store.isEditingVolumeSchedule || isSubmitting}
									className="save-button"
								>
									Save Schedule
								</Button>
								<Button
									colors={Colors.Secondary}
									display={Display.Outline}
									sizes={Sizes.Medium}
									onClick={resetVolumeSchedule}
									disabled={!store.isEditingVolumeSchedule}
								>
									Discard Changes
								</Button>
							</div>
						)}
				</div>
				{/* Time Schedule Table */}
				{/* Time label */}
				{!volumeState.scheduleId ? <p className="schedule-table-instruction">Select a schedule</p>
					: (
						<div className="volume-schedule-time">
							<div className="volume-schedule-row">
								<div className="volume-schedule-header-cell label-column" />
								{TIME_MAP.map(timeSlot => (
									<div key={timeSlot} className="volume-schedule-header-cell time-header">
										{timeSlot}
									</div>
								))}
							</div>
							{/* volume schedule body */}
							{
								(Object.keys(DayOfWeek) as DayOfWeekKeys[]).map((day, dayIndex) => (
									<div key={`timeSlot-label-${day}`} className="volume-schedule-row">
										<div className="volume-schedule-header-cell label-column">
											{day}
										</div>
										{(currentVolumeSchedule[dayIndex])?.map((timeCell, timeIndex) => {
											const isSelected = dayIndex === convertDayToDayIndex(selectedDay)
												&& timeIndex === selectedTimeIndex;
											return (
												// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
												<div
													// eslint-disable-next-line react/no-array-index-key
													key={`timeSlot-${day}-${timeIndex}-${timeCell}`}
													className="volume-schedule-cell"
													onClick={() => onClickTimeSlot(day, timeIndex)}
												>
													<div
														style={
															{
																backgroundColor:
																	isSelected ? 'white'
																		: returnTimeSlotCellColor(
																			currentVolumeSchedule[dayIndex][timeIndex],
																		),
															}
														}
														className={
															returnTimeSlotCellClassName({
																previousValue:
																	currentVolumeSchedule[dayIndex][timeIndex - 1],
																currentValue:
																	currentVolumeSchedule[dayIndex][timeIndex],
																nextValue:
																	currentVolumeSchedule[dayIndex][timeIndex + 1],
															})
														}
													>
														{currentVolumeSchedule[dayIndex][timeIndex] * 10}
													</div>
												</div>
											);
										})}
									</div>
								))
							}
						</div>
					)}
			</div>
		</div>
	);
};

export default VolumeTab;
